import Avatar from '@/component/Avatar/Avatar';
import IconButton from '@/component/Button/IconButton';
import Popper from '@/component/Popper';
import { useCurrentUserQuery } from '@/feature/user/queries';
import { useState } from 'react';
import PreferencesDialog from '../../user/preferences/PreferencesDialog';
import UserActions from './UserActions';

const User = () => {
  const [open, setOpen] = useState('');
  const onClose = () => setOpen('');

  return (
    <>
      <Popper
        Content={({ onClose }) => (
          <UserActions
            onClose={onClose}
            onPreferencesOpen={() => setOpen('preferences')}
          />
        )}
        Trigger={({ onClick }: PopperTriggerProps) => (
          <UserInfo onClick={onClick} />
        )}
        placement="top-start"
      />
      <PreferencesDialog open={open === 'preferences'} onClose={onClose} />
    </>
  );
};

export default User;

const UserInfo = ({ onClick }: PopperTriggerProps) => {
  const { data: user } = useCurrentUserQuery();

  if (!user) {
    return <></>;
  }

  return (
    <IconButton onClick={onClick} color="inherit" size="large" edge="end">
      <Avatar
        firstName={user.firstName}
        lastName={user.lastName}
        profileImage={user.image}
        size="xs"
      />
    </IconButton>
  );
};
