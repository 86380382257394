import {
  MutationStatus,
  QueryStatus,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

export const some = <T extends QueryStatus | MutationStatus>(
  withStatus: T,
  ...statuses: T[]
) => statuses.some((s) => s === withStatus);

export const every = (withStatus: QueryStatus, ...statuses: QueryStatus[]) =>
  statuses.every((s) => s === withStatus);

export const getStatus = (...isLoading: boolean[]) =>
  isLoading.some((loading) => loading) ? 'loading' : 'success';

// react-query is planning to introduce a new version of `isLoading` in version 5. This flag can be used to identify if a loading skeleton should be displayed.
// Reference: https://github.com/TanStack/query/issues/3975#issuecomment-1245101647
export const useQueryV5 = <Q = unknown, E = unknown, D = Q>(
  queryOptions: UseQueryOptions<Q, E, D>
): UseQueryResult<D, E> => {
  const queryResult = useQuery<Q, E, D>(queryOptions);
  const { isLoading, isFetching } = queryResult;
  queryResult.isLoading = isLoading && isFetching;

  return queryResult;
};
