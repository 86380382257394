import useQuery from '@/util/useQuery';
import useUrlBuilder from '@/util/useUrlBuilder';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

const Context = createContext('');

export const returnUrlParam = 'returnUrl';

export const useReturnUrl = () => useContext(Context);

interface ReturnUrlProviderProps {
  children: ReactNode;
  initialValue?: string;
}

export const ReturnUrlProvider = ({
  children,
  initialValue = '',
}: ReturnUrlProviderProps) => {
  const { query, deleteParam } = useQuery();
  const [returnUrl, setReturnUrl] = useState(
    query[returnUrlParam] || initialValue
  );

  useEffect(() => {
    if (query[returnUrlParam]) {
      setReturnUrl(query[returnUrlParam]);
      deleteParam(returnUrlParam);
    }
  }, [deleteParam, query, setReturnUrl]);

  return <Context.Provider value={returnUrl}>{children}</Context.Provider>;
};

export const useSetReturnUrl = (returnUrl: string) => {
  const { editUrl } = useUrlBuilder();

  return useEffect(() => {
    editUrl().setParam(returnUrlParam, returnUrl).replace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
