import { initializeApp } from '@firebase/app';
import { getMessaging, getToken, onMessage } from '@firebase/messaging';
import { isEqual } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import useAuth from '../auth/useAuth';
import { useCurrentUser } from '../user/queries';
import { firebaseConfig } from './firebaseConfig';
import { useAddRegistrationToken } from './mutations';
import { useHandleMessage } from './useHandleMessage';
import { usePushToken } from './usePushToken';
import { useRequestPermission } from './useRequestPermission';

const MAX_RETRY_COUNT = 3;

export const usePushNotifications = () => {
  const { isAuthenticated } = useAuth();
  const handleMessage = useHandleMessage();
  const { mutateAsync: addToken } = useAddRegistrationToken();
  const user = useCurrentUser();
  const [token, setToken] = usePushToken();
  const [retryCount, setRetryCount] = useState(0);
  const { granted } = useRequestPermission(true);
  const enableInitialization = isAuthenticated && granted;

  const handleError = useCallback(
    (error: any) => {
      console.warn('Push notification, registration failed:', error);
      setRetryCount(retryCount + 1);
    },
    [retryCount]
  );

  useEffect(
    () => {
      if (enableInitialization && retryCount < MAX_RETRY_COUNT) {
        const app = initializeApp(firebaseConfig.options);
        const messaging = getMessaging(app);

        getToken(messaging, { vapidKey: firebaseConfig.vapidKey })
          .then((fcmToken) => {
            const newToken = {
              userId: user.id,
              registrationToken: fcmToken,
            };
            if (!isEqual(token, newToken)) {
              addToken(newToken)
                .then(() => {
                  setToken(newToken);
                })
                .catch(handleError);
            }
          })
          .catch(handleError);

        onMessage(messaging, handleMessage);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enableInitialization, retryCount]
  );
};
