function wash(blurhash?: string): string | undefined {
  if (!blurhash) {
    return;
  }
  let result: string | undefined = blurhash;

  // BASE64 decode
  if (blurhash.indexOf(';base64,') > 0) {
    const base64blurhash = blurhash.split(';base64,')[1];
    result = base64blurhash ? atob(base64blurhash) : undefined;
  }

  return result;
}

export { wash };
