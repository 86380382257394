export function hasDecimals(value: number) {
  return value % 1 !== 0;
}

export function isNumber(value: any): value is number {
  return typeof value === 'number' && !isNaN(value);
}

interface IsBetweenOptions {
  value: number;
  from: number;
  to: number;
}

export function isBetween({ value, from, to }: IsBetweenOptions) {
  return value >= from && value <= to;
}
