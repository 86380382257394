import { toQueryParams } from '@/api/util';
import { get, getPaginated, post, put, remove } from '@/api/v2';
import { flatten } from 'lodash-es';
import { createQueryClause } from '../query/queryClause';

export const fetchCurrentUser = () =>
  get<UserDto>('user/me?include=image&include=roles');

export const fetchUser = (id: string | undefined, params?: FetchUserParams) => {
  if (!id) {
    return Promise.resolve(undefined);
  }
  return get<UserDto>(`user/${id}${toQueryParams(params)}`);
};

export const fetchUsers = (
  criteria?: UserCriteria,
  params: ListParams<UserInclude> = {}
) => {
  return getPaginated<UserDto[]>(
    `user`,
    {
      query: createQueryClause(criteria),
      ...params,
    },
    {
      defaultValue: [],
    }
  ).then(flatten);
};

export const createUser = (data: Partial<UserDto>) =>
  post<UserDto>('user?send_user_invite=true', data);

export const updateUser = (user: UserDto) =>
  put<UserDto>(`user/${user.id}`, user);

export const softDeleteUser = (id: string) =>
  remove(`user/${id}?soft_delete=true`);

interface PasswordChange {
  password: string;
  confirmation: string;
}

interface ChangePasswordOptions extends PasswordChange {}

export const changePassword = (passwordInfo: ChangePasswordOptions) =>
  put<UserDto>('user/me/password', passwordInfo);

interface ChangePasswordForUserOptions {
  userId: string;
  password: PasswordChange;
}

export const changePasswordForUser = ({
  userId,
  password,
}: ChangePasswordForUserOptions) =>
  put<void>(`user/${userId}/password`, password);

export const fetchRoles = (realmId: string) =>
  get<RoleDto[]>(`role${toQueryParams({ realm: realmId })}`, {
    defaultValue: [],
  });
