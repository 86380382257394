import { createContext } from 'react';

interface AuthContextProps {
  login: () => void;
  logout: () => void;
  isAuthenticated: boolean;
  isInitialized: boolean;
}

export default createContext<AuthContextProps>({
  login: () => {},
  logout: () => {},
  isAuthenticated: false,
  isInitialized: false,
});
