import { QueryStatus } from '@tanstack/react-query';
import useAuth from '../auth/useAuth';
import { rootRealmId } from '../businessEntity/constants';
import { useBusinessEntityByIdQuery } from '../businessEntity/queries';
import { some } from '../query/util';
import { useCurrentUserQuery } from '../user/queries';
import { useUserMePermitsQuery } from './queries';

/**
 * The purpose of "global permits" is to be able to fetch permits that can be used in the entire app, without the
 * context of a specific entity. A main case for this is when deciding which navigation items that should be presented.
 * Permits has to be fetched from some different sources for different users:
 * 1. Users on root realm should get their permits from api/v2/user/me/permit. This is not affected by capabilty bundles.
 * 2. Users placed on regions should use same permits as root users, see 1 above.
 * 3. All other users (i.e. users placed on accounts) should get permits that are filtered by capabilty bundles. This is
 *    achieved by fetching the permits from the parent entity.
 */
export function useGlobalPermits() {
  const { isAuthenticated } = useAuth();
  const { data: currentUser } = useCurrentUserQuery({
    enabled: isAuthenticated,
  });

  const realmId = currentUser?.realm.id;
  const isRoot = realmId === rootRealmId;
  const {
    data: realm,
    status: realmStatus,
    isLoading: realmIsLoading,
  } = useBusinessEntityByIdQuery<RealmDto>(realmId, ['parents'], {
    enabled: Boolean(realmId && !isRoot),
  });

  const parentId = realm?.parentIds?.[0];
  const {
    data: parent,
    status: parentStatus,
    isLoading: parentIsLoading,
  } = useBusinessEntityByIdQuery<RealmDto>(parentId, ['permits'], {
    enabled: Boolean(parentId && !isRoot),
  });

  const isRegion = parent?.type === 'region';

  const {
    data: userMePermits,
    status: userMeStatus,
    isLoading: userMeIsLoading,
  } = useUserMePermitsQuery(isRoot || isRegion);

  const permits = (isRoot || isRegion ? userMePermits : parent?.permits) ?? [];

  const status: QueryStatus =
    realmIsLoading || parentIsLoading || userMeIsLoading
      ? 'loading'
      : some('error', realmStatus, parentStatus, userMeStatus)
      ? 'error'
      : 'success';

  const error = { message: status === 'error' ? 'Fetch permits failed' : null };

  return { permits, status, error };
}
