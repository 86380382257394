import type { ReactNode } from 'react';
import { useHasBusinessEntityPermit } from '../businessEntity/queries';
import { useHasPermission } from './PermissionsProvider';

interface AccessProps {
  requiredPermit: Permit;
  entityId?: string;
  denied?: ReactNode;
  children: ReactNode;
}

/**
 * Validates that user has permission to access component. If granted the child
 * component is displayed, otherwise not.
 *
 * The component can be used in two ways:
 *
 * (1) With entityId and permit: Lookup is done on the entity that the user has
 *     given permit.
 *
 * (2) With permit only: Checks if user has given permit somewhere
 *
 * @param requiredPermit Permit to check access for
 * @param entityId Entity id to check permit on (optional)
 * @param children Component to render, if the user has permit
 * @constructor
 */
const Access = ({
  requiredPermit,
  entityId,
  denied,
  children,
}: AccessProps) => {
  const hasGlobalPermit = useHasPermission(requiredPermit);
  const { hasPermit: hasEntityPermit } = useHasBusinessEntityPermit(
    entityId,
    requiredPermit
  );

  if (entityId) {
    // Check access on entity level
    if (!hasEntityPermit) {
      return <>{denied ?? null}</>;
    }
  } else {
    // Check global access
    if (!hasGlobalPermit) {
      return <>{denied ?? null}</>;
    }
  }

  return <>{children}</>;
};

export default Access;
