import { useRecommendationsQuery } from '@/feature/recommendation/queries';
import { Icon } from '@/icon/Icon';
import Badge from '@mui/material/Badge';

export const RecommendationsNavIcon = () => {
  const { data } = useRecommendationsQuery();
  const numberOfOpenRecommendations = data?.filter(
    (r) => r.recommendationsOrderStatus === 'open'
  ).length;

  return (
    <Badge badgeContent={numberOfOpenRecommendations} color="primary">
      <Icon name="recommendations" size="small" />
    </Badge>
  );
};

export const CriticalityIcon = ({
  value,
}: {
  value?: RecommendationCriticality;
}) => {
  switch (value) {
    case 'normal':
      return <Icon name="info" size="small" color="primary" />;
    case 'high':
      return <Icon name="warning" size="small" color="warning" />;
    case 'urgent':
      return <Icon name="notification_important" size="small" color="error" />;
    default:
      return null;
  }
};

export const OrderStatusIcon = ({
  value,
}: {
  value?: RecommendationOrderStatus;
}) => {
  switch (value) {
    case 'open':
      return <Icon name="info" size="small" color="primary" />;
    case 'ordered':
      return <Icon name="success" size="small" color="success" />;
    case 'partial':
      return (
        <Icon name="radio_button_unchecked" size="small" color="success" />
      );
    default:
      return null;
  }
};
