import { BasicColumn } from '@/component/DataGrid/ColumnDefinitions';
import StyledTable from '@/component/DataGrid/component/StyledTable';
import { DataGrid } from '@/component/DataGrid/DataGrid';
import DataProvider from '@/component/DataGrid/DataProvider';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@/component/Dialog';
import Translate from '@/component/Translate';
import { ONE_HOUR } from '@/util/time';
import { Button } from '@mui/material';
import { useState } from 'react';
import { Row } from 'react-table';
import { useBusinessEntityQuery } from '../businessEntity/queries';
import { useTexts } from '../lang';
import criteriaBuilder from '../query/criteriaBuilder';
import { useAccountSelector } from './AccountProvider';

interface AccountDialogProps {
  open: boolean;
  onClose: () => void;
}

const AccountDialog = ({ open, onClose }: AccountDialogProps) => {
  const { app, component } = useTexts();
  const { account, setAccount } = useAccountSelector();
  const [selectedAccount, setSelectedAccount] =
    useState<BusinessEntityBase | undefined>(account);
  const criteria = criteriaBuilder().type('region').type('account').get();
  const { data, status } = useBusinessEntityQuery<BusinessEntityBase[]>(
    criteria,
    {},
    { enabled: open, queryKey: ['accountSelector'], staleTime: ONE_HOUR }
  );

  const handleSetAccount = () => {
    setAccount(selectedAccount);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle title={app.account.dialog.header} />
      <DialogContent>
        <DataProvider
          columnDefinitions={[
            BasicColumn({
              accessor: 'displayName',
              translator: ({ admin }) => admin.company.model.name,
              initialSort: { direction: 'asc' },
            }),
          ]}
          initialData={data}
          status={status}
          getRowId={(row: BusinessEntityBase) => row.id}
          onRowClick={(row: Row<AccountDto>) =>
            setSelectedAccount(row.original)
          }
          highlightRowId={(row: BusinessEntityBase) =>
            row.id === selectedAccount?.id
          }
          sorting
          pagination
          filter
        >
          <StyledTable>
            <DataGrid />
          </StyledTable>
        </DataProvider>
      </DialogContent>
      <DialogActions
        right={
          <>
            <Button type="button" variant="outlined" onClick={onClose}>
              <Translate text={component.button.common.cancel} />
            </Button>
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={handleSetAccount}
            >
              <Translate text={component.button.common.ok} />
            </Button>
          </>
        }
      />
    </Dialog>
  );
};

export default AccountDialog;
