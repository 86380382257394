import type { QueryStatus } from '@tanstack/react-query';
import { createContext } from 'react';

interface DetailsContext {
  selectedEquipment: EquipmentData[];
  isLoading: boolean;
  status: QueryStatus;
}

export default createContext<DetailsContext>({
  status: 'success',
  isLoading: false,
  selectedEquipment: [],
});
