import { ToastType } from '@/component/Toast';
import { add } from '@/component/Toast/toastManager';
import { excludeProps } from '@/util/object';
import { QueryClient } from '@tanstack/react-query';
import { appInsights } from '../insights/appInsights';

const retryCount = 3;
const retryBlacklist = [403, 404];
const trackErrorBlacklist = [401, 404];

async function trackError(error: any, variables: any) {
  try {
    const { status, response, url, method } = error;
    if (!Number.isFinite(status)) {
      return;
    }
    const isClientError = status >= 400 && status <= 499;
    const isBlacklisted = trackErrorBlacklist.includes(status);
    if (!isClientError || isBlacklisted) {
      return;
    }

    const requestBody = excludeProps(
      variables,
      ['password', 'confirmation'],
      '***'
    );
    let responseBody: null | string | object = null;
    try {
      responseBody = await response.text();
      responseBody = JSON.parse(requestBody);
    } catch (error) {}

    const exception = new Error(`Http request: Client error (4xx)`);
    exception.cause = error;

    appInsights.trackException({
      exception,
      properties: { apiUrl: url, method, requestBody, responseBody },
    });
  } catch (error) {
    console.log('Failed to track error', error);
  }
}

export default new QueryClient({
  defaultOptions: {
    mutations: {
      onError: async (error: any, variables: any) => {
        const message = error.status === 403 ? 'unauthorized' : 'saveDataError';

        add({
          message: `common.error.${message}`,
          type: ToastType.Error,
          path: true,
        });

        await trackError(error, variables);
      },
    },
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 5 * 1000,
      notifyOnChangeProps: [
        'data',
        'status',
        'isLoading',
        'isSuccess',
        'isError',
      ],
      retry: (failureCount, error) => {
        // @ts-ignore
        if (retryBlacklist.includes(error.status)) {
          return false;
        }
        return retryCount - 1 > failureCount;
      },
    },
  },
});
