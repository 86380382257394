import { useAccountSelector } from '@/feature/account/AccountProvider';
import { useTexts } from '@/feature/lang';
import { Icon } from '@/icon/Icon';
import { Button, IconButton, styled, Typography } from '@mui/material';
import Translate from '../Translate';
import { useRail } from './useRail';

const Root = styled('div', {
  name: 'ActAsCustomer',
  slot: 'Root',
})(({ theme }) => ({
  display: 'flex',
  flexFlow: 'column nowrap',
  alignItems: 'center',
  width: '100%',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}));

const Text = styled(Typography, {
  name: 'ActAsCustomer',
  slot: 'Text',
})(({ theme }) => ({
  display: 'block',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  maxWidth: '90%',
  marginTop: theme.spacing(1),
}));

const Label = styled('span', {
  name: 'ActAsCustomer',
  slot: 'Label',
})(({ theme }) => ({
  display: 'inline',
  marginRight: theme.spacing(1),
}));

const CompanyName = styled('span', {
  name: 'ActAsCustomer',
  slot: 'CompanyName',
})({
  fontWeight: 500,
});

const ActAsYourselfButton = styled(Button, {
  name: 'ActAsCustomer',
  slot: 'ActAsYourselfButton',
})(({ theme }) => ({
  padding: '0px 8px',
  margin: theme.spacing(1),
  fontSize: 12,
  fontWeight: 400,
  textTransform: 'none',
  borderRadius: 12,
}));

const ActAsCustomer = () => {
  const [rail] = useRail();
  const { account, setAccount } = useAccountSelector();
  const { app } = useTexts();

  const handleActAsYourself = () => {
    setAccount(undefined);
    window.location.reload();
  };

  return (
    <Root>
      <Text variant="body1">
        {!rail && (
          <Label>
            <Translate text={app.account.viewingAs} />
          </Label>
        )}
        <CompanyName>{account?.displayName}</CompanyName>
      </Text>
      {rail ? (
        <IconButton color="inherit" onClick={handleActAsYourself} size="large">
          <Icon name="close" />
        </IconButton>
      ) : (
        /* TODO Extract to new generic button (size="mini") */
        <ActAsYourselfButton
          color="inherit"
          variant="outlined"
          onClick={handleActAsYourself}
        >
          <Translate text={app.account.action.actAsYourself} />
        </ActAsYourselfButton>
      )}
    </Root>
  );
};

export default ActAsCustomer;

export const ActAsCustomerInfo = () => {
  const { account } = useAccountSelector();
  return account ? <ActAsCustomer /> : null;
};
