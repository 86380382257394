import { useTranslation } from '@/feature/lang';
import type { AllTexts, Translation } from '@/feature/lang/types';
import { ListItemIcon, ListItemText as MuiListItemText } from '@mui/material';
import type { ReactNode } from 'react';
import { transitionIn } from '../Drawer/layout';
import { useRail } from '../Drawer/useRail';
import Tooltip from '../Tooltip';

interface ListItemContentProps {
  label: (texts: AllTexts) => Translation;
  icon: ReactNode;
  isSubItem?: boolean;
}

export const ListItemContent = ({
  label,
  icon,
  isSubItem = false,
}: ListItemContentProps) => {
  const { texts, translate } = useTranslation();
  const translatedLabel = translate(label(texts));
  const [rail] = useRail();

  return (
    <>
      <Tooltip title={translatedLabel} disableInteractive>
        <ListItemIcon
          sx={{
            ml: isSubItem ? 4 : 0,
            mr: 2,
            minWidth: '24px',
            color: 'inherit',
            transition: (theme) => transitionIn(theme, 'margin-left'),
            ...(rail && {
              ml: isSubItem ? '2px' : 0,
            }),
          }}
        >
          {icon}
        </ListItemIcon>
      </Tooltip>
      <ListItemText text={translatedLabel} />
    </>
  );
};

const ListItemText = ({ text }: { text?: string }) => (
  <MuiListItemText
    primaryTypographyProps={{ variant: 'body2' }}
    primary={text}
  />
);
