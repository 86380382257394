import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { NotificationToken } from './api.types';

const tokenAtom = atomWithStorage<NotificationToken | undefined>(
  'user.pushToken',
  undefined
);

export const usePushToken = () => useAtom(tokenAtom);
