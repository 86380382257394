export function isNonEmptyArray<T = any>(arr?: any): arr is [T, ...T[]] {
  return Array.isArray(arr) && arr.length > 0;
}

export function toArray<T>(values: T | T[]): T[] {
  return Array.isArray(values) ? values : [values];
}

export function uniqueValues<T>(values: T | T[]): T[] {
  const allValues = toArray(values);
  return Array.from(new Set(allValues));
}

export function unique<T>(value: T, index: number, array: T[]) {
  return array.indexOf(value) === index;
}

export function notEmpty<TValue>(
  value: TValue | null | undefined
): value is TValue {
  return value !== null && value !== undefined;
}

export function createChunks<T>(arr: T[], chunkSize: number) {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
}

export function everyNth<T>(arr: T[], nth: number, includeFirst?: boolean) {
  const ref = [...arr];
  const newArr = [];

  if (ref.length && includeFirst) {
    const item = ref.shift();
    if (item) {
      newArr.push(item);
    }
  }

  newArr.push(...ref.filter((e, i) => i % nth === nth - 1));

  return newArr;
}

export function move<T>(arr: T[], from: number, to: number) {
  arr.splice(to, 0, arr.splice(from, 1)[0]);
}
