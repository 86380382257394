import './font.css';

// @ts-ignore
(() => {
  if (document?.fonts) {
    setTimeout(function () {
      document.fonts.load('300 16px "Material Symbols Rounded"');
    }, 0);
  }
})();
