import useBusinessRelations from '../businessRelations/useBusinessRelations';
import useDeliveryPoints from '../businessRelations/useDeliveryPoints';

const PrefetchData = () => {
  // Add react queries prefetch data during app startup.
  // Note: The UI is not blocked while this data is fetched.
  useBusinessRelations();
  useDeliveryPoints();
  return null;
};

export default PrefetchData;
