import useTranslation from '@/feature/lang/hook';
import type { AllTexts } from '@/feature/lang/types';
import { Autocomplete as MuiAutocomplete, Popper, styled } from '@mui/material';
import type { PopperProps } from '@mui/material/Popper';
import { useMemo } from 'react';
import { ColumnInstance, Row } from 'react-table';
import { Input } from '../Form';
import type { InputProps } from '../Form/types';

export const Autocomplete = styled(MuiAutocomplete, {
  name: 'SelectColumnFilter',
  slot: 'Autocomplete',
})({
  '& .MuiAutocomplete-inputRoot': {
    '&[class*="MuiFilledInput-root"][class*="MuiFilledInput-root"]': {
      paddingTop: '21px',
      paddingBottom: '12px',
      '&.MuiInputBase-sizeSmall': {
        paddingTop: '8px',
        paddingBottom: '9px',
        '& .MuiAutocomplete-tag': {
          fontSize: '12px',
          lineHeight: 1,
        },
      },
      '& .MuiAutocomplete-input': {
        padding: 0,
      },
    },
  },
}) as typeof MuiAutocomplete;

const PopperComponent = styled(
  (props: PopperProps) => (
    <Popper {...props} placement="bottom-start" style={{ width: 'unset' }} />
  ),
  {
    name: 'SelectColumnFilter',
    slot: 'PopperComponent',
  }
)(({ theme }) => ({
  maxWidth: '300px',
  '.MuiAutocomplete-paper': {
    boxShadow: theme.shadows[4],
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  '.MuiAutocomplete-option': {
    fontSize: theme.typography.body2.fontSize,
    whiteSpace: 'nowrap',
  },
}));

export const DefaultColumnFilter = ({
  column,
  ...props
}: DataGridFilterProps) => {
  const { filterValue, setFilter } = column;
  const { texts } = useTranslation();

  return (
    <Input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      {...getFilterInputProps(column, props, texts)}
    />
  );
};

export const SelectColumnFilter = ({
  column,
  translateValue,
  ...props
}: DataGridFilterProps) => {
  const { filterValue, setFilter, preFilteredRows, id } = column;
  const options = useMemo(
    () => getUniqueValues(preFilteredRows, id),
    [id, preFilteredRows]
  );
  const { texts } = useTranslation();

  return (
    <Autocomplete
      options={options}
      value={filterValue ?? null}
      onChange={(event, value) => setFilter(value)}
      // @ts-ignore
      getOptionLabel={(option) =>
        translateValue ? translateValue(option) : option
      }
      renderInput={(params) => (
        <Input {...params} {...getFilterInputProps(column, props, texts)} />
      )}
      PopperComponent={PopperComponent}
    />
  );
};

export const YesNoColumnFilter = ({ ...filterProps }: DataGridFilterProps) => {
  const { texts, translate } = useTranslation();
  const translateYesNo = (value: string) =>
    translate(value === 'yes' ? texts.common.yes : texts.common.no);

  return (
    <SelectColumnFilter {...filterProps} translateValue={translateYesNo} />
  );
};

function getUniqueValues(rows: Row[], id: string) {
  const options = new Set();
  rows.forEach((row) => {
    if (row.values[id]) {
      options.add(row.values[id]);
    }
  });
  // @ts-ignore
  return [...options.values()];
}

export function getFilterInputProps(
  column: ColumnInstance,
  tableProps: any,
  texts: AllTexts
): InputProps {
  if (tableProps.label) {
    return {
      // @ts-ignore
      label: column.render('Header'),
      size: 'medium',
      margin: 'normal',
    };
  } else {
    return {
      placeholder: texts.component.dataGrid.filter.placeholder,
      size: 'small',
      margin: 'none',
    };
  }
}
