import useTranslation from './hook';

const useLanguages = () => {
  const { texts, translate } = useTranslation();
  const langs = texts.common.language;

  const options = [
    {
      id: 'en',
      title: translate(langs.english),
    },
    {
      id: 'sv',
      title: translate(langs.swedish),
    },
  ];

  return { options };
};

export default useLanguages;
