import { cloneDeep } from 'lodash-es';

function add(cart: CartDto, ...items: CartItem[]): CartDto {
  const newCart: CartDto = cloneDeep(cart);

  let position = getPositionMax(cart);

  items.forEach((item) => {
    newCart.items.push({
      cartAllocator: item.cartAllocator,
      itemId: item.id,
      equipmentId: item.equipmentId,
      quantity: item.quantity ?? 1,
      position: ++position,
      deliveryPointEntityId: item.deliveryPointEntityId,
    });
  });

  return newCart;
}

function changeQuantity(
  cart: CartDto,
  position: number,
  quantity: number
): CartDto {
  const newCart = cloneDeep(cart);

  const item = getItemByPos(newCart, position);
  if (item) {
    item.quantity = quantity;
  }

  return newCart;
}

function changeDeliveryPoint(
  cart: CartDto,
  positions: number[],
  deliveryPointId: string | undefined
) {
  const newCart = cloneDeep(cart);

  positions.forEach((position) => {
    const item = getItemByPos(newCart, position);
    if (item) {
      if (deliveryPointId) {
        item.deliveryPointEntityId = deliveryPointId;
      } else {
        delete item.deliveryPointEntityId;
      }
    }
  });

  return newCart;
}

function changeEquipment(
  cart: CartDto,
  positions: number[],
  equipmentId: string | undefined
) {
  const newCart = cloneDeep(cart);
  positions.forEach((position) => {
    const item = getItemByPos(newCart, position);
    if (item) {
      if (equipmentId) {
        item.equipmentId = equipmentId;
      } else {
        delete item.equipmentId;
      }
    }
  });

  return newCart;
}

function remove(cart: CartDto, positions: number[]): CartDto {
  const newCart = cloneDeep(cart);

  newCart.items = newCart.items.filter(
    (item) => !positions.includes(item.position)
  );

  return newCart;
}

function replace(cart: CartDto, fromItemId: number, toItemId: number): CartDto {
  if (!toItemId) {
    return cart;
  }
  const newCart = cloneDeep(cart);

  newCart.items = newCart.items.map((item) => {
    if (item.itemId === fromItemId) {
      return {
        ...item,
        itemId: toItemId,
        itemDetails: undefined,
      };
    }
    return item;
  });

  return newCart;
}

function getPositionMax(cart: CartMutable): number {
  return cart ? Math.max(...cart.items.map((item) => item.position), 0) : 0;
}

function getItemByPos(cart: CartDto, position: number) {
  return cart.items.find((item) => item.position === position);
}

export default {
  add,
  changeQuantity,
  changeDeliveryPoint,
  changeEquipment,
  remove,
  replace,
};
