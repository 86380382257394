import { Button, ButtonProps, darken } from '@mui/material';
import { useTexts } from '@/feature/lang';
import { Icon } from '@/icon/Icon';
import ButtonLoading, {
  ButtonLoadingProps,
} from '../ButtonLoading/ButtonLoading';
import Translate from '../Translate';

export const DiscreetButton = (props: ButtonProps) => (
  <Button color="grey" variant="contained" {...props} />
);

export const CancelButton = (props: ButtonProps) => {
  const { component } = useTexts();
  return (
    <Button variant="outlined" key="cancel" {...props} color="inherit">
      <Translate text={component.button.common.cancel} />
    </Button>
  );
};

export const OkButton = (props: ButtonLoadingProps) => {
  const { component } = useTexts();
  return (
    <ButtonLoading
      variant="contained"
      color="primary"
      type="submit"
      key="ok"
      {...props}
    >
      {props.children ?? <Translate text={component.button.common.ok} />}
    </ButtonLoading>
  );
};

export const AddButton = (props: ButtonProps) => {
  const { component } = useTexts();
  return (
    <Button
      startIcon={<Icon name="add" size="small" />}
      color="primary"
      variant="contained"
      key="add"
      {...props}
    >
      <Translate text={component.button.common.add} />
    </Button>
  );
};

export const AddImageButton = (props: ButtonLoadingProps) => {
  const { component } = useTexts();
  return (
    <ButtonLoading
      startIcon={<Icon name="add_a_photo" size="small" />}
      variant="outlined"
      color="primary"
      key="addimage"
      component="span"
      {...props}
    >
      <Translate text={component.button.common.add} />
    </ButtonLoading>
  );
};

export const PreviousButton = (props: ButtonProps) => {
  const { component } = useTexts();
  return (
    <Button variant="outlined" color="inherit" key="previous" {...props}>
      <Translate text={component.button.common.previous} />
    </Button>
  );
};

export const NextButton = (props: ButtonLoadingProps) => {
  const { component } = useTexts();
  return (
    <ButtonLoading variant="contained" color="primary" key="next" {...props}>
      <Translate text={component.button.common.next} />
    </ButtonLoading>
  );
};

export const CloseButton = (props: ButtonProps) => {
  const { component } = useTexts();
  return (
    <Button variant="outlined" color="inherit" key="close" {...props}>
      {props.children ?? <Translate text={component.button.common.close} />}
    </Button>
  );
};

export const RemoveButton = (props: ButtonLoadingProps) => {
  const { component } = useTexts();
  return (
    <ButtonLoading
      variant="contained"
      key="remove"
      {...props}
      sx={({ palette }) => ({
        backgroundColor: palette.error.main,
        color: palette.getContrastText(palette.error.main),
        '&:hover': {
          backgroundColor: darken(palette.error.main, 0.5),
        },
      })}
    >
      <Translate text={component.button.common.remove} />
    </ButtonLoading>
  );
};

export const ConfirmButton = ({ children, ...props }: ButtonLoadingProps) => {
  const { component } = useTexts();
  return (
    <ButtonLoading
      variant="contained"
      color="primary"
      type="submit"
      key="ok"
      {...props}
    >
      {children ?? <Translate text={component.button.common.ok} />}
    </ButtonLoading>
  );
};

export const ResetButton = (props: ButtonProps) => {
  const { component } = useTexts();

  return (
    <Button variant="outlined" color="secondary" {...props}>
      {props.children ?? <Translate text={component.button.common.reset} />}
    </Button>
  );
};
