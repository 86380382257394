import { styled } from '@mui/material/styles';
import type { ReactNode } from 'react';

const FooterRoot = styled('div', { name: 'DataGridFooter' })(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: theme.spacing(2),
  gap: theme.spacing(1),
}));

interface FooterProps {
  left?: ReactNode;
  right?: ReactNode;
}

export const Footer = ({ left, right }: FooterProps) => (
  <FooterRoot>
    {left ?? <div />}
    {right ?? <div />}
  </FooterRoot>
);
