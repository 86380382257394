import { useSignedAgreementsQuery } from '@/feature/agreement/query';
import { lazy, Suspense } from 'react';
import { ContentContainer } from '../components/ContentContainer';

const AgreementContent = lazy(
  () => import('../../agreement/dialog/AgreementContent')
);

interface AgreementsProps {
  visible: boolean;
}

const Agreements = ({ visible }: AgreementsProps) => {
  const { data } = useSignedAgreementsQuery();
  const agreementTypes =
    data?.map((signedAgreement) => signedAgreement.documentType) ?? [];

  return (
    <ContentContainer visible={visible}>
      <Suspense>
        <AgreementContent agreementTypes={agreementTypes} />
      </Suspense>
    </ContentContainer>
  );
};

export default Agreements;
