import { get, getPaginated, post, put, remove } from './apiClient';

export const getByVersion =
  (version: number) =>
  <T>(path: string, options?: Options): Promise<T> =>
    get(path, mergeOptions(version, options));

export const getPaginatedByVersion =
  (version: number) =>
  <T>(
    path: string,
    params: any,
    options?: Options,
    pageProp?: string
  ): Promise<T[]> =>
    getPaginated(path, params, mergeOptions(version, options), pageProp);

export const putByVersion =
  (version: number) =>
  <T>(path: string, body?: any, options?: Options): Promise<T> =>
    put(path, body, mergeOptions(version, options));

export const postByVersion =
  (version: number) =>
  <T>(path: string, body: any, options?: Options): Promise<T> =>
    post(path, body, mergeOptions(version, options));

export const removeByVersion =
  (version: number) =>
  <T>(path: string, body?: any, options?: Options): Promise<T> =>
    remove(path, body, mergeOptions(version, options));

function mergeOptions(version: number, options?: Options) {
  return { ...options, baseUrl: getBaseUrl(version) };
}

function getBaseUrl(version: number) {
  return `/api/v${version}`;
}
