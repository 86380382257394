import { useTranslation } from '@/feature/lang';
import type { Translatable } from '@/feature/lang/types';
import { Icon } from '@/icon/Icon';
import { walk } from '@/util/object';
import { Button, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { ReactNode } from 'react';
import Translate from '../Translate';
import { IToastModel, ToastType } from './types';

type ContainerProps = {
  type?: ToastType;
  children: ReactNode;
};

const Container = styled(
  (props: ContainerProps) => <div {...props}>{props.children}</div>,
  {
    name: 'Toast',
    slot: 'Container',
    shouldForwardProp: (propName) => propName !== 'type',
  }
)(({ theme, type }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: 'min(256px, 95vw)',
  border: '1px solid white',
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(2),
  paddingRight: theme.spacing(2),
  boxShadow: theme.shadows[8],

  ...(type === ToastType.Success && {
    color: theme.palette.getContrastText(theme.palette.success.light),
    background: theme.palette.success.light,
    borderColor: theme.palette.success.main,
  }),

  ...(type === ToastType.Error && {
    color: theme.palette.getContrastText(theme.palette.error.light),
    background: theme.palette.error.light,
    borderColor: theme.palette.error.main,
  }),

  ...(type === ToastType.Info && {
    color: theme.palette.getContrastText(theme.palette.info.light),
    backgroundColor: theme.palette.info.light,
    borderColor: theme.palette.info.main,
  }),
}));

const Message = styled(Typography, {
  name: 'Toast',
  slot: 'Message',
})(({ theme }) => ({
  flex: 1,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
}));

export default ({
  remove,
  message,
  values,
  count,
  type = ToastType.Info,
  path,
  actions,
}: IToastModel) => {
  const { texts, translate } = useTranslation();

  return (
    <Container type={type}>
      <IconButton
        onClick={remove}
        color="inherit"
        aria-label="close"
        size="large"
      >
        <Icon name="close" size="small" />
      </IconButton>
      <Message
        variant="body2"
        sx={(theme) => ({
          marginRight: actions ? theme.spacing(5) : 0,
        })}
      >
        {path && typeof message === 'string' ? (
          translate(walk(texts, message) as Translatable)
        ) : (
          <Translate text={message} values={values} count={count} />
        )}
      </Message>
      {actions?.map((action, i) => (
        <Button
          key={i}
          color="inherit"
          size="small"
          onClick={() => {
            action.onClick();
            remove();
          }}
        >
          <Translate text={action.label} />
        </Button>
      ))}
    </Container>
  );
};
