import { useQuery } from '@tanstack/react-query';
import { useQueryV5 } from '../query/util';
import { fetchRecommendationById, fetchRecommendations } from './api';

export const recommendationsQueryKey = 'recommendations';

export const useRecommendationsQuery = (enabled: boolean = true) =>
  useQuery([recommendationsQueryKey], fetchRecommendations, { enabled });

export const useRecommendationByIdQuery = (recommendationId?: string) =>
  useQueryV5({
    queryKey: [recommendationsQueryKey, recommendationId],
    queryFn: () => fetchRecommendationById(recommendationId ?? ''),
    enabled: Boolean(recommendationId),
  });
