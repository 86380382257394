import type { Translation } from '@/feature/lang/types';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import type { SxProps } from '@mui/system';
import type { ReactElement, ReactNode } from 'react';
import { Box } from '../Box';
import HelpText from '../HelpText';
import Translate from '../Translate';
import FilterModal from './FilterModal/FilterModal';

const Container = styled('div', { name: 'Toolbar', slot: 'Container' })({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  width: '100%',
  maxWidth: '100%',
  minHeight: 40,
  minWidth: 0,
});

interface HeaderProps {
  showSearch?: boolean;
}
const Header = styled('div', {
  name: 'Toolbar',
  slot: 'Header',
  shouldForwardProp: (propName) => propName !== 'showSearch',
})<HeaderProps>(({ showSearch, theme }) =>
  theme.unstable_sx([
    {
      // Flex header when no search is present
      flex: 100,
      display: 'flex',
      marginRight: 4,
      alignItems: 'center',
      minWidth: 0,
    },
    showSearch === true && {
      flex: 'none',
    },
  ])
);

export interface ToolbarProps {
  title?: Translation;
  header?: JSX.Element;
  actionButtons?: ReactNode;
  Filter?: (props: PopperContentProps) => ReactElement;
  filterBadgeContent?: ReactNode;
  search?: ReactNode;
  chip?: ReactNode;
  help?: Translation;
  sx?: SxProps;
}

export const Toolbar = ({
  actionButtons,
  title,
  header,
  Filter,
  filterBadgeContent,
  search,
  chip,
  help,
  sx,
}: ToolbarProps) => (
  <Container sx={sx}>
    <Header showSearch={Boolean(search)}>
      {title ? (
        <Typography variant="h3" noWrap>
          <Translate text={title} />
        </Typography>
      ) : (
        header
      )}
      {help && (
        <Box ml={2}>
          <HelpText text={help} tooltip margin="none" />
        </Box>
      )}
      {chip}
      {Filter && (
        <FilterModal badgeContent={filterBadgeContent} Content={Filter} />
      )}
    </Header>
    {search && <Box sx={{ flex: 1 }}>{search}</Box>}
    {actionButtons}
  </Container>
);
