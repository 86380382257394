import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';

export function getColor(theme: Theme, color = 'textPrimary'): string {
  if (!color) {
    return 'pink';
  }
  let paletteColor = color;
  switch (color) {
    case 'primary':
      paletteColor = theme.palette.primary.main;
      break;
    case 'secondary':
      paletteColor = theme.palette.secondary.main;
      break;
    case 'success':
      paletteColor = theme.palette.success.main;
      break;
    case 'error':
      paletteColor = theme.palette.error.main;
      break;
    case 'gray':
      paletteColor = theme.palette.grey.A200;
      break;
    case 'textPrimary':
      paletteColor = theme.palette.text.primary;
      break;
    case 'textSecondary':
      paletteColor = theme.palette.text.secondary;
      break;
  }
  return paletteColor;
}

// Only supports subtracting from values which are numeric, otherwise fallback to string value
export function subtract(from: number | string, amount: number) {
  if (typeof from === 'number') {
    return from - amount;
  }
  return from;
}

export function toSxArray(sx?: SxProps<Theme>) {
  if (sx === undefined) {
    return [];
  }
  return Array.isArray(sx) ? sx : [sx];
}

export function includeProps(prop: PropertyKey, includePropNames: string[]) {
  if (typeof prop === 'string') {
    return includePropNames.includes(prop);
  }
  return false;
}

export function excludeProps(prop: PropertyKey, excludePropNames: string[]) {
  if (typeof prop === 'string') {
    return !excludePropNames.includes(prop);
  }
  return false;
}
