import type Keycloak from 'keycloak-js';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import AuthContext from './AuthContext';
import {
  REFRESH_TOKEN_CHECK_INTERVAL,
  REFRESH_TOKEN_MIN_VALIDITY,
} from './constants';
import { getPreLoginTokens } from './getPreLoginTokens';

// Reference:
// https://www.keycloak.org/docs/latest/securing_apps/#_javascript_adapter

interface AuthProviderProps {
  keycloak: Keycloak;
  children: ReactNode;
}

const AuthProvider = ({ keycloak, children }: AuthProviderProps) => {
  const [isInitialized, setIsInitialized] = useState(false);

  const init = useCallback(async () => {
    if (isInitialized) {
      return;
    }
    const tokens = await getPreLoginTokens();
    try {
      await keycloak.init({ ...tokens, checkLoginIframe: false });
    } catch (error) {
      console.error('Keycloak init error', error);
    }
    setIsInitialized(true);
  }, [isInitialized, keycloak]);

  const setupKeycloakEvents = useCallback(() => {
    keycloak.onAuthError = (error: any) => {
      console.error('Authentication error', error);
    };
    keycloak.onAuthRefreshError = () => {
      console.error('Authentication refresh error');
    };
  }, [keycloak]);

  const login = useCallback(() => {
    if (!isInitialized) {
      throw new Error(
        'Unable to login as keycloak is not inited just yet. Use isInitialized to delay the call.'
      );
    }
    if (keycloak.authenticated) {
      console.warn('User is authenticated. Abort login...');
      return;
    }
    keycloak.login();
  }, [isInitialized, keycloak]);

  const logout = useCallback(() => {
    keycloak.logout({ redirectUri: window.location.origin });
  }, [keycloak]);

  const refreshToken = useCallback(async () => {
    try {
      await keycloak.updateToken(REFRESH_TOKEN_MIN_VALIDITY);
    } catch (error) {
      login();
    }
  }, [keycloak, login]);

  const setupRefreshTokenInterval = useCallback(() => {
    return window.setInterval(
      refreshToken,
      REFRESH_TOKEN_CHECK_INTERVAL * 1000
    );
  }, [refreshToken]);

  useEffect(() => {
    let intervalId: number;
    (async function () {
      setupKeycloakEvents();
      intervalId = setupRefreshTokenInterval();
      await init();
    })();
    return () => clearInterval(intervalId);
  }, [setupKeycloakEvents, setupRefreshTokenInterval, init]);

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        isAuthenticated: keycloak.authenticated ?? false,
        isInitialized,
      }}
      children={children}
    />
  );
};

export default AuthProvider;
