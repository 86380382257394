import type { ThemeOptions } from '@mui/material';
import { buttonClasses } from '@mui/material/Button';
import { buttonGroupClasses } from '@mui/material/ButtonGroup';

export const base: ThemeOptions = {
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        option: ({ theme }) => ({
          display: 'block',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: theme.typography.body2.fontSize,
        }),
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          lineHeight: 1.14,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        // @ts-ignore
        head: ({ theme }) => ({
          fontWeight: 700,
          color: theme.palette.text.secondary,
        }),
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          lineHeight: 'normal',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          borderRadius: '100px',
          ...(ownerState.color === 'grey' && {
            backgroundColor: theme.palette.getGrey(20),
            '&:hover': {
              backgroundColor: theme.palette.getGrey(30),
            },
          }),
        }),
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: ({ theme }) => ({
          [`& .${buttonGroupClasses.firstButton}.${buttonClasses.sizeSmall}`]: {
            paddingLeft: 11,
          },
          [`& .${buttonGroupClasses.lastButton}.${buttonClasses.sizeSmall}`]: {
            paddingRight: 11,
          },
          [`& .${buttonGroupClasses.grouped}`]: {
            // Fix border color when hover buttons
            '&:not(:last-of-type):hover': {
              borderRightColor: 'transparent',
            },
            // Fix border color and background color for disabled buttons
            [`&.${buttonGroupClasses.disabled}`]: {
              borderColor:
                theme.palette.mode === 'light'
                  ? 'rgba(0, 0, 0, 0.23)'
                  : 'rgba(255, 255, 255, 0.23)',
              pointerEvents: 'auto',
              '&:hover': {
                background: 'inherit',
              },
            },
            // Fix right border when group contains one button
            [`&.${buttonGroupClasses.firstButton}:last-of-type`]: {
              borderRightColor:
                theme.palette.mode === 'light'
                  ? 'rgba(0, 0, 0, 0.23)'
                  : 'rgba(255, 255, 255, 0.23)',
              borderTopRightRadius: 100,
              borderBottomRightRadius: 100,
            },
          },
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: theme.typography.body2.fontSize,
          overflow: 'hidden',
          borderRadius: theme.shape.borderRadius,
        }),
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          // Color from primary text, use `sx` to override
          color: 'inherit',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          // Need to match minHeight on MuiTab#root
          minHeight: 42,
        },
        flexContainer: ({ theme }) => ({
          gap: theme.spacing(4),
        }),
        flexContainerVertical: ({ theme }) => ({
          gap: theme.spacing(1),
          alignItems: 'flex-start',
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minWidth: 64,
          // Need to match minHeight on MuiTabs#root
          minHeight: 42,
          padding: '9px 0',
          opacity: 1,
        },
        labelIcon: ({ theme }) => ({
          flexDirection: 'row',
          gap: theme.spacing(1),
          paddingRight: theme.spacing(4),
          '.MuiTab-iconWrapper': {
            // Remove margin for icon
            marginBottom: 0,
          },
          ' > *:nth-child(2)': {
            // Left align texts in tab
            textAlign: 'left',
          },
        }),
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          // Color from primary text, use `sx` to override
          color: 'inherit',
          borderRadius: '100px',
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          '& > button:first-of-type': {
            paddingLeft: 9,
          },
          '& > button:last-of-type': {
            paddingRight: 9,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: '6px 10px',
          fontSize: 13,
        },
      },
    },
  },
  palette: {
    link: {
      primary: {
        default: '#FF6A00',
        hover: '#FF6A00',
        active: '#FF6A00',
      },
      nav: {
        default: '#484848',
        hover: '#333',
        active: '#333',
      },
    },
    primary: {
      main: '#A08C64',
    },
    secondary: {
      main: '#327DFF',
    },
    success: {
      main: '#7FCC5B',
      light: '#F2FAEF',
      dark: '#30382C',
      contrastText: '#fff',
    },
    info: {
      main: '#3881FF',
      light: '#E1EAFA',
      dark: '#28303D',
      contrastText: '#fff',
    },
    error: {
      contrastText: '#fff',
      main: '#FF2E2E',
      light: '#FFEAEA',
      dark: '#3D2828',
    },
    warning: {
      main: '#FFAE57',
      light: '#FFFCEF',
      dark: '#3D392D',
      contrastText: 'rgba(0, 0, 0, 0.77)',
    },
    grey: {
      '50': '#FAFAFA',
      '100': '#F5F5F5',
      '200': '#EEEEEE',
      '300': '#E0E0E0',
      '400': '#BDBDBD',
      '500': '#9E9E9E',
      '600': '#757575',
      '700': '#616161',
      '800': '#424242',
      '900': '#212121',
    },
    getGrey() {
      return 'cyan';
    },
    severity: {
      critical: {
        main: '#FF2E2E',
        dark: 'cyan',
        light: 'cyan',
        contrastText: '#fff',
      },
      high: {
        main: '#FF824B',
        dark: 'cyan',
        light: 'cyan',
        contrastText: '#fff',
      },
      medium: {
        main: '#FFAE57',
        dark: 'cyan',
        light: 'cyan',
        contrastText: '#fff',
      },
      low: {
        main: '#FFDD63',
        dark: 'cyan',
        light: 'cyan',
        contrastText: '#fff',
      },
      info: {
        main: '#3881FF',
        dark: 'cyan',
        light: 'cyan',
        contrastText: '#fff',
      },
      fallback: {
        main: 'cyan',
        dark: 'cyan',
        light: 'cyan',
        contrastText: '#fff',
      },
    },
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily: 'SandvikSansText',
    // named "h3" in design system
    h1: {
      fontFamily: 'SandvikSansHeadline',
      fontSize: '48px',
      fontWeight: 400,
      lineHeight: '116.7%',
    },
    // named "h4" in design system
    h2: {
      fontFamily: 'SandvikSansText',
      fontSize: '30px',
      fontWeight: 400,
      lineHeight: '123.5%',
      letterSpacing: '0.25px',
    },
    // named "h5" in design system
    h3: {
      fontFamily: 'SandvikSansText',
      fontSize: '24px',
      fontWeight: 400,
      lineHeight: '133.4%',
    },
    // named "h6" in design system
    h4: {
      fontFamily: 'SandvikSansText',
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: '160%',
      letterSpacing: '0.15px',
    },
    h5: {
      fontFamily: 'SandvikSansText',
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: '160%',
      letterSpacing: '0.15px',
    },
    h6: {
      fontFamily: 'SandvikSansText',
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: '160%',
      letterSpacing: '0.15px',
    },
    subtitle1: {
      fontFamily: 'SandvikSansText',
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: 1.1875,
      letterSpacing: '0.5px',
    },
    subtitle2: {
      fontFamily: 'SandvikSansText',
      fontSize: '0.875rem',
      fontWeight: 700,
      lineHeight: 1.14,
      letterSpacing: '0.5px',
    },
    body1: {
      fontFamily: 'SandvikSansText',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '150%',
      letterSpacing: '0.15px',
    },
    body2: {
      fontFamily: 'SandvikSansText',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '143%',
      letterSpacing: '0.17px',
    },
    overline: {
      fontFamily: 'SandvikSansText',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '266%',
      letterSpacing: '1px',
    },
    button: {
      fontFamily: 'SandvikSansText',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '0.4px',
      textTransform: 'none',
    },
  },
};
