import { usePermissions } from '@/feature/permissions/PermissionsProvider';
import { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { NotFound } from '../app/routing/NotFound';
import {
  companiesPath,
  equipmentPath,
  samAccountPath,
  sitesPath,
  usersPath,
} from '../pathDefinitions';
import CompanyRoutes from './company/routes';
import EquipmentRoutes from './equipment/routes';
import SamAccountRoutes from './samaccount/routes';
import SiteRoutes from './site/routes';
import UserRoutes from './user/routes';

export default () => {
  const permissions = usePermissions();
  const defaultRoute = permissions.some((p) => p.startsWith('user:'))
    ? usersPath
    : samAccountPath;

  return (
    <Suspense fallback={<></>}>
      <Routes>
        <Route path="/" element={<Navigate to={defaultRoute} replace />} />
        <Route path={`${companiesPath}/*`} element={<CompanyRoutes />} />
        <Route path={`${sitesPath}/*`} element={<SiteRoutes />} />
        <Route path={`${equipmentPath}/*`} element={<EquipmentRoutes />} />
        <Route path={`${usersPath}/*`} element={<UserRoutes />} />
        <Route path={`${samAccountPath}/*`} element={<SamAccountRoutes />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};
