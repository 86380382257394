import { List, ListProps, Paper } from '@mui/material';
import { MenuProvider } from './context';

interface MenuProps extends ListProps {
  onClose?: () => void;
}

const Menu = ({ onClose, children, ...listProps }: MenuProps) => {
  return (
    <Paper elevation={8}>
      <MenuProvider onClose={onClose}>
        <List {...listProps} sx={{ minWidth: '250px' }}>
          {children}
        </List>
      </MenuProvider>
    </Paper>
  );
};

export default Menu;
