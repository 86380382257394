import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import type { HTMLAttributes, ReactChild } from 'react';
import { forwardRef, useEffect, useRef } from 'react';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

const LISTBOX_PADDING = 8;

const OptionText = styled(Typography, { name: 'OptionText' })({
  display: 'block',
});

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const rowData = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  const [rowProps, rowLabel] = rowData;
  return (
    <li {...rowProps} style={inlineStyle}>
      <OptionText variant="body2" noWrap>
        {rowLabel}
      </OptionText>
    </li>
  );
}

function useResetCache(data: any) {
  const ref = useRef<FixedSizeList>(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.scrollTo(0);
    }
  }, [data]);
  return ref;
}

const ITEM_HEIGHT = 32;

const ListboxComponentVirtualized = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData: ReactChild[] = [];
  (children as ReactChild[]).forEach(
    (item: ReactChild & { children?: ReactChild[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    }
  );

  const itemCount = itemData.length;
  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * ITEM_HEIGHT;
    }
    return itemCount * ITEM_HEIGHT;
  };
  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <FixedSizeList
        itemData={itemData}
        height={getHeight() + 2 * LISTBOX_PADDING}
        width="100%"
        outerElementType={forwardRef<HTMLDivElement>((props, ref) => (
          <div {...props} {...other} ref={ref} />
        ))}
        innerElementType={(props) => (
          <ul {...props} style={{ ...props.style, margin: 0, padding: 0 }} />
        )}
        itemSize={ITEM_HEIGHT}
        overscanCount={10}
        itemCount={itemCount}
        ref={gridRef}
      >
        {renderRow}
      </FixedSizeList>
    </div>
  );
});

const VirtualizedAutocomplete = forwardRef(
  (props: AutocompleteProps<any, any, any, any>, ref) => {
    const { getOptionLabel } = props;

    return (
      <MuiAutocomplete
        {...props}
        // @ts-ignore
        renderOption={(props, option) => [props, getOptionLabel?.(option)]}
        ListboxComponent={ListboxComponentVirtualized}
        ref={ref}
      />
    );
  }
);

export default VirtualizedAutocomplete;
