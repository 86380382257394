import { lazy } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { NotFound } from '../../app/routing/NotFound';
import { shoppinglistsFullPath } from '../../pathDefinitions';

const ShoppingLists = lazy(() => import('./list/ShoppingLists'));
const ShoppingListPage = lazy(() => import('./details/ShoppingListPage'));

export const useNavigateToShoppingList = () => {
  const navigate = useNavigate();
  return (listId: string) => navigate(`${shoppinglistsFullPath}/${listId}`);
};

export const shoppingListIdParam = 'listId';

const ShoppingListRoutes = () => (
  <Routes>
    <Route path="/" element={<ShoppingLists />} />
    <Route path={`:${shoppingListIdParam}`} element={<ShoppingListPage />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
);

export default ShoppingListRoutes;
