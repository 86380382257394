import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { NotFound } from '../../app/routing/NotFound';

const ListEquipmentRoute = lazy(() => import('./list/ListEquipmentRoute'));
const EquipmentDetailsRoute = lazy(
  () => import('./details/EquipmentDetailsRoute')
);

export const equipmentIdParam = 'equipmentId';

export default () => (
  <Routes>
    <Route path="/" element={<ListEquipmentRoute />} />
    <Route path={`:${equipmentIdParam}`} element={<EquipmentDetailsRoute />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
);
