import { useSessionStorage } from '@/util/useStorage';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

export const useShowKeys = () => {
  const [showKeys, setShowKeys] = useSessionStorage(
    'settings.showTextKeys',
    false
  );
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries();
  }, [showKeys, queryClient]);

  return [showKeys, setShowKeys] as const;
};
