import type { Translation } from '@/feature/lang/types';
import { toSxArray } from '@/theme/util';
import useRememberScroll from '@/util/useRememberScroll';
import type { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import type { SxProps } from '@mui/system';
import type { CSSProperties, ReactNode } from 'react';
import { Box } from '../Box';
import Translate from '../Translate';
import Pane from './Pane';

interface PaneProps {
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
  sx?: SxProps<Theme>;
}

const PaneFixedHeader = ({ children, className, style, sx }: PaneProps) => {
  return (
    <Pane
      sx={[
        {
          display: 'flex',
          flexDirection: 'column',
        },
        ...toSxArray(sx),
      ]}
      className={className}
      style={style}
      dense
    >
      {children}
    </Pane>
  );
};

type HeaderProps = HeaderWithTitle | HeaderWithChildren;

interface HeaderWithTitle {
  title: Translation;
  children?: never;
  sx?: SxProps;
}

interface HeaderWithChildren {
  title?: never;
  children: ReactNode;
  sx?: SxProps;
}

const Header = ({ title, children, sx }: HeaderProps) => (
  <Box
    sx={[
      {
        flex: 0,
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        p: 2,
        minHeight: '72',
      },
      ...toSxArray(sx),
    ]}
  >
    {title ? (
      <Typography variant="h2">
        <Translate text={title} />
      </Typography>
    ) : (
      children
    )}
  </Box>
);

interface ContentProps {
  backVisible?: boolean;
  children?: ReactNode;
  scrollSlot?: string;
  sx?: SxProps<Theme>;
}

const Content = ({
  children,
  backVisible = false,
  scrollSlot,
  sx,
}: ContentProps) => {
  const ref = useRememberScroll(scrollSlot);

  return (
    <Box
      sx={[
        {
          flex: 1,
          overflow: 'auto',
          p: 2,
          pt: 0,
          ml: {
            xs: 0,
            md: backVisible ? 7 : 0,
          },
        },
        ...toSxArray(sx),
      ]}
      ref={ref}
    >
      {children}
    </Box>
  );
};

PaneFixedHeader.Header = Header;
PaneFixedHeader.Content = Content;

export default PaneFixedHeader;
