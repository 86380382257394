import { sortBy } from 'lodash-es';

export function createEmptyCart(): CartMutable {
  return {
    items: [],
  };
}

export function sortItems(cart: CartDto) {
  cart.items = sortBy(cart.items, 'position');
  return cart;
}

export function getItemQuantityTotal(
  itemId: Number,
  selectedDeliveryPointId?: string,
  cart?: CartDto
) {
  if (!cart || !selectedDeliveryPointId) {
    return;
  }

  return cart.items
    .filter((item) => item.deliveryPointEntityId === selectedDeliveryPointId)
    .filter((item) => item.itemId === itemId)
    .reduce((sum, item) => sum + item.quantity, 0);
}
