import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { NotFound } from '../../app/routing/NotFound';

const ListUsersRoute = lazy(() => import('./list/ListUsersRoute'));
const UserDetailsRoute = lazy(() => import('./details/UserDetailsRoute'));

export const userIdParam = 'userId';

export default () => (
  <Routes>
    <Route path="/" element={<ListUsersRoute />} />
    <Route path={`:${userIdParam}`} element={<UserDetailsRoute />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
);
