import type { ReactNode } from 'react';
import { createContext, useContext } from 'react';
import { useContainerQuery } from 'react-container-query';
import type { ContainerQueries } from 'react-container-query/src/interfaces';
import { useAside } from './aside/hooks';
import type { AsideApi } from './aside/types';

export interface LayoutBreakpoints {
  medium: ContainerQueries;
  large: ContainerQueries;
  xlarge: ContainerQueries;
}

export type LayoutSize = 'medium' | 'large' | 'xlarge';

export type LayoutMode = 'stack' | 'multi-panel';

interface LayoutContextData {
  layout: LayoutMode;
  size: LayoutSize;
  aside: AsideApi;
}

const LayoutContext = createContext<LayoutContextData>({
  layout: 'multi-panel',
  size: 'xlarge',
  aside: {
    open() {},
    close() {},
    selected: {
      kind: undefined,
      id: undefined,
    },
    isOpen: false,
  },
});

export const useLayout = () => useContext(LayoutContext);

function getSize(container: any): LayoutSize {
  switch (true) {
    case container.medium:
      return 'medium';
    case container.large:
      return 'large';
    case container.xlarge:
    default:
      return 'xlarge';
  }
}

const layouts: Record<LayoutSize, LayoutMode> = {
  medium: 'stack',
  large: 'multi-panel',
  xlarge: 'multi-panel',
};

interface LayoutProviderProps {
  breakpoints: LayoutBreakpoints;
  children: ReactNode;
}

export const LayoutProvider = ({
  children,
  breakpoints,
}: LayoutProviderProps) => {
  // @ts-ignore
  const [container, ref] = useContainerQuery(breakpoints, {});
  const aside = useAside();
  const size = getSize(container);
  const layout = layouts[size];

  return (
    <LayoutContext.Provider value={{ layout, size, aside }}>
      <div ref={ref}>{children}</div>
    </LayoutContext.Provider>
  );
};
