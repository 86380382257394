import type { QueryKey } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import { SampleResponse } from '../types';

export const getSampleQueryKey = ({
  equipmentId,
  signalName,
}: {
  equipmentId: string | undefined;
  signalName: string | undefined;
}): QueryKey => [
  equipmentId ?? 'eq-id-missing',
  'stream',
  'sample',
  signalName ?? 'signal-missing',
];

export const useSampleCacheUpdater = () => {
  const queryClient = useQueryClient();

  return (response: SampleResponse) => {
    const { equipmentId, signalName } = response;
    const key = getSampleQueryKey({ equipmentId, signalName });
    queryClient.setQueryData(key, response);
  };
};
