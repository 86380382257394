import { truncate as lodashTruncate } from 'lodash-es';

export const equals = (a: string | undefined, b: string | undefined) => {
  return typeof a === 'string' && typeof b === 'string'
    ? a.localeCompare(b, undefined, { sensitivity: 'base' }) === 0
    : a === b;
};

export function truncate(val: unknown, length: number = 30) {
  const options = { length };
  if (typeof val === 'string') {
    return lodashTruncate(val, options);
  }
  if (typeof val === 'number') {
    return lodashTruncate(val.toString(), options);
  }
  return val;
}
