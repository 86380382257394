import { Menu, MenuItem } from '@/component/Menu';
import Translate from '@/component/Translate';
import useLogout from '@/feature/auth/useLogout';
import { useTexts } from '@/feature/lang';
import { Icon } from '@/icon/Icon';
import ThemeContext from '@/theme/ThemeContext';
import { Typography } from '@mui/material';
import MuiDivider from '@mui/material/Divider';
import { useContext } from 'react';

interface UserActionsProps {
  onClose?: () => void;
  onPreferencesOpen: () => void;
}

const UserActions = ({ onClose, onPreferencesOpen }: UserActionsProps) => {
  const logout = useLogout();
  const { user } = useTexts();
  const { name, setTheme } = useContext(ThemeContext);

  return (
    <Menu onClose={onClose}>
      <MenuItem
        label={user.action.openPreferences}
        icon={<Icon name="settings" />}
        onClick={onPreferencesOpen}
      />
      <MuiDivider />
      <Typography variant="body2" fontWeight="500" sx={{ px: 2, py: 1 }}>
        <Translate text={user.theme.header} />
      </Typography>
      <MenuItem
        label={user.theme.light}
        icon={<Icon name="light_mode" />}
        onClick={() => setTheme('light')}
        disabled={name === 'light'}
        selected={name === 'light'}
      />
      <MenuItem
        label={user.theme.dark}
        icon={<Icon name="dark_mode" />}
        onClick={() => setTheme('dark')}
        disabled={name === 'dark'}
        selected={name === 'dark'}
      />
      <MenuItem
        label={user.theme.automatic}
        icon={<Icon name="auto_mode" />}
        onClick={() => setTheme('auto')}
        disabled={name === 'auto'}
        selected={name === 'auto'}
      />
      <MuiDivider />
      <MenuItem
        label={user.action.logout}
        icon={<Icon name="logout" />}
        onClick={logout}
      />
    </Menu>
  );
};

export default UserActions;
