import { useOnNavigateEffect } from '@/util/useOnNavigationEffect';
import { SeverityLevel } from '@microsoft/applicationinsights-common';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import type { ErrorInfo, ReactElement, ReactNode } from 'react';
import { Component } from 'react';

type IAppInsightsErrorBoundaryProps = {
  appInsights: ReactPlugin;
  onError: (error?: any) => ReactElement;
  children: ReactNode;
};

export interface IAppInsightsErrorBoundaryState {
  hasError: boolean;
  error?: any;
}

class AppInsightsErrorBoundary extends Component<
  IAppInsightsErrorBoundaryProps,
  IAppInsightsErrorBoundaryState
> {
  state = { hasError: false, error: undefined };

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.props.appInsights.trackException({
      error: error,
      exception: error,
      severityLevel: SeverityLevel.Error,
      properties: errorInfo,
    });
  }

  render() {
    if (this.state.hasError) {
      const { onError } = this.props;
      return (
        <>
          <NavigationListener
            onNavigation={() => this.setState({ hasError: false })}
          />
          {onError(this.state.error)}
        </>
      );
    }

    return this.props.children;
  }
}

export default AppInsightsErrorBoundary;

const NavigationListener = ({ onNavigation }: { onNavigation: () => void }) => {
  useOnNavigateEffect(onNavigation);
  return null;
};
