import { useLocalStorage } from '@/util/useStorage';
import { createContext, ReactNode, useContext } from 'react';

interface IAccountProviderContext {
  account: BusinessEntityBase | undefined;
  setAccount: (account: BusinessEntityBase | undefined) => void;
}

const AccountProviderContext = createContext<IAccountProviderContext>({
  account: undefined,
  setAccount: () => {},
});

export const useAccountSelector = () => useContext(AccountProviderContext);

interface AccountProviderProps {
  children: ReactNode;
}

const AccountProvider = ({ children }: AccountProviderProps) => {
  const [account, setAccount] = useLocalStorage<BusinessEntityBase | undefined>(
    'user.account',
    undefined
  );

  return (
    <AccountProviderContext.Provider
      value={{
        account,
        setAccount,
      }}
    >
      {children}
    </AccountProviderContext.Provider>
  );
};

export default AccountProvider;
