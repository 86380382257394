import { styled } from '@mui/material/styles';
import { Icon } from '@/icon/Icon';
import type { IconSize } from '@/icon/types';
import BlurhashImage from '../BlurhashImage/BlurhashImage';
import type { Size } from './types';
import { getAvatarImgInfo, getSize } from './utils';

interface RootProps {
  size: Size;
}

// Notice that height & width need to match `getSize()` in utils.ts.
const Root = styled('div', {
  name: 'Avatar-root',
  shouldForwardProp: (propName) => propName !== 'size',
})<RootProps>(({ size, theme }) =>
  theme.unstable_sx([
    {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      overflow: 'hidden',
      width: '3rem',
      height: '3rem',
    },
    size === 'xs' && {
      width: '1.5rem',
      height: '1.5rem',
    },
    size === 'sm' && {
      width: '2rem',
      height: '2rem',
    },
    size === 'lg' && {
      width: '4rem',
      height: '4rem',
    },
    size === 'xl' && {
      width: '8rem',
      height: '8rem',
    },
    size === 'xxl' && {
      width: '10rem',
      height: '10rem',
    },
  ])
);

const avatarSizeToFallbackMapping: Record<Size, IconSize> = {
  xs: 'medium',
  sm: 'medium',
  md: 'medium',
  lg: 'large',
  xl: 'xlarge',
  xxl: 'xxlarge',
};

interface AvatarProps {
  profileImage?: ImagesDto;
  firstName?: string;
  lastName?: string;
  size?: Size;
}

const Avatar = ({
  firstName,
  lastName,
  profileImage,
  size = 'md',
}: AvatarProps) => {
  const sizeInUnits = getSize(size);
  const avatarImgInfo = getAvatarImgInfo(profileImage);
  const fallbackSize = avatarSizeToFallbackMapping[size] ?? 'medium';

  return (
    <Root size={size}>
      {avatarImgInfo ? (
        <BlurhashImage
          blurhash={avatarImgInfo.blurhash}
          src={avatarImgInfo.src}
          width={sizeInUnits}
          height={sizeInUnits}
          alt={`${firstName} ${lastName}`}
        />
      ) : (
        <Icon name="account_circle" size={fallbackSize} />
      )}
    </Root>
  );
};

export default Avatar;
