import { formatDateAndTime } from '@/util/date';
import { hasDecimals } from '@/util/number';
import { Unit } from './Unit';

type TValue = string | number | Date | null | undefined;

export function formatValue(value: TValue) {
  let displayValue = value;

  if (typeof value === 'number' && hasDecimals(value)) {
    displayValue = value?.toFixed(1);
  }
  if (value instanceof Date) {
    displayValue = formatDateAndTime(value, {
      timeOptions: { timeStyle: 'medium' },
    });
  }
  if (displayValue === null || displayValue === undefined) {
    displayValue = '-';
  }
  return displayValue;
}

interface ValueProps {
  value: TValue;
  unit?: string;
}

const Value = ({ value, unit }: ValueProps) => {
  const showUnit = value !== undefined && value !== null;

  return (
    <>
      {formatValue(value)}
      {showUnit && (
        <>
          {' '}
          <Unit unit={unit} />
        </>
      )}
    </>
  );
};

export default Value;
