import {
  ListItemButton as MuiListItemButton,
  ListItemButtonBaseProps,
} from '@mui/material';
import type { MouseEventHandler, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

interface ListItemButtonProps extends ListItemButtonBaseProps {
  to: string;
  children: ReactNode;
  mainItemActive: boolean;
  selectable?: boolean;
  onClick?: MouseEventHandler;
}

export const ListItemButton = ({
  to,
  children,
  mainItemActive,
  selectable,
  ...props
}: ListItemButtonProps) => {
  return (
    <MuiListItemButton
      to={to}
      sx={(theme) => ({
        ...(selectable && {
          borderRight: '2px solid transparent',
          '&.active': {
            borderRightColor: theme.palette.secondary.main,
          },
          '&.active, &.active:hover, &:hover': {
            backgroundColor: theme.palette.getGrey(20),
          },
        }),
      })}
      component={NavLink}
      disableRipple
      {...props}
    >
      {children}
    </MuiListItemButton>
  );
};
