import { styled } from '@mui/material/styles';
import LogoSvg from './asset/logo-v2.svg';

const SamLogo = styled(LogoSvg)(({ theme }) => ({
  height: 24,
  width: 'auto',
  '& path': { fill: theme.palette.text.primary },
}));

export default SamLogo;
