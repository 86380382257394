import { Row } from 'react-table';

export default {
  selectRow: function (
    row: Row,
    selected: boolean,
    currentSelection: RowSelectionInternal = {}
  ) {
    const newRowSelection = actions.toggle(currentSelection, row, selected);
    return this.toExternal(newRowSelection);
  },
  selectAllRows: function (rows: Row[] = [], selected: boolean) {
    const newRowSelection = selected
      ? actions.addAll(rows)
      : actions.createEmpty();

    return this.toExternal(newRowSelection);
  },
  toExternal: function (selection: RowSelectionInternal = {}): RowSelection {
    return Object.keys(selection);
  },
  toInternal: function (selection: RowSelection = []): RowSelectionInternal {
    return selection.reduce<RowSelectionInternal>(
      (selectionInternalFormat, rowId) => {
        selectionInternalFormat[rowId] = true;
        return selectionInternalFormat;
      },
      {}
    );
  },
};

const actions = {
  add: (selection: RowSelectionInternal = {}, row: Row) => {
    const newSelection = { ...selection };
    newSelection[row.id] = true;
    return newSelection;
  },
  addAll: (rows: Row[] = []) =>
    rows.reduce((selection: RowSelectionInternal, row) => {
      selection[row.id] = true;
      return selection;
    }, {}),
  remove: (selection: RowSelectionInternal = {}, row: Row) => {
    const newSelection = { ...selection };
    delete newSelection[row.id];
    return newSelection;
  },
  toggle: function (
    selection: RowSelectionInternal = {},
    row: Row,
    selected: boolean
  ) {
    return selected
      ? actions.add(selection, row)
      : actions.remove(selection, row);
  },
  createEmpty: () => ({}),
};
