// Generated by ts-to-zod
import { z } from 'zod';

export const alarmTypeSchema = z.union([
  z.literal('alarm_type_geofence_location'),
  z.string(),
]);

export const alarmStatusSchema = z.union([
  z.literal('OPEN'),
  z.literal('CLOSED'),
]);

export const alarmSeveritySchema = z.union([
  z.literal('LOW'),
  z.literal('MEDIUM'),
  z.literal('HIGH'),
  z.literal('CRITICAL'),
  z.literal('INFO'),
  z.literal('UNKNOWN'),
]);

export const alarmDtoSchema = z.object({
  id: z.string(),
  alarmType: alarmTypeSchema,
  equipmentId: z.string(),
  eventTriggered: z.string(),
  properties: z
    .object({
      'email-from': z.string(),
      'company-name': z.string(),
      'email-reply-to': z.string(),
      'equipment-name': z.string(),
      'equipment-model': z.string(),
      'equipment-serial-number': z.string(),
      'push-notification-heading': z.string(),
      'push-notification-subject': z.string(),
      'signal-monitoring-service-event-signal-battery': z.string(),
      'signal-monitoring-service-event-signal-subject': z.string(),
    })
    .optional(),
  service: z.string(),
  status: alarmStatusSchema,
  name: z.string().optional(),
  severity: alarmSeveritySchema,
  severityLabel: z.string(),
  actions: z.string().optional(),
  consequences: z.string().optional(),
});

export const alarmDataSchema = z.object({
  alarms: z.array(alarmDtoSchema),
  totalCount: z.number(),
});
