import Avatar from '@/component/Avatar/Avatar';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@/component/Dialog';
import { Flex } from '@/component/Flex';
import Translate from '@/component/Translate';
import useTranslation from '@/feature/lang/hook';
import { useCurrentUser } from '@/feature/user/queries';
import useIsSmallScreen from '@/util/useIsSmallScreen';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import Content from './Content';
import { TabInfo } from './types';

interface PreferencesDialogProps {
  open: boolean;
  onClose: () => void;
}

const PreferencesDialog = ({ open, onClose }: PreferencesDialogProps) => {
  const { texts } = useTranslation();
  const [tab, setTab] = useState<TabInfo>(TabInfo.PersonalInfo);
  const { firstName, lastName, image, email } = useCurrentUser();
  const isSmallScreen = useIsSmallScreen();

  return (
    <Dialog open={open} maxWidth="lg" onClose={onClose}>
      <DialogTitle
        title={texts.user.preferences.header}
        right={
          <Flex gap={1}>
            {!isSmallScreen && tab !== TabInfo.PersonalInfo && (
              <>
                <Avatar
                  firstName={firstName}
                  lastName={lastName}
                  profileImage={image}
                />
                <Flex
                  flexFlow="column nowrap"
                  alignItems="flex-start"
                  gap={0}
                  sx={{ mr: 4 }}
                >
                  <Typography variant="h3" color="primary">
                    {email}
                  </Typography>
                  <Typography variant="h3">
                    {firstName + ' ' + lastName}
                  </Typography>
                </Flex>
              </>
            )}
          </Flex>
        }
      />
      <DialogContent>
        <Content tab={tab} setTab={setTab} />
      </DialogContent>
      <DialogActions
        right={
          <>
            <Button
              onClick={onClose}
              variant="outlined"
              key="close"
              color="inherit"
            >
              <Translate text={texts.component.button.common.close} />
            </Button>
          </>
        }
      />
    </Dialog>
  );
};

export default PreferencesDialog;
