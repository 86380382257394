export const rootRealmId = '00000000-0000-4483-bbfe-7c88050652f8';

export const rootEntityId = '00000000-0000-4785-bc45-7c88050652f8';

export const whitelistPropertiesForUpdate = [
  'category',
  'connectivity',
  'country',
  'displayName',
  'externalId',
  'externalIdType',
  'id',
  'locale',
  'manufacturer',
  'modelNumber',
  'modelNumberExtended',
  'serialNumber',
  'timeZone',
  'type',
  'version',
];
