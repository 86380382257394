import { getHeaders } from '@/api/apiClient';
import { baseUrl } from '@/api/defaults';
import { track } from '@/feature/tracking';

const preLoginTokenName = 'token';

/**
 * Consume a onetime token/id from the url, exchange this for token ("access_token") and refreshToken ("refresh_token")
 * that can then be used to initialize the keycloak adapter to a logged in state (no redirect needed).
 * The functionality is intended for Ecom Punchout sessions, but may be expanded to cover other use cases.
 */
export const getPreLoginTokens = async () => {
  const url = new URL(window.location.href);
  const preLoginToken = url.searchParams.get(preLoginTokenName);
  if (!preLoginToken) {
    return;
  }

  track('punchout_session_initiated');
  url.searchParams.delete(preLoginTokenName);
  window.history.replaceState(null, '', url.href);

  const response = await fetch(
    new Request(`${baseUrl}/punchout/token`, {
      method: 'post',
      body: JSON.stringify({ oneTimeCode: preLoginToken }),
      headers: getHeaders({ Authorization: '' }),
    })
  );

  if (response.ok) {
    const json = await response.json();
    const token = json.data.access_token;
    const refreshToken = json.data.refresh_token;
    return {
      token,
      refreshToken,
    };
  }
};
