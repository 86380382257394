import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@/component/Dialog';
import Translate from '@/component/Translate';
import useTranslation from '@/feature/lang/hook';
import { Typography } from '@mui/material';
import type { ReactNode } from 'react';
import { lazy, Suspense } from 'react';

const AgreementContent = lazy(() => import('./AgreementContent'));

interface AgreementDialogProps {
  open: boolean;
  agreementTypes: AgreementType[];
  actions: ReactNode;
  isRejected: boolean;
}

export const AgreementDialog = ({
  open = false,
  actions,
  agreementTypes,
  isRejected,
}: AgreementDialogProps) => {
  const { texts } = useTranslation();

  return (
    <Dialog open={open} maxWidth="lg">
      <DialogTitle title={texts.auth.registration.agreements.message} />
      <DialogContent>
        <Suspense>
          {isRejected ? (
            <RejectedAgreementContent />
          ) : (
            <AgreementContent agreementTypes={agreementTypes} />
          )}
        </Suspense>
      </DialogContent>
      <DialogActions right={actions}></DialogActions>
    </Dialog>
  );
};

const RejectedAgreementContent = () => {
  const { texts } = useTranslation();
  return (
    <>
      <Typography sx={{ mb: 5 }} variant="body1">
        <Translate text={texts.agreement.action.rejectedFeedback} />
      </Typography>
      <Typography sx={{ mb: 5 }} variant="body1">
        <Translate text={texts.agreement.action.rejectedFeedbackSamName} />
      </Typography>
    </>
  );
};
