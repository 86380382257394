import { Divider } from '@mui/material';
import { lazy } from 'react';
import AdminActions from './AdminActions';
import { LangSelector } from './LangSelector';
import User from './User';

const CartAppBarAction = lazy(
  () => import('../../ecom/cart/CartPopper/CartAppBarAction')
);
const DeliveryPointAppBarAction = lazy(
  () => import('../../ecom/deliveryPoint/DeliveryPointAppBarAction')
);

export default [
  <User />,
  <AdminActions />,
  <LangSelector />,
  <Divider orientation="vertical" sx={{ mx: 2 }} />,
  <CartAppBarAction />,
  <DeliveryPointAppBarAction />,
];
