import type { ReactNode } from 'react';
import { rootRealmId } from '../businessEntity/constants';
import { useCurrentUser } from '../user/queries';

interface IsRootRealmUserProps {
  children: ReactNode;
}

const IsRootRealmUser = ({ children }: IsRootRealmUserProps) => {
  const isRootRealmUser = useIsRootRealmUser();

  return isRootRealmUser ? <>{children}</> : null;
};

export default IsRootRealmUser;

export const useIsRootRealmUser = () => {
  const user = useCurrentUser();

  return user.realm.id === rootRealmId;
};
