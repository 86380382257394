import { ONE_HOUR } from '@/util/time';
import { UseQueryOptions } from '@tanstack/react-query';
import { sortBy } from 'lodash-es';
import { useBusinessEntityQuery } from '../businessEntity/queries';
import { formatDeliveryPointAddress } from './address';

export const useDeliveryPointsQuery = (
  queryOptions: UseQueryOptions<DeliveryPointDto[]> = {}
) =>
  useBusinessEntityQuery<DeliveryPointDto[]>(
    {
      type: 'mdg_delivery_point',
    },
    {
      include: ['parents', 'permits'],
    },
    {
      cacheTime: ONE_HOUR,
      ...queryOptions,
    }
  );

const useDeliveryPoints = () => {
  const { data = [], ...rest } = useDeliveryPointsQuery();

  const deliveryPoints = sortBy(data, 'mdgId').filter((d) =>
    d.permits?.includes('ecom.price:r')
  );
  const options: DeliveryPointOption[] = deliveryPoints
    .filter((d) => d.permits?.includes('ecom.order:w'))
    .map((deliveryPoint) => ({
      id: deliveryPoint.id,
      title: formatDeliveryPointAddress(deliveryPoint),
    }));

  return { deliveryPoints, options, ...rest };
};

export default useDeliveryPoints;

export const useDeliveryPoint = (deliveryPointId?: string) => {
  const { deliveryPoints } = useDeliveryPoints();

  return deliveryPoints.find(
    (deliveryPoint) => deliveryPoint.id === deliveryPointId
  );
};
