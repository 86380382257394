import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { NotFound } from '../../app/routing/NotFound';

const SamAccount = lazy(() => import('./SamAccount'));

export default () => (
  <Routes>
    <Route path="/" element={<SamAccount />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
);
