import Avatar from '@/component/Avatar/Avatar';
import { Flex } from '@/component/Flex';
import { Parameter, ParameterList } from '@/component/ParameterList';
import Translate from '@/component/Translate';
import useTranslation from '@/feature/lang/hook';
import { useCurrentUser } from '@/feature/user/queries';
import Typography from '@mui/material/Typography';
import { ContentContainer } from '../components/ContentContainer';
import { Section } from '../components/Section';
import ChangePassword from './ChangePassword';
import { sectionSize } from './layout';

interface PersonalInfoProps {
  visible: boolean;
}

const PersonalInfo = ({ visible }: PersonalInfoProps) => {
  const { firstName, lastName, image, email } = useCurrentUser();
  const { texts } = useTranslation();
  return (
    <ContentContainer visible={visible}>
      <Section>
        <Flex flexFlow="column nowrap" gap={2}>
          <Avatar
            firstName={firstName}
            lastName={lastName}
            profileImage={image}
            size="xl"
          />
          <Typography variant="h2">
            <Translate
              text={texts.user.preferences.welcome}
              values={{ firstName, lastName }}
            />
          </Typography>
        </Flex>
      </Section>
      <Section>
        <ParameterList
          title={texts.user.preferences.personalInfo.contact}
          keyColumnWidth={sectionSize.keyColumnWidth}
          fullWidth
        >
          <Parameter
            label={texts.admin.user.model.firstName}
            value={firstName}
          />
          <Parameter label={texts.admin.user.model.lastName} value={lastName} />
          <Parameter label={texts.admin.user.model.email} value={email} />
        </ParameterList>
      </Section>
      <Section>
        <ChangePassword />
      </Section>
    </ContentContainer>
  );
};

export default PersonalInfo;
