import MuiAccordion, {
  AccordionProps as MuiAccordionProps,
} from '@mui/material/Accordion';
import { styled } from '@mui/material/styles';
import { AccordionProvider, useAccordion } from './AccordionProvider';

export interface AccordionProps extends MuiAccordionProps {
  type?: AccordionType;
  id?: string;
  uncontrolledExpand?: boolean;
}

const Accordion = ({
  children,
  className,
  type = 'default',
  id,
  uncontrolledExpand = false,
  ...rest
}: AccordionProps) => {
  return (
    <AccordionProvider
      type={type}
      id={id}
      defaultExpanded={rest.defaultExpanded}
      uncontrolledExpand={uncontrolledExpand}
    >
      <AccordionContent {...rest}>{children}</AccordionContent>
    </AccordionProvider>
  );
};

export default Accordion;

interface StyledAccordionProps {
  type: AccordionType;
}

const StyledAccordion = styled(MuiAccordion, {
  name: 'Accordion',
  shouldForwardProp: (propName) => propName !== 'type',
})<StyledAccordionProps>(({ type, theme }) =>
  theme.unstable_sx([
    {
      border: '1px solid',
      borderColor: 'divider',
      borderRadius: 1,
      boxShadow: 'none',
      background: 'unset',
      mb: 2,
      '&:before': {
        display: 'none',
      },
      '&.Mui-expanded': {
        mt: 0,
        mb: 2,
      },
      '&.Mui-expanded:last-of-type': {
        mb: 2,
      },
    },
    type === 'default' && {
      borderColor: 'divider',
    },
    type === 'info' && {
      borderColor: 'info.main',
    },
    type === 'error' && {
      borderColor: 'error.main',
    },
    type === 'warning' && {
      borderColor: 'warning.main',
    },
    type === 'success' && {
      borderColor: 'success.main',
    },
  ])
);

const AccordionContent = ({ ...rest }: AccordionProps) => {
  const { type, expanded, setExpanded } = useAccordion();

  const handleExpand = (_: any, isExpanded: boolean) => {
    if (setExpanded) {
      setExpanded(isExpanded);
    }
  };

  return (
    <StyledAccordion
      expanded={expanded}
      onChange={handleExpand}
      {...rest}
      type={type}
    />
  );
};
