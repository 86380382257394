import { styled } from '@mui/material';
import { useState } from 'react';
import { Blurhash } from 'react-blurhash';
import { wash } from './blurhashUtil';

const Root = styled('div', {
  name: 'BlurhashImage',
  slot: 'Root',
})({
  position: 'relative',
});

const BlurhashContainer = styled('span', {
  name: 'BlurhashImage',
  slot: 'Blurhash',
})({
  position: 'absolute',
  opacity: 1,
  transition: 'opacity 0.2s',
  pointerEvents: 'none',
  '&[data-isloaded="true"]': {
    opacity: 0,
  },
});

const Image = styled('img', {
  name: 'BlurhashImage',
  slot: 'Image',
})({
  objectFit: 'cover',
});

interface BlurhashImageProps {
  src: string;
  alt: string;
  blurhash?: string;
  width: number | string;
  height: number | string;
  bustCache?: boolean;
}

const BlurhashImage = ({
  src,
  alt,
  blurhash,
  width,
  height,
  bustCache = false,
}: BlurhashImageProps) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const washedBlurhash = wash(blurhash);

  const onImageLoad = () => {
    setIsImageLoaded(true);
  };

  if (!src) {
    return null;
  }

  const imgSrc = bustCache
    ? `${src}?${Math.round(Math.random() * 1000000)}`
    : src;

  return (
    <Root style={{ height, width }}>
      {washedBlurhash && (
        <BlurhashContainer data-isloaded={isImageLoaded}>
          <Blurhash hash={washedBlurhash} width={width} height={height} />
        </BlurhashContainer>
      )}
      <Image
        src={imgSrc}
        onLoad={onImageLoad}
        style={{ width, height }}
        alt={alt}
      />
    </Root>
  );
};

export default BlurhashImage;
