import { useTexts, useTranslation } from '@/feature/lang';
import type { Translation } from '@/feature/lang/types';
import { cloneDeep } from 'lodash-es';

interface FieldConfig {
  label: Translation;
}

export const useValidation = (base: any, field: FieldConfig) => {
  const { translate } = useTranslation();
  const { component } = useTexts();

  const validation = cloneDeep(base) ?? {};
  if (validation.required === true) {
    validation.required = translate(component.form.validation.required, {
      label: field.label ?? component.form.validation.required.labelFallback,
    });
  }

  if (isNumeric(validation.minLength)) {
    validation.minLength = {
      message: translate(component.form.validation.minLength, {
        length: validation.minLength,
      }),
      value: validation.minLength,
    };
  }

  if (isNumeric(validation.maxLength)) {
    validation.maxLength = {
      message: translate(component.form.validation.maxLength, {
        length: validation.maxLength,
      }),
      value: validation.maxLength,
    };
  }

  if (isNumeric(validation.min)) {
    validation.min = {
      message: translate(component.form.validation.min, {
        min: validation.min,
      }),
      value: validation.min,
    };
  }

  if (isNumeric(validation.max)) {
    validation.max = {
      message: translate(component.form.validation.max, {
        max: validation.max,
      }),
      value: validation.max,
    };
  }

  return validation;
};

const isNumeric = (value: any) => typeof value === 'number';
