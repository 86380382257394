import env from '@/util/env';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';
import { suppressTelemetry } from './suppressTelemetry';

const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();
// Configuration reference:
// https://github.com/microsoft/ApplicationInsights-JS#configuration
const appInsights = new ApplicationInsights({
  config: {
    connectionString: env.APPINSIGHTS_CS,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
    autoTrackPageVisitTime: true,
    disableFetchTracking: false,
  },
});

appInsights.loadAppInsights();
appInsights.trackPageView();
appInsights.addTelemetryInitializer((envelope) => {
  if (suppressTelemetry(envelope)) {
    return false;
  }

  if (envelope.tags) {
    envelope.tags['ai.cloud.role'] = 'sam-web';
  }

  if (envelope.data) {
    envelope.data.href = window.location.href;
  }
});

// Disable tracking in local dev envs
if (process.env.NODE_ENV !== 'production') {
  appInsights.addTelemetryInitializer(() => false);
}

export { reactPlugin, appInsights };
