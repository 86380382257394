import { get, put } from '@/api/v1';
import type { LocalizationSettings, SettingType } from './api.types';

export const fetchLocalizationSettings = () =>
  get<LocalizationSettings>('userpreference/settings/l10n');

export const updateLocalizationSetting = (
  setting: SettingType,
  value: string
) => put(`userpreference/settings/l10n/${setting}`, { value });
