export enum EquipmentConnectivity {
  'NOT_CONNECTED' = 'not_connected',
  'CONNECTED' = 'connected_kb',
}

export enum SignalName {
  OilTemp = 'dOilTemp',
  CSS = 'dCSS',
  CSSSetPoint1 = 'dCSSSetPoint1',
  Pressure = 'dPressure',
  Power = 'dPower',
  Position = 'dPosition',
  TimeRunningSinceLinersChange = 'dTimeRunningSinceLinersChange',
  CtrlModeASRi = 'iCtrlModeASRi',
  Accuracy = 'dPositionAccuracy',
  Latitude = 'dLatitude',
  Longitude = 'dLongitude',
  WorkTime = 'iWorkTime',
  ImpactTime = 'iImpactTime',
  CalcLoad = 'iCalcLoad',
}
