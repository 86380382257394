import type { ElementType } from 'react';
import { useLayout } from '../LayoutContext';
import { AlarmAside } from './AlarmAside';

export interface AsideProps {
  id: string;
}

const components: Record<string, ElementType<AsideProps>> = {
  alarm: AlarmAside,
};

export const Aside = () => {
  const { aside } = useLayout();

  if (!aside.isOpen || !aside.selected.kind || !aside.selected.id) {
    return null;
  }

  const Component = components[aside.selected.kind]!;

  return <Component id={aside.selected.id} />;
};
