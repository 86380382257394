import { MutableRefObject, useEffect, useRef } from 'react';
import { attachToDebug } from './debug';

const storage = new Map();
attachToDebug('scroll', storage);

function save(ref: MutableRefObject<any>, slot: string) {
  if (ref.current) {
    storage.set(slot, ref.current.scrollTop);
  }
}

function scroll(ref: MutableRefObject<any>, slot: string) {
  if (!ref.current) {
    return;
  }
  const scrollTop = storage.get(slot);
  if (scrollTop !== null && scrollTop >= 0) {
    ref.current.scrollTop = scrollTop;
  }
}

const useRememberScroll = (slot: string | undefined) => {
  const ref = useRef<any>(null);

  useEffect(
    () => {
      if (!slot) {
        return;
      }

      const { current } = ref;
      const handler = () => save(ref, slot);
      current?.addEventListener('scroll', handler, { passive: true });
      scroll(ref, slot);

      return () => {
        current?.removeEventListener('scroll', handler);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [slot]
  );

  return ref;
};

export default useRememberScroll;
