import { TEN_MINUTES } from '@/util/time';
import { useQueries, useQuery } from '@tanstack/react-query';
import { fetchAgreement, fetchSignedAgreements } from './api';

export const useAgreementQuery = (type?: AgreementType) => {
  return useQuery(
    ['agreement', type],
    () => fetchAgreement(type as AgreementType).then(decode),
    { enabled: Boolean(type), staleTime: TEN_MINUTES }
  );
};

export const useAgreementsByTypeQuery = (agreementTypes: AgreementType[]) => {
  return useQueries({
    queries: agreementTypes.map((type) => ({
      queryKey: ['agreement', type],
      queryFn: () => fetchAgreement(type).then(decode),
      staleTime: TEN_MINUTES,
    })),
  });
};

export const useSignedAgreementsQuery = () => {
  return useQuery(['signedAgreements'], () => fetchSignedAgreements(), {
    staleTime: TEN_MINUTES,
  });
};

function decode(agreement?: AgreementDocument) {
  return {
    ...agreement,
    content: atob(agreement?.content || ''),
  };
}
