import { useCallback, useContext } from 'react';
import { LangContext } from './context';

const useTranslation = () => {
  return useContext(LangContext);
};

export const useTexts = () => {
  return useTranslation().texts;
};

export const useTranslateEnumValues = <T extends string>(
  values: any,
  emptyValue?: string
) => {
  const { translate } = useTranslation();
  type EnumValueKey = keyof typeof values;

  return useCallback(
    (value?: T) => {
      if (!value) {
        return emptyValue;
      }

      if (values.hasOwnProperty(value.toLowerCase())) {
        return translate(
          values[value.toLowerCase() as unknown as EnumValueKey]
        );
      }
      // Support old text keys with all capital letters as enum name, e.g. `fleet.model.category.values.JAW_CRUSHER`
      if (values.hasOwnProperty(value.toUpperCase())) {
        return translate(
          values[value.toUpperCase() as unknown as EnumValueKey]
        );
      }

      return value;
    },
    [translate, values, emptyValue]
  );
};

export default useTranslation;
