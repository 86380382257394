import { useState } from 'react';
import useDeliveryPoints from '../../businessRelations/useDeliveryPoints';
import { useCurrentUser } from '../../user/queries';
import { useUpdatePreferredDeliveryPoint } from './mutations';

export const useDefaultDeliveryPoint = () => {
  const { deliveryPoints, isLoading } = useDeliveryPoints();
  const user = useCurrentUser();
  const [savedDefault, setSavedDefault] =
    useState<DeliveryPointDto | undefined>(undefined);
  const defaultDeliveryPointId = savedDefault
    ? savedDefault.id
    : user?.preferredDeliveryPointEntityId;
  const defaultDeliveryPoint = deliveryPoints.find(
    (d) => d.id === defaultDeliveryPointId
  );
  const { mutateAsync: updateDefault } = useUpdatePreferredDeliveryPoint();

  const setDefaultDeliveryPoint = (deliveryPoint?: DeliveryPointDto) => {
    setSavedDefault(deliveryPoint);
    if (deliveryPoint) {
      updateDefault(deliveryPoint.id);
    }
  };

  return {
    defaultDeliveryPoint,
    setDefaultDeliveryPoint,
    isLoading,
  };
};
