import { uniqBy } from 'lodash-es';
import type { SampleTransformer } from './index';
import { getPreviousSample, getSamplesForSignal, getSignals } from './utils';

/**
 * Fills gaps / missing data in sample series.
 *
 * Every `n` minutes all samples are available. If using a bucket narrower it may cause some bucket to be empty.
 *
 * For example, fetching samples for signals dCSS and dCSSSetPoint with the bucket PT10S for 5 minutes may yield this result:
 *
 * ```
 * [
 *   { signal: "dCSS", timeStamp: "2022-12-16T12:00:00Z", value: 22.0, ... },
 *   { signal: "dCSS", timeStamp: "2022-12-16T12:02:00Z", value: 22.0, ... },
 *   { signal: "dCSS", timeStamp: "2022-12-16T12:02:20Z", value: 22.2, ... },
 *   { signal: "dCSS", timeStamp: "2022-12-16T12:04:00Z", value: 22.2, ... },
 *   { signal: "dCSSSetPoint", timeStamp: "2022-12-16T12:00:00Z", value: 21.0, ... },
 *   { signal: "dCSSSetPoint", timeStamp: "2022-12-16T12:02:00Z", value: 21.0, ... },
 *   { signal: "dCSSSetPoint", timeStamp: "2022-12-16T12:04:00Z", value: 21.0, ... },
 * ]
 * ```
 *
 * In this case there is no sample value for the dCSSSetPoint for the time stamp 12:02:20.
 *
 * When identifying a timestamp for a signal which is missing a value it can be assumed the value has not been changed since the previous value. In the example above the previous value (21.0) is available at 12:02:00. This value can be used for the time stamp 12:02:20.
 */
export const addMissingData: SampleTransformer = (samples) => {
  const allTimeStamps = uniqBy(samples, 'timeStamp').map(
    (sample) => new Date(sample.timeStamp)
  );

  return getSignals(samples).reduce(
    (result, signal) => {
      const signalSamples = getSamplesForSignal(signal, samples).map(
        (sample) => ({ ...sample, timeStampAsDate: new Date(sample.timeStamp) })
      );

      const signalHasTimeStamp = (timeStamp: Date) =>
        signalSamples.find(
          (sample) => sample.timeStampAsDate.getTime() === timeStamp.getTime()
        );

      allTimeStamps
        .filter((timeStamp) => !signalHasTimeStamp(timeStamp))
        .forEach((timeStamp) => {
          const previous = getPreviousSample(timeStamp, signalSamples);
          if (previous) {
            result.push({
              ...previous,
              timeStamp: timeStamp.toISOString(),
              timeStampAsDate: timeStamp,
            });
          }
        });

      return result;
    },
    [...samples]
  );
};
