import Paper from '@mui/material/Paper';
import type { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import type { CSSProperties, ReactNode } from 'react';
import { toSxArray } from '@/theme/util';

interface PaneProps {
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
  dense?: boolean;
  sx?: SxProps<Theme>;
}

const Pane = ({ children, className, dense, style, sx }: PaneProps) => (
  <Paper
    style={style}
    className={className}
    elevation={0}
    sx={[
      {
        height: '100%',
        minHeight: '100%',
        overflow: 'auto',
        p: {
          xs: 1,
          sm: 2,
        },
        borderRadius: 0,
      },
      dense === true && { p: { xs: 0, sm: 0 } },
      ...toSxArray(sx),
    ]}
    children={children}
  />
);

export default Pane;
