import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { returnUrlParam } from '../feature/returnUrl/Provider';

type ParamValue = string | number | boolean | undefined | (string | number)[];

function getParamValue(value: ParamValue) {
  if (!value) {
    return '';
  }
  return Array.isArray(value) ? value.join(',') : value.toString();
}

const useUrlBuilder = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const buildUrl = useCallback(
    (
      pathname: string,
      params?: string[][] | Record<string, string> | string | URLSearchParams
    ) => {
      const _pathname = pathname;
      const _params = new URLSearchParams(params);

      const setParam = (name: string, value: ParamValue) => {
        if (value) {
          _params.set(name, getParamValue(value));
        } else {
          _params.delete(name);
        }
        return api;
      };

      const setReturnUrl = () => {
        return setParam(returnUrlParam, location.pathname + location.search);
      };

      const deleteParam = (name: string) => {
        _params.delete(name);
        return api;
      };

      const read = () =>
        _params.keys().next().done
          ? _pathname
          : `${_pathname}?${_params.toString()}`;

      const replace = <T extends object>(state?: T) => {
        navigate(read(), { replace: true, state });
      };

      const push = <T extends object>(state?: T) => {
        navigate(read(), { state });
      };

      const api = {
        setParam,
        setReturnUrl,
        deleteParam,
        read,
        replace,
        push,
      };

      return {
        setParam,
        setReturnUrl,
        deleteParam,
        read,
      };
    },
    [navigate, location.pathname, location.search]
  );

  const editUrl = useCallback(
    () => buildUrl(location.pathname, location.search),
    [buildUrl, location.pathname, location.search]
  );

  return useMemo(
    () => ({
      buildUrl,
      editUrl,
    }),
    [buildUrl, editUrl]
  );
};

export default useUrlBuilder;
