import { notEmpty } from '@/util/array';
import type { FormatDateOptions, FormatTimeOptions } from '@/util/date';
import { formatDate, formatTime } from '@/util/date';

interface FormatDateProps {
  date: Date | string | undefined;
  showDate?: boolean;
  showTime?: boolean;
  dateOptions?: FormatDateOptions;
  timeOptions?: FormatTimeOptions;
}

const FormatDate = ({
  date,
  showDate = true,
  showTime = true,
  dateOptions,
  timeOptions,
}: FormatDateProps) => {
  if (date === undefined) {
    return null;
  }
  if (!showDate && !showTime) {
    return null;
  }

  const formattedDate = showDate ? formatDate(date, dateOptions) : null;
  const formattedTime = showTime ? formatTime(date, timeOptions) : null;

  return (
    <>{[formattedDate, formattedTime].filter(notEmpty).join(' ').trim()}</>
  );
};

export default FormatDate;
