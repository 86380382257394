export const ONE_MINUTE = 60 * 1000;

export const TEN_MINUTES = ONE_MINUTE * 10;

export const ONE_HOUR = 60 * 60 * 1000;

export const TWO_HOURS = ONE_HOUR * 2;

export function getUtcOffset(date: Date = new Date()) {
  return -(date.getTimezoneOffset() / 60);
}

export function getUtcOffsetWithSign(date: Date = new Date()) {
  const offset = getUtcOffset(date);
  if (offset >= 0) {
    return `+${offset}`;
  } else {
    return `${offset}`;
  }
}
