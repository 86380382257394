import type { Size } from './types';

const BLURHASH_IMAGE_TYPE = 'blurhash';
const PROFILE_IMAGE_TYPE = 'i192x256';

export function getAvatarImgInfo(profileImage?: ImagesDto) {
  const profileImageBlob = profileImage?.blobs[PROFILE_IMAGE_TYPE];
  const blurhashBlob = profileImage?.blobs[BLURHASH_IMAGE_TYPE];

  return profileImageBlob?.url
    ? {
        blurhash: blurhashBlob?.url,
        src: profileImageBlob.url,
      }
    : undefined;
}

export function getSize(size?: Size) {
  switch (size) {
    case 'xs':
      return '1.5rem';
    case 'sm':
      return '2rem';
    case 'lg':
      return '4rem';
    case 'xl':
      return '8rem';
    case 'xxl':
      return '10rem';
    case 'md':
    default:
      return '3rem';
  }
}
