import { isNonEmptyArray } from '@/util/array';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import type { FetchAlarmsOptions } from './api';
import { fetchAlarm, fetchAlarms } from './api';
import type { AlarmData } from './api.types';
import { useUpdateAlarmCount } from './useAlarmCount';

export type UseAlarmQueryOptions = Omit<FetchAlarmsOptions, 'equipment_ids'>;

const defaultOptions: UseAlarmQueryOptions = {
  ignore_no_permit: true,
};

const otherFilters = [
  'start_time' as const,
  'end_time' as const,
  'severity' as const,
  'alarm_type' as const,
];

export function isFetchingActiveAlarms({
  status,
  ...other
}: UseAlarmQueryOptions) {
  const isActiveAlarmsFilterActive =
    status === 'OPEN' ||
    (Array.isArray(status) && status.length === 1 && status.includes('OPEN'));
  const isOtherFilterActive = otherFilters.some((f) => other[f] !== undefined);

  return isActiveAlarmsFilterActive && !isOtherFilterActive;
}

export function isEnabled(
  equipmentIds: string[],
  queryOptions: UseQueryOptions<AlarmData>
) {
  const { enabled = true } = queryOptions;
  const hasEquipment = isNonEmptyArray(equipmentIds);

  return hasEquipment && enabled;
}

const useAlarmQuery = (
  equipmentIds: string[],
  options: UseAlarmQueryOptions = {},
  queryOptions: UseQueryOptions<AlarmData> = {}
) => {
  const { updateAlarmCountForAlarms } = useUpdateAlarmCount();
  const opts = { ...defaultOptions, ...options, equipment_ids: equipmentIds };
  const enabled = isEnabled(equipmentIds, queryOptions);

  return useQuery({
    queryKey: ['alarms', opts],
    queryFn: () => fetchAlarms(opts),
    ...queryOptions,
    enabled,
    onSuccess: (data: AlarmData) => {
      if (isFetchingActiveAlarms(opts)) {
        updateAlarmCountForAlarms(data.alarms);
      }
    },
  });
};

export default useAlarmQuery;

export const useAlarmByIdQuery = (id: string) =>
  useQuery({
    queryKey: ['alarm', id],
    queryFn: () => fetchAlarm(id),
  });
