import { Row, SortingRule } from 'react-table';
import { ExtendedColumn } from './DataProvider';

export function getSortBy(
  columns: ExtendedColumn[]
): SortingRule<string>[] | undefined {
  const sortByColumn = columns.filter((column) => column.initialSort);

  return sortByColumn.map((col) => {
    const id =
      col.id ??
      (typeof col.accessor === 'string' ? (col.accessor as string) : null);

    if (!id) {
      throw new Error('Specify id / accessor on column to sort by initially');
    }

    return { id, desc: col.initialSort?.direction === 'desc' };
  });
}

export function numericSort(rowA: Row<any>, rowB: Row<any>, id: string) {
  const parsedA = Number.parseFloat(rowA.values[id]);
  const parsedB = Number.parseFloat(rowB.values[id]);
  const valueForNaN = Number.NEGATIVE_INFINITY;
  const a = Number.isNaN(parsedA) ? valueForNaN : parsedA;
  const b = Number.isNaN(parsedB) ? valueForNaN : parsedB;

  return a > b ? 1 : a < b ? -1 : 0;
}
