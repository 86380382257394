import type { InterpolationValues, Translation } from '@/feature/lang/types';
import { Icon } from '@/icon/Icon';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { merge } from 'lodash-es';
import type { ReactNode } from 'react';
import Translate from '../Translate';
import { useConfig } from './useConfig';

const Container = styled('div', { name: 'Empty', slot: 'Container' })({
  display: 'flex',
  flexFlow: 'column nowrap',
  height: '100%',
});
const SpaceBefore = styled('div', { name: 'Empty', slot: 'SpaceAbove' })({
  flex: 3,
});
const SpaceAfter = styled('div', { name: 'Empty', slot: 'SpaceAfter' })({
  flex: 4,
});
const Content = styled('div', { name: 'Empty', slot: 'Content' })(
  ({ theme }) => ({
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 0,
    gap: theme.spacing(1),
    margin: theme.spacing(3, 4),
  })
);
const Text = styled(Typography, { name: 'Empty', slot: 'Text' })(
  ({ theme }) => ({
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    textAlign: 'center',
  })
);
const Actions = styled('div', { name: 'Empty', slot: 'Actions' })(
  ({ theme }) => ({
    margin: theme.spacing(2),
  })
);

export type EmptyType =
  | 'success'
  | 'warning'
  | 'error'
  | 'info'
  | 'invalid_selection'
  | 'no_content'
  | 'no_data_list'
  | 'no_data_chart';

export interface EmptyProps {
  type?: EmptyType;
  text?: Translation;
  actions?: ReactNode;
  textValues?: InterpolationValues;
}

const Empty = ({
  type = 'no_content',
  text,
  textValues,
  actions,
}: EmptyProps) => {
  const config = useConfig(type);
  const info = merge({}, config, { text, actions });

  if (!config) {
    return null;
  }

  return (
    <Container>
      <SpaceBefore />
      <Content>
        <Icon name={config.icon} size="xxlarge" color="action.active" />
        {info.text && (
          <Text variant="body2">
            <Translate text={info.text} values={textValues} />
          </Text>
        )}
        {info.actions && <Actions>{info.actions}</Actions>}
      </Content>
      <SpaceAfter />
    </Container>
  );
};

export default Empty;
