import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import type { ReactNode } from 'react';

interface RootProps {
  middle?: boolean;
}

const Root = styled(Box, {
  name: 'Cell',
  slot: 'Root',
  shouldForwardProp: (propName) => propName !== 'middle',
})<RootProps>(({ theme, middle = false }) => ({
  height: '100%',
  minWidth: 0,
  gridColumnEnd: 'span 1',
  gridRowEnd: 'span 1',
  ...(middle && {
    display: 'inline-flex',
    flexFlow: 'column wrap',
    justifyContent: 'center',
    justifySelf: 'stretch',
  }),
}));

interface CellProps {
  children: ReactNode;
  width?: number;
  height?: number;
  top?: number | string;
  left?: number | string;
  middle?: boolean;
  center?: boolean;
  area?: string;
}

export default ({
  width,
  height,
  left,
  top,
  center,
  area,
  middle,
  children,
}: CellProps) => (
  <Root
    middle={middle}
    sx={{
      textAlign: center ? 'center' : 'inherit',

      ...(width && { gridColumnEnd: `span ${width}` }),
      ...(height && { gridRowEnd: `span ${height}` }),

      ...(left && { gridColumnStart: left }),
      ...(top && { gridRowStart: top }),

      ...(area && { gridArea: area }),
    }}
    children={children}
  />
);
