import { sortBy, uniqBy } from 'lodash-es';
import type { TransformSampleData } from './index';

export function getSignals(samples: TransformSampleData[]) {
  return uniqBy(samples, 'signalName').map((sample) => sample.signalName);
}

export function getSamplesForSignal(
  signal: string,
  samples: TransformSampleData[]
) {
  return samples.filter((sample) => sample.signalName === signal);
}

export function getSampleInterval(samples: TransformSampleData[]) {
  const sortedSamples = sortBy(samples, 'timeStamp');
  const start = sortedSamples.at(0)?.timeStamp;
  const end = sortedSamples.at(-1)?.timeStamp;

  return {
    start: start ? new Date(start) : undefined,
    end: end ? new Date(end) : undefined,
  };
}

interface Closest {
  sample: TransformSampleData | undefined;
  diff: number;
}

export function getPreviousSample(
  forTimeStamp: Date,
  samples: TransformSampleData[]
) {
  return samples.reduce<Closest>(
    (closest, sample) => {
      const diff = forTimeStamp.getTime() - sample.timeStampAsDate.getTime();
      if (diff > 0 && diff < closest.diff) {
        return { sample, diff };
      }
      return closest;
    },
    { sample: undefined, diff: Infinity }
  ).sample;
}
