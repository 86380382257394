import useIsSmallScreen from '@/util/useIsSmallScreen';
import {
  ClickAwayListener,
  Popper as MuiPopper,
  PopperPlacementType,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import type { MouseEvent, ReactElement, ReactNode } from 'react';
import { useState } from 'react';

const StyledMuiPopper = styled(MuiPopper, { name: 'StyledPopper' })(
  ({ theme }) => ({
    zIndex: theme.zIndex.modal,
  })
);

function getTriggerProps({
  disabled,
  badgeContent,
  onClick,
}: PopperTriggerProps) {
  const props: PopperTriggerProps = { onClick };
  if (typeof disabled === 'boolean') {
    props.disabled = disabled;
  }
  if (badgeContent !== undefined) {
    props.badgeContent = badgeContent;
  }

  return props;
}

interface PopperProps<
  C extends PopperContentProps,
  T extends PopperTriggerProps
> {
  badgeContent?: ReactNode;
  placement?: PopperPlacementType;
  Content: (props: C) => ReactElement;
  Trigger: (props: T) => ReactElement;
  disabled?: boolean;
  closeOnClickAway?: boolean;
  onClose?: () => void;
  strategy?: 'fixed' | 'absolute';
  contentProps?: Omit<C, keyof PopperContentProps>;
  triggerProps?: Omit<T, keyof PopperTriggerProps>;
}

const Popper = <C extends PopperContentProps, T extends PopperTriggerProps>({
  badgeContent,
  placement = 'bottom-start',
  Content,
  Trigger,
  disabled,
  closeOnClickAway = true,
  onClose,
  strategy = 'fixed',
  contentProps,
  triggerProps,
}: PopperProps<C, T>) => {
  const theme = useTheme();
  const small = useIsSmallScreen();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isOpen = Boolean(anchorEl);

  const open = (currentTarget: HTMLButtonElement) => setAnchorEl(currentTarget);
  const close = () => {
    if (onClose) {
      onClose();
    }
    setAnchorEl(null);
  };

  const handleClickAway = () => {
    if (closeOnClickAway && isOpen) {
      close();
    }
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (disabled) {
      return;
    }
    if (isOpen) {
      close();
    } else {
      open(event.currentTarget);
    }
  };

  const popperTriggerProps = getTriggerProps({
    disabled,
    badgeContent,
    onClick: handleClick,
  });

  return (
    <>
      {/* @ts-ignore */}
      <Trigger {...popperTriggerProps} {...triggerProps} />
      {isOpen && (
        // disableReactTree used to dismiss popper content when it contains <Slider>
        <ClickAwayListener onClickAway={handleClickAway} disableReactTree>
          <StyledMuiPopper
            open={isOpen}
            anchorEl={anchorEl}
            placement={placement}
            popperOptions={{
              strategy,
            }}
            modifiers={[
              {
                name: 'flip',
                enabled: true,
                options: {
                  altBoundary: true,
                  boundary: 'viewport',
                  padding: 8,
                },
              },
              {
                name: 'preventOverflow',
                enabled: true,
                options: {
                  altAxis: true,
                  boundary: 'viewport',
                  tether: false,
                  padding: small ? 0 : 8,
                },
              },
              {
                name: 'offset',
                enabled: true,
                options: {
                  offset: [0, theme.spacing(1).replace(/px/, '')],
                },
              },
            ]}
          >
            {/* @ts-ignore */}
            <Content onClose={close} {...contentProps} />
          </StyledMuiPopper>
        </ClickAwayListener>
      )}
    </>
  );
};

export default Popper;
