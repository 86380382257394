import { get, post, put, remove } from '@/api/v1';

const basePath = 'ecom/recommendation';

export const fetchRecommendations = () => get<Recommendation[]>(basePath);

export const fetchRecommendationById = (recommendationId: string) =>
  get<Recommendation>(`${basePath}/${recommendationId}`);

export const createRecommendation = (recommendation: Partial<Recommendation>) =>
  post<Recommendation>(basePath, recommendation);

export const updateRecommendation = (recommendation: Partial<Recommendation>) =>
  put<Recommendation>(`${basePath}/${recommendation.id}`, recommendation);

export const updateRecommendationStatus = ({
  recommendationId,
  status,
}: {
  recommendationId: string;
  status: RecommendationState;
}) => put<Recommendation>(`${basePath}/${recommendationId}/status/${status}`);

export const updateRecommendationItemsStatus = ({
  recommendationId,
  status,
  positions,
}: {
  recommendationId: string;
  status: RecommendationItemStatus;
  positions: number[];
}) =>
  Promise.all(
    positions.map((position) =>
      put(
        `${basePath}/${recommendationId}/item/${position}/status/${status}`,
        undefined,
        { responseHandler: () => () => undefined }
      )
    )
  );

export const deleteRecommendation = (recommendationId: string) =>
  remove(`${basePath}/${recommendationId}`);
