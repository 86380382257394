import { useQueryParam } from '@/util/useQuery';
import type { AsideApi, AsideKind } from './types';

export const asideParamName = 'aside';

export const useAside = (): AsideApi => {
  const [aside, setAside] = useQueryParam(asideParamName);
  const selected = aside === '' ? [] : aside.split(':');

  return {
    isOpen: aside !== '',
    open: (kind: string, id: string) => {
      setAside([kind, id].join(':'));
    },
    close: () => {
      setAside('');
    },
    selected: {
      kind: selected.at(0) as AsideKind | undefined,
      id: selected.at(1),
    },
  };
};
