import { get } from '@/api/apiClient';
import { toQueryParams } from '@/api/util';

export const fetchPrices = (ids: number[], deliveryPointId: string) => {
  const params = toQueryParams({
    item_ids: ids,
    delivery_point_id: deliveryPointId,
  });
  return get<PriceAndAvailability[]>(
    `ecom/catalog/article/priceandavailability${params}`
  );
};
