import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { NotFound } from '../../app/routing/NotFound';
import { partsFullPath } from '../../pathDefinitions';

const Parts = lazy(() => import('./parts/Parts'));
const Catalog = lazy(() => import('./catalog/Catalog'));
const Search = lazy(() => import('./search/Search'));

export const catalogPath = 'catalog';
export const catalogRoute = (catalogId: number) =>
  `${partsFullPath}/${catalogPath}/${catalogId}`;
export const catalogIdParam = 'catalogId';
export const searchPath = 'search';

export default () => (
  <Routes>
    <Route path="/" element={<Parts />} />
    <Route path={`${catalogPath}/:${catalogIdParam}`} element={<Catalog />} />
    <Route path={searchPath} element={<Search />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
);
