import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { NotFound } from '../../app/routing/NotFound';
import {
  companiesFullPath,
  equipmentPath,
  sitesPath,
  usersPath,
} from '../../pathDefinitions';
import { equipmentIdParam } from '../equipment/routes';
import { siteIdParam } from '../site/routes';
import { userIdParam } from '../user/routes';

const ListCompaniesRoute = lazy(() => import('./list/ListCompaniesRoute'));
const CompanyDetailsRoute = lazy(() => import('./details/CompanyDetailsRoute'));
const CompanySiteDetails = lazy(() => import('./details/CompanySiteDetails'));
const CompanyUserDetails = lazy(() => import('./details/CompanyUserDetails'));
const CompanyEquipmentDetails = lazy(
  () => import('./details/CompanyEquipmentDetails')
);

export const companyUserRoute = (companyId: string, userId: string) =>
  `${companiesFullPath}/${companyId}/${usersPath}/${userId}`;

export const companySiteRoute = (companyId: string, siteId: string) =>
  `${companiesFullPath}/${companyId}/${sitesPath}/${siteId}`;

export const companyEquipmentRoute = (companyId: string, equipmentId: string) =>
  `${companiesFullPath}/${companyId}/${equipmentPath}/${equipmentId}`;

export const companyIdParam = 'companyId';

export default () => (
  <Routes>
    <Route path="/" element={<ListCompaniesRoute />} />
    <Route path={`:${companyIdParam}`} element={<CompanyDetailsRoute />} />
    <Route
      path={`:${companyIdParam}/${sitesPath}/:${siteIdParam}`}
      element={<CompanySiteDetails />}
    />
    <Route
      path={`:${companyIdParam}/${usersPath}/:${userIdParam}`}
      element={<CompanyUserDetails />}
    />
    <Route
      path={`:${companyIdParam}/${equipmentPath}/:${equipmentIdParam}`}
      element={<CompanyEquipmentDetails />}
    />
    <Route path="*" element={<NotFound />} />
  </Routes>
);
