import { useState } from 'react';

type Deserialize<T> = (data: any) => T;

interface UseStorageOptions<T> {
  deserialize?: Deserialize<T>;
}

export function useLocalStorage<T>(
  key: string,
  initialValue: T,
  options: UseStorageOptions<T> = {}
): [T, (val: T) => void] {
  return useStorage(key, initialValue, options, window.localStorage);
}

export function useSessionStorage<T>(
  key: string,
  initialValue: T,
  options: UseStorageOptions<T> = {}
): [T, (val: T) => void] {
  return useStorage(key, initialValue, options, window.sessionStorage);
}

// https://usehooks.com/useLocalStorage/
function useStorage<T>(
  key: string,
  initialValue: T,
  options: UseStorageOptions<T>,
  storage: any
): [T, (val: T) => void] {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      // Get from local storage by key
      const item = storage.getItem(key);
      // Parse stored json or if none return initialValue
      if (!item) {
        return initialValue;
      }
      const json = JSON.parse(item);
      return typeof options.deserialize === 'function'
        ? options.deserialize(json)
        : json;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      if (valueToStore === undefined) {
        storage.removeItem(key);
      } else {
        storage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue];
}
