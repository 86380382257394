import useTranslation from '@/feature/lang/hook';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { get } from 'lodash-es';
import { useFormContext } from 'react-hook-form';
import Label from './Label';
import type { InputProps } from './types';
import { useValidation } from './useValidation';

function getErrorForField(fieldName: string, errors: any) {
  if (!fieldName) {
    return;
  }

  return get(errors, fieldName);
}

function Input({
  registerControl,
  validation: validationBase,
  margin = 'normal',
  variant = 'filled',
  label,
  placeholder,
  fullWidth = true,
  loading,
  ...rest
}: InputProps) {
  const { translate } = useTranslation();
  const validation = useValidation(validationBase, { label });
  const hasLabel = Boolean(label);

  const props = {
    fullWidth,
    margin,
    variant,
    label: hasLabel ? (
      <Label label={label} required={Boolean(validation?.required)} />
    ) : undefined,
    placeholder: translate(placeholder),
    hiddenLabel: !hasLabel,
    InputProps: {},
    ...rest,
  };
  if (variant === 'filled') {
    // @ts-ignore
    props.InputProps.disableUnderline = true;
  }

  if (loading) {
    // @ts-ignore
    props.InputProps.endAdornment = (
      <InputAdornment position="end">
        <CircularProgress size={20} />
      </InputAdornment>
    );
    props.disabled = true;
  }

  return registerControl ? (
    <RegisteredControl {...props} validation={validation} />
  ) : (
    <BasicInput {...props} />
  );
}

export default Input;

function BasicInput({ ...props }: any) {
  return <TextField {...props} />;
}

function RegisteredControl({ name, validation, ...props }: any) {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const error = getErrorForField(name, errors);
  const { ref, ...inputProps } = register(name, validation);

  return (
    <TextField
      {...props}
      error={Boolean(error)}
      helperText={error && (error.message || 'validation error')}
      inputRef={ref}
      inputProps={{ ...props.inputProps, ...inputProps }}
    />
  );
}
