import { useQuery, useQueryClient } from '@tanstack/react-query';
import { groupBy } from 'lodash-es';
import type { AlarmDto } from './api.types';

interface AlarmCount {
  count: number;
  equipmentId: string;
}

type AlarmCache = Record<string, number>;

const useUpdate = () => {
  const queryClient = useQueryClient();

  return (newCounts: AlarmCount[]) => {
    queryClient.setQueryData<AlarmCache>(['alarmCount'], (currentCounts) => {
      const result = currentCounts ? { ...currentCounts } : {};
      newCounts.forEach((entry) => {
        result[entry.equipmentId] = entry.count;
      });

      return result;
    });
  };
};

export const useAlarmCount = () => {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: ['alarmCount'],
    queryFn: () => queryClient.getQueryData<AlarmCache>(['alarmCount']) ?? {},
  });
};

export const useUpdateAlarmCount = () => {
  const update = useUpdate();

  return {
    updateAlarmCountForEquipment: (equipmentList: Equipment[]) => {
      const counts = equipmentList
        .filter((eq) => typeof eq.alarmCount === 'number')
        .map((eq) => ({
          equipmentId: eq.id,
          count: eq.alarmCount ?? 0,
        }));

      update(counts);
    },
    updateAlarmCountForAlarms: (alarms: AlarmDto[]) => {
      const groups = groupBy(alarms, 'equipmentId');
      const counts = Object.entries(groups).map(([equipmentId, alarms]) => ({
        equipmentId,
        count: alarms.length,
      }));

      update(counts);
    },
  };
};
