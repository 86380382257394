import { IToast, IToastModel } from './types';

const defaultCloseAfter = 6000;

const toasts = new Map<number, IToastModel>();
const listeners: ((callback: any) => void)[] = [];
let counter = 0;

const subscribe = (callback: any) => {
  listeners.push(callback);

  return () => {
    listeners.splice(listeners.indexOf(callback), 1);
  };
};

const notify = () =>
  listeners.forEach((listener) => listener(Array.from(toasts.values())));

const remove = (id: number) => {
  toasts.delete(id);
  notify();
};

const add = (toast: IToast) => {
  const id = counter++;
  const closeAfter = toast.closeAfter ?? defaultCloseAfter;
  const timeout = Number.isFinite(closeAfter)
    ? setTimeout(() => remove(id), closeAfter)
    : undefined;
  toasts.set(id, {
    ...toast,
    id,
    remove: () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      remove(id);
    },
  });
  notify();
};

export { add, subscribe };
