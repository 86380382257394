import { styled } from '@mui/material/styles';
import Progress from './Progress';

const Fullscreen = styled('div')(({ theme }) => ({
  position: 'fixed',
  width: '100vw',
  height: '100vh',
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: theme.zIndex.modal - 1,
}));

interface FullscreenProgressProps {
  isVisible: boolean;
}

const FullscreenProgress = ({ isVisible }: FullscreenProgressProps) =>
  isVisible ? (
    <Fullscreen>
      <Progress />
    </Fullscreen>
  ) : null;

export default FullscreenProgress;
