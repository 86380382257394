import { createContext } from 'react';
import { Column, Row, TableInstance } from 'react-table';

export interface DataProviderContext {
  clearSelectedRows: () => void;
  data: any[];
  initialData?: any[];
  footer: boolean;
  grouping?: boolean;
  highlightRowId?: (item: any) => boolean;
  rowClickable?: (row: Row) => boolean;
  groupBy: (columnId: string) => void;
  onRowClick?: (row: Row) => void;
  onSelectRow: (row: Row, selected: boolean) => void;
  onSelectAllRows: (selected: boolean) => void;
  selectedRows: string[];
  setColumnOrder: (ids: string[]) => void;
  setData: (data: any[]) => void;
  tableProps: TableInstance;
  setColumns: (columns: Column[]) => void;
  status: Status;
  id?: string;
}

export default createContext<DataProviderContext>({
  clearSelectedRows: () => {},
  data: [],
  selectedRows: [],
  setColumnOrder: () => {},
  setData: () => {},
  // @ts-ignore
  tableProps: {},
});
