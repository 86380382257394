import { useUnitLabel } from './useUnitLabel';

interface UnitProps {
  unit: string | undefined;
}

export const Unit = ({ unit }: UnitProps) => {
  const label = useUnitLabel();

  return <>{label(unit)}</>;
};
