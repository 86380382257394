import { ONE_HOUR, ONE_MINUTE, TEN_MINUTES } from '@/util/time';
import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useAccountSelector } from '../account/AccountProvider';
import { useQueryV5 } from '../query/util';
import { fetchCurrentUser, fetchRoles, fetchUser, fetchUsers } from './api';

const defaultInclude: UserInclude = ['image'];

const defaultByIdParams: FetchUserParams = {
  include: [...defaultInclude, 'roles'],
};

const defaultListParams: ListParams<UserInclude> = {
  include: defaultInclude,
  size: 1000,
};

function toByIdParams(listParams: ListParams<UserInclude>) {
  return {
    include: listParams.include,
  };
}

/**
 * Gets data about current user. This is prefetched during startup of the app
 * and is guaranteed to be loaded, hence no need for loaders on individual
 * views.
 *
 * Note: Changes to the context user does not automatically update components
 * where useCurrentUser() is used. Favor useCurrentUserQuery() if this is
 * desired.
 */
export const useCurrentUser = () => {
  const queryClient = useQueryClient();
  const currentUser = queryClient.getQueryData<UserDto>(['currentUser']);
  if (!currentUser) {
    throw new Error('Found no data for current user');
  }
  return currentUser;
};

export const useCurrentUserQuery = (queryOptions?: UseQueryOptions<UserDto>) =>
  useQuery({
    queryKey: ['currentUser'],
    queryFn: () => fetchCurrentUser(),
    staleTime: TEN_MINUTES,
    cacheTime: ONE_HOUR,
    ...queryOptions,
  });

export const useUserQuery = (id?: string) =>
  useQuery({
    queryKey: ['user', id, defaultByIdParams],
    queryFn: () => fetchUser(id, defaultByIdParams),
    staleTime: ONE_MINUTE,
    enabled: !!id,
  });

export const useUsersQuery = (
  criteria?: UserCriteria,
  params: ListParams<UserInclude> = {},
  queryOptions: UseQueryOptions<UserDto[]> = {}
) => {
  const populate = useCachePopulator();
  const { account } = useAccountSelector();

  const combinedCriteria = { account: account?.id, ...criteria };
  const combinedParams = { ...defaultListParams, ...params };

  return useQueryV5({
    queryKey: ['users', combinedCriteria, combinedParams],
    queryFn: () => fetchUsers(combinedCriteria, combinedParams),
    staleTime: ONE_MINUTE,
    onSuccess: (users) => populate(users, combinedParams),
    ...queryOptions,
  });
};

const useCachePopulator = () => {
  const queryClient = useQueryClient();
  return (users: UserDto[], params: ListParams<UserInclude>) => {
    const byIdParams = toByIdParams(params);
    users.forEach((user) => {
      queryClient.setQueryData(['user', user.id, byIdParams], user, {
        updatedAt: Date.now() + ONE_MINUTE,
      });
    });
  };
};

export const useRolesQuery = (realmId?: string) =>
  useQuery(['roles', realmId], () => fetchRoles(realmId as string), {
    staleTime: TEN_MINUTES,
    enabled: !!realmId,
  });
