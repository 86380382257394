import { forwardRef } from 'react';
import { MaterialSymbolIcon } from './MaterialSymbol/MaterialSymbolIcon';
import type { IconProps } from './types';

export const Icon = forwardRef<any, IconProps>(
  ({ name, size = 'medium', fill = false, ...rest }, ref) => (
    <MaterialSymbolIcon
      name={name}
      size={size}
      fill={fill}
      ref={ref}
      {...rest}
    />
  )
);
