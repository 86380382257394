import { styled } from '@mui/material/styles';
import { useSelection } from '../../selection/SelectionContext';
import { UrlParamSelectionProvider } from '../../selection/UrlParamSelectionProvider';
import { Aside } from './aside/Aside';
import { LayoutModeProps } from './Layout';
import { useLayout } from './LayoutContext';

const Container = styled('div', {
  name: 'DesktopLayout',
})({
  display: 'flex',
  flexFlow: 'row no-wrap',
  height: '100%',
});

const Main = styled('main', {
  name: 'DesktopMain',
})(({ theme }) => ({
  flex: 1,
  overflow: 'auto',
  borderLeft: `1px solid ${theme.palette.divider}`,
}));

const AsideContainer = styled('aside', {
  name: 'DesktopAside',
})(({ theme }) => ({
  width: 'clamp(500px, 40%, 1000px)',
  borderLeft: `1px solid ${theme.palette.divider}`,
}));

export const MultiPanelLayout = ({ list, main }: LayoutModeProps) => {
  return (
    <UrlParamSelectionProvider>
      <Panes main={main} list={list} />
    </UrlParamSelectionProvider>
  );
};

const Panes = ({ list, main }: LayoutModeProps) => {
  const isSelected = useSelection().selected.length > 0;
  const { aside } = useLayout();

  return (
    <Container>
      {list}
      {isSelected && <Main>{main}</Main>}
      {aside.isOpen && (
        <AsideContainer>
          <Aside />
        </AsideContainer>
      )}
    </Container>
  );
};
