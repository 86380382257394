import { ONE_HOUR } from '@/util/time';
import { useBusinessEntityQuery } from '../businessEntity/queries';
import { useDeliveryPoint } from './useDeliveryPoints';

const useBusinessRelations = () => {
  const { data = [], ...rest } = useBusinessEntityQuery<BusinessRelationDto[]>(
    {
      type: 'mdg_business_relation',
    },
    {
      include: ['parents'],
    },
    {
      cacheTime: ONE_HOUR,
    }
  );

  return { businessRelations: data, ...rest };
};

export default useBusinessRelations;

interface UseBusinessRelationOptions {
  deliveryPointId?: string;
}
export const useBusinessRelation = ({
  deliveryPointId,
}: UseBusinessRelationOptions) => {
  const { businessRelations } = useBusinessRelations();
  const deliveryPoint = useDeliveryPoint(deliveryPointId);

  const [businessRelationId] = deliveryPoint?.parentIds ?? [];

  return businessRelations.find(
    (businessRelation) => businessRelation.id === businessRelationId
  );
};
