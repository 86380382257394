import { styled } from '@mui/material/styles';

interface ContentContainerProps {
  visible: boolean;
}

export const ContentContainer = styled('div', {
  name: 'ContentContainer',
  shouldForwardProp: (propName) => propName !== 'visible',
})<ContentContainerProps>(({ visible }) => ({
  display: visible ? 'block' : 'none',
}));
