import { styled } from '@mui/material/styles';
import { Skeleton, SkeletonProps } from '../Skeleton';

interface AccordionSkeletonProps extends SkeletonProps {
  height: number | string;
}

const AccordionSkeleton = styled((props: AccordionSkeletonProps) => (
  <Skeleton variant="rectangular" {...props} />
))(({ theme }) => ({
  width: '100%',
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2),
}));

export default AccordionSkeleton;
