import {
  getByVersion,
  getPaginatedByVersion,
  postByVersion,
  putByVersion,
  removeByVersion,
} from './wrappers';

const version = 2;

export const get = getByVersion(version);
export const getPaginated = getPaginatedByVersion(version);
export const put = putByVersion(version);
export const post = postByVersion(version);
export const remove = removeByVersion(version);
