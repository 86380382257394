import { useTranslateEnumValues, useTranslation } from '../lang';

const useAlarmPropTexts = () => {
  const { texts } = useTranslation();
  const statusValues = texts.fleet.alarms.model.status.values;
  const getStatusName = useTranslateEnumValues(statusValues);

  return { getStatusName };
};

export default useAlarmPropTexts;
