import { useContext, useMemo } from 'react';
import context from '../../pages/detailsContext';

export const useAlarmEquipment = () => {
  const { selectedEquipment, isLoading } = useContext(context);
  const alarmEquipment = useMemo(
    () => selectedEquipment.filter((e) => e.connected),
    [selectedEquipment]
  );
  const selectedEquipmentIds = useMemo(
    () => alarmEquipment.map((e) => e.id),
    [alarmEquipment]
  );

  return { selectedEquipment: alarmEquipment, selectedEquipmentIds, isLoading };
};
