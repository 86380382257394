import { attachToDebug } from './debug';

// Environment variables are inserted at run time. This is favoured over
// including them during build time as the built docker image - including the
// build artifact - is identical regardless of the environment where it is used.
// This means that the image is identical and can freely be moved between
// environments without the needs of additional builds.

// The variables are sourced in index.html from /env.js and are stored on the
// window object. Docker containers generate /env.js during startup based on
// available environment variables. Local dev envs uses /public/env.js
// (which is source controlled).

interface Env {
  NAME: string;
  KEYCLOAK_URL: string;
  KEYCLOAK_REALM: string;
  APPINSIGHTS_CS: string;
  GOOGLEANALYTICS_TAGID: string;
}

// @ts-ignore
const env: Env = window._env;

attachToDebug('env', env);

export default { ...env };

export const isProd = env.NAME === 'prod';

export const isDev = env.NAME === 'dev.local' || env.NAME === 'dev';
