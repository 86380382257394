import { useQuery } from '@tanstack/react-query';
import useAuth from '../auth/useAuth';
import { fetchLocalizationSettings } from './api';

export const localizationSettingsQueryKey = ['localizationSettings'];

export const useLocalizationSettingsQuery = () => {
  const { isAuthenticated } = useAuth();

  return useQuery({
    queryKey: localizationSettingsQueryKey,
    queryFn: () => fetchLocalizationSettings(),
    enabled: isAuthenticated,
  });
};
