import { useTexts } from '@/feature/lang';
import { Icon } from '@/icon/Icon';
import { DiscreetButton } from '../Button/Buttons';
import Translate from '../Translate';

export const RefreshPageButton = () => {
  const texts = useTexts().component.empty.actions;

  return (
    <DiscreetButton
      startIcon={<Icon name="refresh" />}
      onClick={() => {
        window.location.reload();
      }}
    >
      <Translate text={texts.refreshPage} />
    </DiscreetButton>
  );
};

interface TryAgainButtonProps {
  onClick: () => void;
}
export const TryAgainButton = ({ onClick }: TryAgainButtonProps) => {
  const texts = useTexts().component.empty.actions;

  return (
    <DiscreetButton startIcon={<Icon name="refresh" />} onClick={onClick}>
      <Translate text={texts.tryAgain} />
    </DiscreetButton>
  );
};
