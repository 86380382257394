import { reactPlugin } from '@/feature/insights/appInsights';
import type { ReactElement, ReactNode } from 'react';
import AppInsightsErrorBoundary from './AppInsightsErrorBoundary';
import DisplayError from './DisplayError';

interface ErrorBoundaryProps {
  children: ReactNode;
  action?: ReactElement;
}

const ErrorBoundary = ({ action, children }: ErrorBoundaryProps) => {
  return (
    <AppInsightsErrorBoundary
      onError={(error?: any) => (
        <DisplayError action={action} error={error} fullscreen />
      )}
      appInsights={reactPlugin}
    >
      {children}
    </AppInsightsErrorBoundary>
  );
};

export default ErrorBoundary;
