import { lazy } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { NotFound } from '../../app/routing/NotFound';
import { recommendationsFullPath } from '../../pathDefinitions';

const RecommendationDetails = lazy(
  () => import('./detail/RecommendationDetails')
);
const ListRecommendations = lazy(() => import('./list/ListRecommendations'));

export const useNavigateToRecommendation = () => {
  const navigate = useNavigate();

  return (recommendationId: string) =>
    navigate(`${recommendationsFullPath}/${recommendationId}`);
};

export const recommendationIdParam = 'recommendationId';

const RecommendationRoutes = () => (
  <Routes>
    <Route path="/" element={<ListRecommendations />} />
    <Route
      path={`:${recommendationIdParam}`}
      element={<RecommendationDetails />}
    />
    <Route path="*" element={<NotFound />} />
  </Routes>
);

export default RecommendationRoutes;
