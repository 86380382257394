import { createContext } from 'react';

interface ThemeContextProps {
  name: string;
  setTheme: (themeName: string) => void;
}

export default createContext<ThemeContextProps>({
  name: 'auto',
  setTheme: () => {},
});
