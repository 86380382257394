import { styled } from '@mui/material';
import type { ReactNode } from 'react';

interface StyledTableProps {
  backgroundColor?: string;
  children: ReactNode;
}

const SpanWithSx = styled('span')({});

const StyledTable = ({
  backgroundColor = 'unset',
  children,
}: StyledTableProps) => {
  return (
    <SpanWithSx
      sx={{
        '& .MuiTableCell-root.MuiTableCell-head': {
          backgroundColor: backgroundColor,
        },
      }}
    >
      {children}
    </SpanWithSx>
  );
};

export default StyledTable;
