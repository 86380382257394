import { Parameter, ParameterList } from '@/component/ParameterList';
import { ToastType, useToast } from '@/component/Toast';
import { useTexts } from '@/feature/lang';
import { useChangePasswordMutation } from '@/feature/user/mutations';
import { useCurrentUser } from '@/feature/user/queries';
import {
  ConfirmationField,
  PasswordField,
  PasswordForm,
  SubmitChangePasswordButton,
} from '../components/Password';
import { sectionSize } from './layout';

const ChangePassword = () => {
  const { admin, user: userTexts } = useTexts();
  const notify = useToast();
  const { mutateAsync: changePasswordMutation, status: updateStatus } =
    useChangePasswordMutation();

  const user = useCurrentUser();

  const changePassword = async ({ password, confirmation }: any) => {
    try {
      await changePasswordMutation({
        password,
        confirmation,
      });

      notify({
        message: admin.user.model.passwordChanged,
        type: ToastType.Success,
      });
    } catch (error) {
      notify({
        message: admin.user.model.passwordNotChanged,
        type: ToastType.Error,
      });
    }
  };

  return (
    <PasswordForm onChangePassword={changePassword}>
      <ParameterList
        title={userTexts.preferences.personalInfo.password}
        keyColumnWidth={sectionSize.keyColumnWidth}
        fullWidth
      >
        <Parameter label={admin.user.model.password}>
          <PasswordField user={user} />
        </Parameter>
        <Parameter label={admin.user.model.retypePassword}>
          <ConfirmationField />
        </Parameter>
        <Parameter label="" underline={false}>
          <SubmitChangePasswordButton loading={updateStatus === 'loading'} />
        </Parameter>
      </ParameterList>
    </PasswordForm>
  );
};

export default ChangePassword;
