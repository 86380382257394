import { useTheme } from '@mui/material/styles';
import { forwardRef } from 'react';
import type { IconColor, IconName, IconProps, IconSize } from '../types';
import './font';

const names: Record<IconName, string> = {
  account_circle: 'account_circle',
  alarms: 'warning',
  activities: 'build',
  add: 'add',
  add_a_photo: 'add_a_photo',
  add_shopping_cart: 'add_shopping_cart',
  arrow_back: 'arrow_back',
  arrow_downward: 'arrow_downward',
  arrow_drop_down: 'arrow_drop_down',
  arrow_upward: 'arrow_upward',
  assembly: 'workspaces',
  assignment: 'assignment',
  assignment_turned_in: 'assignment_turned_in',
  bar_chart: 'bar_chart',
  book: 'book',
  build: 'build',
  build_circle: 'build_circle',
  calendar: 'calendar_today',
  call: 'call',
  cancel: 'cancel',
  card_membership: 'card_membership',
  card_view: 'grid_view',
  chart: 'ssid_chart',
  chart_min_max: 'align_vertical_center',
  check_box: 'check_box',
  check_box_outline_blank: 'check_box_outline_blank',
  check_circle: 'check_circle',
  chevron_left: 'chevron_left',
  chevron_right: 'chevron_right',
  close: 'close',
  close_fullscreen: 'close_fullscreen',
  companies: 'contacts',
  comment: 'comment',
  connectivity: 'wifi',
  content_copy: 'content_copy',
  data_thresholding: 'data_thresholding',
  delete: 'delete',
  device_hub: 'device_hub',
  done: 'done',
  donut_large: 'donut_large',
  drag_indicator: 'drag_indicator',
  edit: 'edit',
  equipment_basics: 'description',
  equipment_data: 'equalizer',
  error: 'error',
  expand_less: 'expand_less',
  expand_more: 'expand_more',
  filter: 'filter_list',
  folder: 'folder',
  forward_media: 'forward_media',
  grid_view: 'grid_view',
  grouped_bar_chart: 'grouped_bar_chart',
  histogram: 'pie_chart',
  indeterminate_check_box: 'indeterminate_check_box',
  info: 'info',
  insert_drive_file: 'insert_drive_file',
  insights: 'scatter_plot',
  line_chart: 'show_chart',
  list_view: 'view_agenda',
  key: 'key',
  key_off: 'key_off',
  map_view: 'map',
  menu: 'menu',
  menu_book: 'menu_book',
  monetization_on: 'monetization_on',
  more_vert: 'more_vert',
  no_content: 'screen_search_desktop',
  note: 'note',
  notes: 'notes',
  notification_important: 'notification_important',
  open_in_new: 'open_in_new',
  orders: 'assignment_turned_in',
  parts: 'extension',
  pause: 'pause',
  pause_circle: 'pause_circle',
  photo_camera: 'photo_camera',
  picture_as_pdf: 'picture_as_pdf',
  pie_chart: 'pie_chart',
  play_arrow: 'play_arrow',
  playlist_remove: 'playlist_remove',
  power_settings: 'power_settings_new',
  radio_button_unchecked: 'radio_button_unchecked',
  recommendations: 'lightbulb',
  refresh: 'refresh',
  reply: 'reply',
  restart_alt: 'restart_alt',
  sam_account: 'account_box',
  search: 'search',
  settings: 'settings',
  settings_applications: 'settings_applications',
  shopping_cart: 'shopping_cart',
  shopping_lists: 'assignment',
  sites: 'pin_drop',
  sort: 'sort_by_alpha',
  star: 'star',
  stacked_bar_chart: 'stacked_bar_chart',
  success: 'check_circle',
  swap_horiz: 'swap_horiz',
  swap_vert: 'swap_vert',
  table_view: 'list',
  timer: 'timer',
  turn_right: 'turn_right',
  undo: 'undo',
  users: 'people',
  visibility: 'visibility',
  visibility_off: 'visibility_off',
  warning: 'warning',
};

const sizes: Record<IconSize, number> = {
  xsmall: 18,
  small: 20,
  medium: 24,
  large: 32,
  xlarge: 48,
  xxlarge: 64,
};

// Icon reference:
// https://fonts.google.com/icons?icon.platform=web&icon.style=Rounded
export const MaterialSymbolIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ name, fill, size = 'medium', color, cursor, ...rest }, ref) => {
    const iconName = names[name] ?? name;

    return (
      <span
        {...rest}
        className={['material-symbols-rounded', rest.className].join(' ')}
        style={{
          fontVariationSettings: `'FILL' ${fill ? 1 : 0}`,
          fontSize: sizes[size],
          color: useColor(color),
          cursor,
        }}
        ref={ref}
      >
        {iconName}
      </span>
    );
  }
);

const useColor = (color?: IconColor) => {
  const theme = useTheme();
  switch (color) {
    case 'action.active':
      return theme.palette.action.active;
    case 'disabled':
      return theme.palette.action.disabled;
    case 'error':
      return theme.palette.error.main;
    case 'primary':
      return theme.palette.primary.main;
    case 'success':
      return theme.palette.success.main;
    case 'warning':
      return theme.palette.warning.main;
    case 'text.secondary':
      return theme.palette.text.secondary;
    default:
      return '';
  }
};
