import { getBuild } from './build';
import { version } from './version';

const BuildInfo = () => {
  const { id, number, gitCommitHash } = getBuild();

  return (
    <div style={{ height: 0, width: 0, overflow: 'hidden' }}>
      Build: {id} / {number} / {gitCommitHash}
      Version: {version}
    </div>
  );
};

export default BuildInfo;
