import { isNil } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

type ParamValue = string | number | (string | number)[];

export default function useQuery() {
  const location = useLocation();
  const navigate = useNavigate();

  const query = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return Object.fromEntries(params);
  }, [location.search]);

  const updateParam = useCallback(
    (key: string, value: ParamValue, replace: boolean = true) => {
      const params = new URLSearchParams(location.search);

      // Ignore null / undefined value
      if (!isNil(value)) {
        const val = Array.isArray(value) ? value.join(',') : value.toString();
        params.set(key, val);
      }

      const url = `${location.pathname}?${params.toString()}`;
      if (replace) {
        navigate(url, { replace });
      }
      return url;
    },
    [location.search, location.pathname, navigate]
  );

  const deleteParam = useCallback(
    (key: string, replace: boolean = true) => {
      const params = new URLSearchParams(location.search);
      params.delete(key);
      const url = `${location.pathname}?${params.toString()}`;
      if (replace) {
        navigate(url, { replace });
      }
      return url;
    },
    [location.search, location.pathname, navigate]
  );

  return {
    query,
    updateParam,
    deleteParam,
  };
}

const dataTypes = {
  string: {
    defaultValue: '',
    convert: (v: string) => v,
    isEmpty: (v: any) => v === undefined || v === null || v === '',
  },
  number: {
    defaultValue: undefined,
    convert: (v: string) => (v === '' ? undefined : Number(v)),
    isEmpty: (v: any) => v === undefined || v === null,
  },
};

// @ts-ignore
export const useQueryParam = <T = string>(
  name: string,
  dataType: 'string' | 'number' = 'string',
  defaultValue?: T
): [T, (value: ParamValue, replace?: boolean) => void] => {
  const config = dataTypes[dataType];
  const { query, updateParam, deleteParam } = useQuery();
  const value = query.hasOwnProperty(name)
    ? config.convert(query[name])
    : defaultValue ?? config.defaultValue;

  return [
    // @ts-ignore
    value,
    useCallback(
      (value: ParamValue, replace?: boolean) => {
        config.isEmpty(value)
          ? deleteParam(name)
          : updateParam(name, value, replace);
      },
      [config, deleteParam, updateParam, name]
    ),
  ];
};
