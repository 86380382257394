import { isNonEmptyArray, notEmpty } from '@/util/array';
import { ONE_HOUR } from '@/util/time';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useAccountSelector } from '../account/AccountProvider';
import { useUpdateAlarmCount } from '../alarm';
import {
  useBusinessEntityByIdQuery,
  useBusinessEntityQuery,
} from '../businessEntity/queries';
import criteriaBuilder from '../query/criteriaBuilder';
import { useQueryV5 } from '../query/util';
import { useCurrentUser } from '../user/queries';
import {
  EquipmentIncludeV1,
  EquipmentIncludeV2,
  fetchEquipmentByEntityIds,
  fetchEquipmentByIds,
  fetchEquipmentSignalOffset,
  fetchEquipmentTarget,
  fetchEquipmentWarranty,
  fetchExternalEquipment,
  fetchReportingTime,
} from './api';

export {
  useEquipmentByIdQuery,
  useEquipmentByIdsQuery,
  useEquipmentQuery,
  useEquipmentSignalOffsetsQuery,
  useEquipmentReportingTimeQuery,
  useEquipmentWarrantyQuery,
  useEquipmentTargetQuery,
  useExternalEquipmentQuery,
  useMyEquipment,
};

interface EquipmentByIdsQueryProps {
  equipmentIds?: (string | undefined)[];
  include?: EquipmentIncludeV1;
}

function useEquipmentQuery(
  criteria?: Criteria,
  params: ListParams<BusinessEntityInclude> = {},
  queryOptions: Partial<UseQueryOptions<EquipmentEntity[]>> = {}
) {
  const mergedCriteria = useMemo(
    () => criteriaBuilder(criteria).type('equipment').get(),
    [criteria]
  );

  return useBusinessEntityQuery<EquipmentEntity[]>(mergedCriteria, params, {
    ...queryOptions,
    queryKey: ['equipment', criteria, params],
  });
}

function useMyEquipment(
  entityIds?: string[],
  include?: EquipmentIncludeV2,
  enabled = true
) {
  const user = useCurrentUser();
  const { account } = useAccountSelector();
  const { updateAlarmCountForEquipment } = useUpdateAlarmCount();
  const ids = isNonEmptyArray(entityIds)
    ? entityIds
    : account
    ? [account.id]
    : user.roles?.map((role) => role.entityId).filter(notEmpty);

  return useQueryV5({
    queryKey: ['myequipment', ids, include],
    queryFn: () => fetchEquipmentByEntityIds(ids, include),
    enabled: isNonEmptyArray(ids) && enabled,
    staleTime: ONE_HOUR,
    onSuccess: (equipmentList: Equipment[]) => {
      updateAlarmCountForEquipment(equipmentList);
    },
  });
}

function useEquipmentByIdsQuery({
  equipmentIds,
  include,
}: EquipmentByIdsQueryProps) {
  const values = equipmentIds?.filter(notEmpty);

  return useQueryV5({
    queryKey: ['equipmentByIds', values, include],
    queryFn: () => fetchEquipmentByIds(values, include),
    enabled: isNonEmptyArray(values),
    staleTime: ONE_HOUR,
  });
}

function useEquipmentByIdQuery(
  equipmentId?: string,
  include?: BusinessEntityInclude
) {
  return useBusinessEntityByIdQuery<EquipmentEntity>(equipmentId, include, {
    queryKey: ['equipmentId', equipmentId, include],
  });
}

function useEquipmentSignalOffsetsQuery(equipmentId?: string) {
  return useQuery(
    ['signalOffset', equipmentId],
    () => fetchEquipmentSignalOffset(equipmentId),
    { enabled: !!equipmentId }
  );
}

function useEquipmentReportingTimeQuery(equipmentId?: string) {
  return useQuery(
    ['reportingTime', equipmentId],
    () => fetchReportingTime(equipmentId),
    { enabled: !!equipmentId }
  );
}

function useExternalEquipmentQuery(serial?: string) {
  return useQueryV5({
    queryKey: ['externalEquipment', serial],
    queryFn: () => fetchExternalEquipment(serial),
    enabled: !!serial,
  });
}

function useEquipmentWarrantyQuery(equipmentId?: string) {
  return useQuery(
    ['equipmentWarranty', equipmentId],
    () => fetchEquipmentWarranty(equipmentId),
    { enabled: !!equipmentId }
  );
}

function useEquipmentTargetQuery(equipmentId: string, targetName: TargetName) {
  return useQuery({
    queryKey: ['equipmentTarget', equipmentId, targetName],
    queryFn: () => fetchEquipmentTarget(equipmentId, targetName),
  });
}
