import { styled } from '@mui/material/styles';

interface SelectedMainItemContainerProps {
  active: boolean;
}

export const MainItemContainer = styled('div', {
  name: 'Navigation-SelectedMainItemContainer',
  shouldForwardProp: (propName) => propName !== 'active',
})<SelectedMainItemContainerProps>(({ theme, active }) => ({
  borderTop: '1px solid transparent',
  borderBottom: '1px solid transparent',
  '&:hover': {
    borderTopColor: theme.palette.getGrey(20),
    borderBottomColor: theme.palette.getGrey(20),
  },
  ...(active && {
    borderTopColor: theme.palette.divider,
    borderBottomColor: theme.palette.divider,
    backgroundColor: theme.palette.getGrey(20),
  }),
}));
