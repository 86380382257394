import type { ThemeOptions } from '@mui/material';
import { merge } from 'lodash-es';
import type { GreyShade } from '../../muiThemeExtensions';
import { applyDefaultShadows } from '../util';
import { base } from './common';

const lightTheme: ThemeOptions = merge({}, base, {
  components: {
    MuiButton: {
      styleOverrides: {
        outlinedInherit: {
          color: 'rgba(0, 0, 0, 0.87)',
          borderColor: 'rgba(0,0,0,0.23)',
          '&:hover': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
          },
        },
      },
    },
  },
  palette: {
    mode: 'light',
    link: {
      primary: {
        default: '#FF6A00',
        hover: '#FF6A00',
        active: '#FF6A00',
      },
      nav: {
        default: '#484848',
        hover: '#333',
        active: '#333',
      },
    },
    background: {
      default: '#f5f5f5',
      inverted: 'rgba(0,0,0,0.56)',
      modal: '#fff',
      paper: '#fff',
    },
    action: {
      selected: 'rgba(0, 0, 0, 0.05)',
      selectedOpacity: 0.05,
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.77)',
    },
    success: {
      light: '#DBFAC8',
    },
    warning: {
      light: '#FAF4E1',
    },
    error: {
      light: '#FAE1E1',
    },
    info: {
      light: '#E1EAFA',
    },
    getGrey(shade: GreyShade) {
      switch (shade) {
        case 10:
          return 'rgba(0, 0, 0, 0.02)';
        case 20:
          return 'rgba(0, 0, 0, 0.06)';
        case 30:
          return 'rgba(0, 0, 0, 0.09)';
        case 40:
          return 'rgba(0, 0, 0, 0.15)';
      }
    },
    getGreyOpaque(shade: GreyShade) {
      switch (shade) {
        case 10:
          return '#fafafa';
        case 20:
          return '#f0f0f0';
        case 30:
          return '#e8e8e8';
        case 40:
          return '#d9d9d9';
      }
    },
    severity: {
      critical: {
        light: '#FAE1E1',
      },
      high: {
        light: '#FAEAE1',
      },
      medium: {
        light: '#FAEEE1',
      },
      low: {
        light: '#FAF4E1',
      },
      info: {
        light: '#E1EAFA',
      },
      fallback: {
        main: '#003C7D',
        light: '#E0EFFF',
      },
    },
  },
  shadows: applyDefaultShadows([
    '0px 0px 16px 0px rgba(0,0,0,0.1)',
    '0px 0px 24px 0px rgba(0,0,0,0.3)',
    '0px 0px 10px -5px rgba(0,0,0,0.3), 0px 0px 40px -5px rgba(0,0,0,0.5)',
  ]),
});

export default lightTheme;
