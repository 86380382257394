import { isWithinInterval } from 'date-fns';
import type { SampleTransformer } from './index';

/**
 * Include samples inside date range.
 */
export const includeSamplesInDateRange: SampleTransformer = (
  samples,
  settings
) => {
  const { dateRange } = settings.options;
  if (!dateRange) {
    return samples;
  }
  const interval = {
    start: dateRange.from,
    end: dateRange.to,
  };

  return samples.filter((sample) =>
    isWithinInterval(sample.timeStampAsDate, interval)
  );
};
