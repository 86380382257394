interface IDuration {
  years: number;
  months: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

interface PeriodToken {
  quantity: number;
  unit: string;
}

const emptyDuration: IDuration = {
  years: 0,
  months: 0,
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0,
};

function parsePeriodToken(token: string): PeriodToken | undefined {
  const [, quantity, unit] = token.match(/([0-9]+)([A-Z])/i) ?? [];

  if (isNaN(+quantity) || !unit) {
    return;
  }

  return {
    quantity: +quantity,
    unit,
  };
}

function getTokens(periodPart: string): PeriodToken[] {
  const tokens = periodPart?.match(/[0-9]+[A-Z]/gi) ?? [];
  return tokens
    .map(parsePeriodToken)
    .filter((token) => token !== undefined) as PeriodToken[];
}

function from(period: string | undefined): IDuration {
  if (!period) {
    return emptyDuration;
  }
  const duration: IDuration = {
    years: 0,
    months: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };
  const [datePeriod, timePeriod] = period.split('T');

  getTokens(datePeriod).forEach(({ unit, quantity }) => {
    switch (unit) {
      case 'Y':
        duration.years = quantity;
        break;
      case 'M':
        duration.months = quantity;
        break;
      case 'D':
        duration.days = quantity;
        break;
    }
  });
  getTokens(timePeriod).forEach(({ unit, quantity }) => {
    switch (unit) {
      case 'H':
        duration.hours = quantity;
        break;
      case 'M':
        duration.minutes = quantity;
        break;
      case 'S':
        duration.seconds = quantity;
        break;
    }
  });

  return duration;
}

const Duration = {
  from,
};

export default Duration;
