import { Skeleton } from '@/component/Skeleton';
import { notEmpty } from '@/util/array';
import { attachToDebug } from '@/util/debug';
import { noop } from '@/util/fn';
import { useQueryClient } from '@tanstack/react-query';
import { setDefaultOptions } from 'date-fns';
import { merge } from 'lodash-es';
import type { ReactNode } from 'react';
import { createContext, useEffect, useState } from 'react';
import { languages } from './config';
import { interpolateValues } from './interpolation';
import type { AllTexts, InterpolationValues, Translation } from './types';
import { useShowKeys } from './useShowKeys';
import { useStoredLanguage } from './useStoredLanguage';
import { getMessage, isTranslatable } from './util';

const devTextsPromise = import('../../text.js');

interface LangContextProps {
  language: string;
  changeLanguage: (language: string) => void;
  texts: AllTexts;
  translate: (
    text: Translation,
    values?: InterpolationValues,
    count?: number
  ) => string | undefined;
  showKeys: boolean;
  setShowKeys: (showKeys: boolean) => void;
  isReady: boolean;
}

const LangContext = createContext<LangContextProps>({
  language: 'en',
  changeLanguage: noop,
  texts: {} as AllTexts,
  translate: () => undefined,
  showKeys: false,
  setShowKeys: noop,
  isReady: true,
});

interface LangProviderProps {
  defaultLanguage: string;
  children: ReactNode;
}

const createPromise = () => Promise.resolve(undefined);

const LangProvider = ({ children, defaultLanguage }: LangProviderProps) => {
  const { language, updateLanguage, isReady } =
    useStoredLanguage(defaultLanguage);
  const [texts, setTexts] = useState<AllTexts | undefined>();
  const [showKeys, setShowKeys] = useShowKeys();
  const queryClient = useQueryClient();

  attachToDebug('lang', { language, changeLanguage: updateLanguage, texts });

  useEffect(() => {
    const config = languages.find((lang) => lang.id === language);
    const textsPromise = config?.texts() ?? createPromise();

    Promise.all([devTextsPromise, textsPromise])
      .then(([devTranslations, translations]) => {
        const newTexts = createTexts(
          { showKeys },
          devTranslations,
          translations
        );
        setTexts(newTexts);
      })
      .catch((error) => {
        console.error('Failed to load translations', error);
      });
  }, [language, showKeys]);

  useEffect(() => {
    const config = languages.find((lang) => lang.id === language);
    const promise = config?.dateLocale() ?? createPromise();

    promise
      .then((dateLocale) => {
        if (dateLocale?.default) {
          // @ts-ignore
          setDefaultOptions({ locale: dateLocale.default });
        }
      })
      .catch((error) => {
        console.error('Failed to update date-fns options', error);
      });
  }, [language]);

  function translate(
    text: Translation,
    values?: InterpolationValues,
    count?: number
  ) {
    if (!isTranslatable(text)) {
      return text;
    }

    if (showKeys) {
      return text.key || `missing key for ${text.message}`;
    }

    const message = getMessage(text, count);

    return interpolateValues(message, { count, ...values });
  }

  const changeLanguage = (val: string) => {
    updateLanguage(val);
    queryClient.invalidateQueries();
  };

  if (texts === undefined) {
    return (
      <Skeleton
        variant="rectangular"
        sx={{ m: 2, width: '50%', height: 50 }}
        delay={2000}
      />
    );
  }

  return (
    <LangContext.Provider
      value={{
        language,
        texts,
        changeLanguage,
        showKeys,
        setShowKeys,
        translate,
        isReady,
      }}
    >
      {children}
    </LangContext.Provider>
  );
};

export default LangProvider;
export { LangContext };

interface Module {
  default: object;
}

function createTexts(
  { showKeys }: { showKeys: boolean },
  ...translationModules: (Module | undefined)[]
) {
  const translations = translationModules
    .filter(notEmpty)
    .map((m) => m.default);

  const texts = merge({}, ...translations);

  if (showKeys) {
    addPath(texts);
  }

  return texts;
}

function addPath(obj: any, path?: string) {
  if (obj.message) {
    obj.key = path;
  }
  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value === 'object') {
      const newPath = path ? `${path}.${key}` : key;
      addPath(value, newPath);
    }
  });
}
