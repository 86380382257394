declare global {
  interface Window {
    SAM: any;
  }
}
window.SAM = {
  name: 'SAM Debug Assistance',
};

export function attachToDebug(name: string, value: any) {
  window.SAM[name] = value;
}
