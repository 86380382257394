import { includes } from 'lodash-es';

/**
 * Builds criteria which is used in useQuery-methods as input to specify the
 * objects of interest.
 *
 * Example building a criteria for all equipment with an ancestor (e.g.
 * company):
 *
 * ```
 * const criteria = criteriaBuilder()
 *    .type('equipment')
 *    .ancestor('112233')
 *    .get();
 * ```
 */
const criteriaBuilder = (initial?: Criteria) => {
  const criteria: Criteria = { ...initial };

  function id(...ids: string[]) {
    return add('id', ...ids);
  }

  function realm(...realms: string[]) {
    return add('realm', ...realms);
  }

  function type(...types: (EntityType | string)[]) {
    return add('type', ...types);
  }

  function ancestor(...ancestor: (string | number)[]) {
    return add('ancestor', ...ancestor);
  }

  function descendant(...descendant: (string | number)[]) {
    return add('descendant', ...descendant);
  }

  function query(...query: string[]) {
    return add('query', ...query);
  }

  function add(key: string, ...values: (string | number)[]) {
    if (!criteria[key]) {
      criteria[key] = [];
    }
    values.forEach((value) => {
      if (!includes(criteria[key], value)) {
        // @ts-ignore
        criteria[key].push(value);
      }
    });

    return api;
  }

  function get() {
    return criteria;
  }

  const api = {
    id,
    type,
    realm,
    ancestor,
    descendant,
    query,
    add,
    get,
  };

  return api;
};

export default criteriaBuilder;
