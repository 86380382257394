import { omit, pick } from 'lodash-es';
import { TableState } from 'react-table';

// Some properties should only be preserved in session instead of localStorage.
const sessionOnlyProps = ['pageIndex'];

const useDataProviderState = (id?: string) => {
  const getInitialState = () =>
    id && {
      ...JSON.parse(window.localStorage.getItem(id) || '{}'),
      ...JSON.parse(window.sessionStorage.getItem(id) || '{}'),
    };

  const saveState = (nextState: TableState) => {
    if (id) {
      window.localStorage.setItem(
        id,
        JSON.stringify(omit(nextState, sessionOnlyProps))
      );
      window.sessionStorage.setItem(
        id,
        JSON.stringify(pick(nextState, sessionOnlyProps))
      );
    }
  };

  return {
    getInitialState,
    saveState,
  };
};

export default useDataProviderState;
