import { Theme } from '@mui/material';

export const railWidth = 56;

export const drawerWidth = 280;

export const headerHeight = 3.875;

export const transitionIn = (theme: Theme, prop: string) =>
  theme.transitions.create(prop, {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  });
export const transitionOut = (theme: Theme, prop: string) =>
  theme.transitions.create(prop, {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  });
