import { useTranslation } from '@/feature/lang';
import { Icon } from '@/icon/Icon';
import {
  AppBar,
  Badge,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from '@mui/material';
import { forwardRef, useState } from 'react';
import { FilterModalProps } from './FilterModal';

const Transition = forwardRef(function Transition(props, ref) {
  // @ts-ignore
  return <Slide direction="up" ref={ref} {...props} />;
});

const FullScreenModal = ({ badgeContent, Content }: FilterModalProps) => {
  const [open, setOpen] = useState(false);
  const { texts, translate } = useTranslation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={handleClickOpen} size="large">
        <Badge badgeContent={badgeContent} color="primary">
          <Icon name="filter" />
        </Badge>
      </IconButton>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        //@ts-ignore
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }} /*className={classes.appBar}*/>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              size="large"
            >
              <Icon name="close" />
            </IconButton>
            <Typography variant="h6" sx={{ flex: 1, ml: 2 }}>
              {translate(texts.component.layout.filterModal.title)}
            </Typography>
          </Toolbar>
        </AppBar>

        <Content onClose={handleClose} />
      </Dialog>
    </>
  );
};

export default FullScreenModal;
