import { createContext, useContext } from 'react';

export interface SelectionContextData {
  selected: string[];
  select: (id: string | string[]) => void;
  toggleSelected: (id: string) => void;
  clearSelection: () => void;
}

export const SelectionContext = createContext<SelectionContextData>({
  selected: [],
  select: () => {},
  toggleSelected: () => {},
  clearSelection: () => {},
});

export const useSelection = () => useContext(SelectionContext);

export const useFirstSelected = () => useSelection().selected.at(0);
