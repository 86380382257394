import Drawer from '@mui/material/Drawer';
import FeaturesActiveChip from '@/feature/features/FeaturesActiveChip';
import { mainNavItems } from '@/view/mainNavItems';
import { Box } from '../Box';
import { DevChip } from '../Chip/Chip';
import { Navigation } from '../Navigation';
import { ActAsCustomerInfo } from './ActAsCustomer';
import MobileDrawerHeader from './MobileDrawerHeader';

interface MobileDrawerProps {
  open: boolean;
  close: () => void;
}

const MobileDrawer = ({ open, close }: MobileDrawerProps) => (
  <Drawer
    anchor="left"
    open={open}
    onClose={() => close()}
    sx={{ zIndex: 1202 }}
    PaperProps={{
      sx: {
        backgroundImage: 'unset',
      },
    }}
  >
    <Box
      sx={{
        height: '100%',
        width: 'min(280px, 80vw)',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <MobileDrawerHeader close={close} />
      <Box flex={1}>
        <Navigation navigation={mainNavItems} />
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          padding: theme.spacing(1),
          gap: 1,
        })}
      >
        <FeaturesActiveChip />
        <DevChip />
      </Box>
      <ActAsCustomerInfo />
    </Box>
  </Drawer>
);

export default MobileDrawer;
