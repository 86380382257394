import type { ReactNode } from 'react';
import { createContext } from 'react';

interface MenuContextOptions {
  onClose: () => void;
}

const MenuContext = createContext<MenuContextOptions>({
  onClose: () => {},
});

interface MenuProviderProps {
  onClose?: () => void;
  children: ReactNode;
}
const MenuProvider = ({ onClose = () => {}, children }: MenuProviderProps) => {
  return (
    <MenuContext.Provider value={{ onClose }}>{children}</MenuContext.Provider>
  );
};

export { MenuContext, MenuProvider };
