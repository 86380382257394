import { MessagePayload } from '@firebase/messaging';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

/**
 * Handle push notification when the application is active.
 * Background messages are handled in the service worker,
 * see handleBackgroundMessage.
 * 
 * Example of FCM payload:
    {
      "from": "550639957416",
      "messageId": "751b5d72-c4b8-4214-b151-3272b4430c1f",
      "data": {
        "title": "Alarm: Kritisch",
        "body": "Erregergeschwindigkeit. Equipment name X, Company name X, Site name X",
        "web_target_link": "web://alarms?equipmentId=SANDVIK_CH840i_0990S20050&alarmId=97dc657e-356f-4482-87e6-2739dacc38b0",
        "mobile_target_link": "app://fleet/SANDVIK_CH840i_0990S20050/alarm/97dc657e-356f-4482-87e6-2739dacc38b0"
      }
    }
 * 
 * Note! Some code duplication exists between
 * \src\feature\pushNotifications\useHandleMessage.tsx
 * and \public\firebase-messaging-sw.js
 */
export const useHandleMessage = () => {
  const navigate = useNavigate();
  const invalidateQueries = useInvalidateQueries();

  return (payload: MessagePayload) => {
    const notificationTitle = payload.data?.title ?? '';
    const notificationOptions = {
      body: payload.data?.body ?? '',
      icon: '/asset/favicon.svg',
    };

    const notification = new Notification(
      notificationTitle,
      notificationOptions
    );

    notification.onclick = () => {
      const route = getRoute(payload.data?.web_target_link);
      if (navigate) {
        window.focus();
        navigate(route);
      } else {
        window.location.href = route;
      }
    };

    invalidateQueries(payload.data?.web_target_link);
  };
};

const useInvalidateQueries = () => {
  const queryClient = useQueryClient();

  return (targetLink?: string) => {
    const target = parseTargetLink(targetLink);
    if (target.isAlarm) {
      queryClient.invalidateQueries(['alarms']);
    }
  };
};

const getRoute = (targetLink?: string) => {
  const target = parseTargetLink(targetLink);
  return target.isAlarm && target.equipmentId && target.alarmId
    ? `/fleet/alarms?selected=${target.equipmentId}&alarmId=${target.alarmId}`
    : '/fleet';
};

const parseTargetLink = (targetLink?: string) => {
  const notificationType = targetLink?.split('?').at(0);
  const isAlarm = notificationType === 'web://alarms';
  const params = new URLSearchParams(targetLink?.split('?').at(1));
  const equipmentId = params.get('equipmentId');
  const alarmId = params.get('alarmId');
  return { isAlarm, equipmentId, alarmId };
};
