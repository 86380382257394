import { debounce, isEqual } from 'lodash-es';
import { useEffect, useMemo } from 'react';
import useWebSocket from 'react-use-websocket';
import { useCacheUpdater } from './cache';
import { createRequest } from './request';
import { streamConfig } from './streamConfig';
import type { DataStreamRequest, Subscription } from './types';
import { getWebSocketUrl } from './util';

// Debounce time (in ms) for updating the websocket subscription
const subscriptionDebounce = 500;

/**
 * Manages the websocket connection for data streaming. It serves two purposes:
 *
 * 1. Monitor what data to stream. Upon modification the websocket subscription
 * is updated.
 *
 * 2. Receive websocket messages and update (react-query) caches.
 *
 * @constructor
 */
export const DataStreamManager = () => {
  const handleUpdate = useCacheUpdater();
  const { sendMessage } = useWebSocket(getWebSocketUrl, {
    onMessage: async (message: MessageEvent<string>) => {
      const data = JSON.parse(message.data);
      if (data.status === 401) {
        console.log('Invalid ticket');
        return;
      }
      if (data.type === 'error') {
        console.error('WebSocket error message:', data);
        return;
      }
      handleUpdate(data);
    },
    onOpen: () => {
      const request = createRequest(streamConfig.getSubscriptions());
      sendMessage(JSON.stringify(request));
    },
    // Do not reconnect on 1008 (policy violation) as ticket is invalid
    shouldReconnect: (event) => event.code !== 1008,
    reconnectAttempts: 20,
    reconnectInterval: (attemptNumber) =>
      Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
  });

  const handleChangeSubscription = useMemo(() => {
    let activeSubscription: DataStreamRequest | undefined;

    return debounce((subscriptions: Subscription[]) => {
      const request = createRequest(subscriptions);
      if (isEqual(request, activeSubscription)) {
        return;
      }
      sendMessage(JSON.stringify(request));
      activeSubscription = request;
    }, subscriptionDebounce);
  }, [sendMessage]);

  useEffect(() => {
    streamConfig.on('change', handleChangeSubscription);

    return () => {
      streamConfig.off('change', handleChangeSubscription);
      handleChangeSubscription.cancel();
    };
  }, [handleChangeSubscription]);

  return null;
};
