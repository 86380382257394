import { useEquipmentByIdsQuery } from '@/feature/equipment/queries';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import FleetContext from './detailsContext';

interface FleetContextProviderProps {
  children?: ReactNode;
  selected: string[];
}

const DetailsContextProvider = ({
  children,
  selected,
}: FleetContextProviderProps) => {
  const {
    data = [],
    status,
    isLoading,
  } = useEquipmentByIdsQuery({
    equipmentIds: selected,
    include: [
      'documents',
      'inspection_interval',
      'trip_meter',
      'presentations',
    ],
  });

  const selectedEquipment = useMemo(
    () =>
      [...data].sort(
        (a, b) => selected.indexOf(a.id) - selected.indexOf(b.id)
      ) ?? [],
    [data, selected]
  );

  if (status === 'success' && data.length === 0) {
    throw new Error(
      'Unable to retrieve selected equipment from equipment endpoint'
    );
  }

  return (
    <FleetContext.Provider
      value={{
        selectedEquipment,
        isLoading,
        status: status || 'success',
      }}
      children={children}
    />
  );
};

export default DetailsContextProvider;
