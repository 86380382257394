import { post } from '@/api/v1';
import { padStart } from 'lodash-es';

export function createKey(prefix: string) {
  return `${prefix}-${padStart(
    Math.round(Math.random() * 100000) + '',
    6,
    '0'
  )}`;
}

interface TicketData {
  ticket: string;
  connectAt: string;
  expireAt: string;
}

export const getWebSocketUrl = async () => {
  const data = await post<TicketData>('websocket/ticket', undefined);
  return `wss://${window.location.host}/api/v1/websocket?ticket=${data?.ticket}`;
};
