import { useToast } from '@/component/Toast';
import { ToastAction } from '@/component/Toast/types';
import { useLocalStorage } from '@/util/useStorage';
import { atom, useAtom } from 'jotai';
import { useCallback, useEffect } from 'react';
import { useTexts } from '../lang';

const isSupported = () =>
  'Notification' in window &&
  'serviceWorker' in navigator &&
  'PushManager' in window;

const grantedAtom = atom(
  isSupported() && Notification.permission === 'granted'
);

export const useRequestPermission = (runOnStartup = false) => {
  const [granted, setGranted] = useAtom(grantedAtom);
  const [hidden, setHidden] = useLocalStorage(
    'user.hidePushRequest',
    isSupported() && Notification.permission === 'denied'
  );
  const { app } = useTexts();
  const toast = useToast();

  const requestPermission = useCallback(
    () => {
      if (granted || !isSupported()) {
        return;
      }

      if (Notification.permission === 'denied') {
        toast.error(app.notifications.errorBlockedInBrowser);
        return;
      }

      const doRequest = () =>
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            setGranted(true);
          }
        });

      const actions: ToastAction[] = [
        {
          label: app.notifications.allow,
          onClick: doRequest,
        },
      ];
      if (runOnStartup) {
        actions.unshift({
          label: app.notifications.dontShowAgain,
          onClick: () => setHidden(true),
        });
      }

      toast.info(app.notifications.notAllowedInBrowser, {
        actions,
        closeAfter: Infinity,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [granted]
  );

  useEffect(() => {
    if (runOnStartup && !granted && !hidden) {
      requestPermission();
    }
  }, [runOnStartup, granted, hidden, requestPermission]);

  return { granted, requestPermission };
};
