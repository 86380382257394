import { DiscreetButton } from '@/component/Button/Buttons';
import Empty from '@/component/Empty';
import { Page } from '@/component/Layout';
import Translate from '@/component/Translate';
import { useTexts } from '@/feature/lang';
import { Icon } from '@/icon/Icon';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { Button as MuiButton } from '@mui/material';
import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

export const NotFound = () => {
  const notFound = useTexts().app.routing.notFound;
  const appInsights = useAppInsightsContext();

  useEffect(
    () =>
      appInsights.trackException({
        id: 'Page not found',
        exception: new Error('404-error: ' + window.location.href),
      }),
    [appInsights]
  );

  const Button = DiscreetButton as typeof MuiButton;

  return (
    <Page>
      <Empty
        text={notFound.empty.text}
        actions={
          <Button to="/" startIcon={<Icon name="home" />} component={NavLink}>
            <Translate text={notFound.empty.action} />
          </Button>
        }
      />
    </Page>
  );
};
