import { excludeProps } from '@/theme/util';
import { styled, TableCell as MuiTableCell } from '@mui/material';

export const TableCell = styled(MuiTableCell, {
  name: 'TableCell',
  slot: 'Cell',
})(({ theme }) => ({
  height: '43px',
  minWidth: '50px',
  maxWidth: '250px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  padding: theme.spacing(1),
}));

export const HeadTableCell = styled(TableCell, {
  name: 'TableCell',
  slot: 'Head',
})({
  verticalAlign: 'bottom',
  lineHeight: 2.75,
});

interface BodyTableCellProps {
  expandedParent: boolean;
  selection: boolean;
  cellNoPadding: boolean;
}

export const BodyTableCell = styled(TableCell, {
  name: 'TableCell',
  slot: 'Body',
  shouldForwardProp: (propName) =>
    excludeProps(propName, ['expandedParent', 'selection', 'cellNoPadding']),
})<BodyTableCellProps>(
  ({ theme, expandedParent, selection, cellNoPadding }) => ({
    ...(expandedParent && {
      border: 'none',
    }),
    ...(selection && {
      padding: theme.spacing(0, 1),
    }),
    ...(cellNoPadding && {
      padding: 0,
    }),
  })
);

export const FooterTableCell = styled(TableCell, {
  name: 'TableCell',
  slot: 'Footer',
})({
  fontSize: '0.875rem',
  fontWeight: 500,
  border: 'none',
});

export const GroupHeaderTableCell = styled(TableCell, {
  name: 'TableCell',
  slot: 'GroupHeader',
})(({ theme }) => ({
  backgroundColor: theme.palette.getGrey(20),
  padding: theme.spacing(1, 2),
}));
