import { isNonEmptyArray, uniqueValues } from '@/util/array';
import type { BucketOperation } from './api.types';

export function getBucketOperation(
  operations: BucketOperation[] | undefined = []
): BucketOperation | undefined {
  if (!isNonEmptyArray(operations)) {
    return 'average';
  }

  const uniqueOperations = uniqueValues(operations);
  if (uniqueOperations.length > 1) {
    throw new Error('One operation per sample query is supported');
  }
  const [operation] = uniqueOperations;
  if (operation === 'disabled') {
    return undefined;
  }
  return operation;
}
