import { isUndefined } from 'lodash-es';
import type { Plural, Translatable } from './types';

export const isTranslatable = (toCheck: any): toCheck is Translatable =>
  (toCheck as Translatable)?.message !== undefined;

export const isPlural = (toCheck: any): toCheck is Plural => {
  const possiblePlural = toCheck as Plural;
  return (
    possiblePlural?.zero !== undefined ||
    possiblePlural?.one !== undefined ||
    possiblePlural?.other !== undefined
  );
};

const fallbackMessage = '';

export function getMessage(text: Translatable, count?: number): string {
  if (isPlural(text.message)) {
    // Check if count is set, weird if count is not set
    if (isUndefined(count)) {
      console.warn(
        'Translation contains plural forms but no count provided',
        text
      );
    }

    let message;
    switch (count) {
      case 0:
        message = text.message.zero || text.message.other;
        break;
      case 1:
        message = text.message.one || text.message.other;
        break;
      default:
        message = text.message.other;
    }
    if (!message) {
      console.warn(
        `Translation contains no plural form to handle count=${count}`,
        text
      );
    }
    return message || fallbackMessage;
  } else {
    // Check if count is set, weird if set and no plural forms exists ?
    if (!isUndefined(count)) {
      console.warn(
        'Translation contains no plural forms, but trying to translate with plural',
        text,
        count
      );
    }
    return text.message;
  }
}
