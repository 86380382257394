import type { ReactNode } from 'react';
import { useCallback, useMemo } from 'react';
import { toArray } from '@/util/array';
import useQuery from '@/util/useQuery';
import useUrlBuilder from '@/util/useUrlBuilder';
import { asideParamName } from '../components/Layout/aside/hooks';
import { SelectionContext } from './SelectionContext';

export const selectionParamName = 'selected';

interface SelectionProviderProps {
  children: ReactNode;
}

export const UrlParamSelectionProvider = ({
  children,
}: SelectionProviderProps) => {
  const { editUrl } = useUrlBuilder();
  const { query } = useQuery();
  const selected = useMemo(
    () => (query.selected ? query.selected.split(',') : []),
    [query.selected]
  );

  const select = useCallback(
    (id: string | string[]) => {
      editUrl()
        .setParam(selectionParamName, toArray(id).join(','))
        // Close aside when changing selection
        .deleteParam(asideParamName)
        .push();
    },
    [editUrl]
  );

  const toggleSelected = useCallback(
    (id: string) => {
      const ids = selected.includes(id)
        ? selected.filter((i) => i !== id)
        : [...selected, id];
      select(ids);
    },
    [selected, select]
  );

  const clearSelection = useCallback(() => {
    editUrl()
      .deleteParam(selectionParamName)
      // Close aside when changing selection
      .deleteParam(asideParamName)
      .replace();
  }, [editUrl]);

  return (
    <SelectionContext.Provider
      value={{
        selected,
        select,
        clearSelection,
        toggleSelected,
      }}
      children={children}
    />
  );
};
