import { useTexts } from '@/feature/lang';
import { Icon } from '@/icon/Icon';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import type { MouseEvent } from 'react';
import { Box } from '../Box';
import Translate from '../Translate';

export function getPaginationInfo(
  count: number,
  page: number,
  rowsPerPage: number
) {
  const from = page * rowsPerPage + 1;
  const to = Math.min(count, from + rowsPerPage - 1);
  const hasPrevPage = page > 0;
  const hasNextPage = to < count;

  return { from, to, hasNextPage, hasPrevPage };
}

interface PaginationProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => void;
}

export const Pagination = ({
  count,
  page,
  rowsPerPage,
  onPageChange,
}: PaginationProps) => {
  const texts = useTexts().component.dataGrid;
  const { from, to, hasPrevPage, hasNextPage } = getPaginationInfo(
    count,
    page,
    rowsPerPage
  );

  const gotoNextPage = () => onPageChange(null, page + 1);
  const gotoPrevPage = () => onPageChange(null, page - 1);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <IconButton onClick={gotoPrevPage} disabled={!hasPrevPage}>
        <Icon name="chevron_left" size="small" />
      </IconButton>
      <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
        <Translate text={texts.displayedRows} values={{ from, to, count }} />
      </Typography>
      <IconButton onClick={gotoNextPage} disabled={!hasNextPage}>
        <Icon name="chevron_right" size="small" />
      </IconButton>
    </Box>
  );
};
