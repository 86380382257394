import { merge } from 'lodash-es';
import { defaultOptions } from './defaults';

type QueryValueStrict = string | boolean | number;
type QueryValue = QueryValueStrict | undefined;
type QueryParamData = { [key: string]: QueryValue | QueryValueStrict[] };

export function toQueryParams(data: QueryParamData | undefined) {
  if (data === undefined) {
    return '';
  }
  const params = new URLSearchParams();
  Object.entries(data).forEach(([key, value]) => {
    if (value === undefined) {
      return;
    }
    if (Array.isArray(value)) {
      params.append(key, value.map((d) => String(d)).join(','));
    } else {
      params.append(key, String(value));
    }
  });

  return params.toString() ? `?${params.toString()}` : '';
}

export function mergeOptions(...options: Array<Options | undefined>) {
  return merge({}, defaultOptions, ...options);
}
