import { useIsRootRealmUser } from '@/feature/permissions/IsRootRealmUser';
import { Navigate, Route, Routes } from 'react-router-dom';
import AdminRoutes from './admin/routes';
import { NotFound } from './app/routing/NotFound';
import EcomRoutes from './ecom/routes';
import FleetRoutes from './fleet/routes';
import { adminPath, ecomPath, fleetPath } from './pathDefinitions';

export default () => {
  const isRootRealmUser = useIsRootRealmUser();
  const redirectRoute = isRootRealmUser ? adminPath : fleetPath;

  return (
    <Routes>
      <Route path="/" element={<Navigate to={redirectRoute} replace />} />
      <Route path={`${fleetPath}/*`} element={<FleetRoutes />} />
      <Route path={`${ecomPath}/*`} element={<EcomRoutes />} />
      <Route path={`${adminPath}/*`} element={<AdminRoutes />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
