import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { NotFound } from '../../app/routing/NotFound';

const CartView = lazy(() => import('./CartView'));

const CartRoutes = () => (
  <Routes>
    <Route path="/" element={<CartView />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
);

export default CartRoutes;
