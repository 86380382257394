import { Icon } from '@/icon/Icon';
import { Checkbox as MuiCheckbox, CheckboxProps } from '@mui/material';

const Checkbox = (props: CheckboxProps) => (
  <MuiCheckbox
    checkedIcon={<Icon name="check_box" />}
    icon={<Icon name="check_box_outline_blank" />}
    indeterminateIcon={<Icon name="indeterminate_check_box" />}
    color="default"
    {...props}
  />
);

export default Checkbox;
