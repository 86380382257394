import { attachToDebug } from '@/util/debug';
import { useLocalStorage } from '@/util/useStorage';
import './font';
import './muiThemeExtensions';
import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
  useMediaQuery,
} from '@mui/material';
import { createTheme } from '@mui/material/styles';
import type { ReactNode } from 'react';
import ThemeContext from './ThemeContext';
import darkTheme from './themes/g/darkTheme';
import lightTheme from './themes/g/lightTheme';

const defaultTheme = 'auto';

function getTheme(name: string, prefersDarkMode: boolean) {
  let light = lightTheme;
  let dark = darkTheme;

  switch (name) {
    case 'auto':
      return prefersDarkMode ? dark : light;
    case 'dark':
      return dark;
    case 'light':
    default:
      return light;
  }
}

interface ThemeProviderProps {
  children: ReactNode;
  // Overrides selected theme
  name?: string;
}

export default ({ children, name }: ThemeProviderProps) => {
  const [themeName, setThemeName] = useLocalStorage('ui.theme', defaultTheme);
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const currentThemeName = name ?? themeName;

  const theme = createTheme(getTheme(currentThemeName, prefersDarkMode));

  attachToDebug('theme', theme);

  return (
    <ThemeContext.Provider
      value={{
        name: currentThemeName,
        setTheme: (themeName) => {
          setThemeName(themeName);
        },
      }}
    >
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </StyledEngineProvider>
    </ThemeContext.Provider>
  );
};
