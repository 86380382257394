export default class ServerError extends Error {
  name: string;
  url: string;
  method: string;
  status: number;
  response: Response;

  constructor(response: Response, request: Request) {
    super(response.statusText);
    this.name = 'ServerError';
    this.url = request.url;
    this.method = request.method;
    this.status = response.status;
    this.response = response;
    if (!!Error.captureStackTrace) {
      //captureStackTrace is not a standard method on error.
      Error.captureStackTrace(this, ServerError);
    }
  }
}
