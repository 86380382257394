import type { ElementType } from 'react';
import LegendBarIcon from './asset/chip-symbol-bar.svg';
import LegendLineDashedIcon from './asset/chip-symbol-line-dashed.svg';
import LegendLineDottedIcon from './asset/chip-symbol-line-dotted.svg';
import LegendLineSolidIcon from './asset/chip-symbol-line-solid.svg';
import FleetOutlinedIcon from './asset/fleet2Outlined.svg';
import AssemblyIcon from './asset/network--2 1.svg';
import TreeIcon from './asset/tree_24px.svg';

export default class IconType {
  static readonly FleetOutlined = new IconType('fleet', FleetOutlinedIcon);
  static readonly Assembly = new IconType('part', AssemblyIcon);
  static readonly LegendBar = new IconType('part', LegendBarIcon);
  static readonly LegendLineSolid = new IconType('part', LegendLineSolidIcon);
  static readonly LegendLineDashed = new IconType('part', LegendLineDashedIcon);
  static readonly LegendLineDotted = new IconType('part', LegendLineDottedIcon);
  static readonly Tree = new IconType('part', TreeIcon);

  private constructor(
    public readonly name: string,
    public readonly IconComponent: ElementType
  ) {}
}
