import type { BoxProps } from '@mui/material/Box';
import MuiBox from '@mui/material/Box';
import { forwardRef } from 'react';

// This component exists in order to be able to use MUI and @react-three/fiber.
// `<Box component="div" ... />` fixes error "Expression produces a union type
// that is"too complex to represent" reported by Typescript.
export const Box = forwardRef((props: BoxProps, ref) => (
  <MuiBox {...props} component="div" ref={ref} />
));
