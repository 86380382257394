import { cloneDeep } from 'lodash-es';

export const walk = (obj: Record<string, any>, path: string) =>
  path.split('.').reduce((o, key) => (o && o[key] ? o[key] : null), obj);

export const exclude = <T extends object>(
  obj: T,
  keys: Array<keyof typeof obj>
) => {
  const copy = { ...obj };
  return keys.reduce((acc, key) => {
    delete acc[key];
    return acc;
  }, copy);
};

export function isKeyOfObject<T extends object>(
  key: string | number | symbol,
  obj: T
): key is keyof T {
  return key in obj;
}

export function excludeProps<T extends object>(
  obj: T,
  properties: string[],
  replaceWithValue?: string
) {
  const cleanedObject = cloneDeep(obj);

  const remove = (o: any) => {
    for (let property in o) {
      if (properties.includes(property)) {
        if (typeof replaceWithValue === 'string') {
          // @ts-ignore
          o[property] = replaceWithValue;
        } else {
          delete o[property];
        }
      } else if (typeof o[property] === 'object') {
        remove(o[property]);
      }
    }
  };

  remove(cleanedObject);

  return cleanedObject;
}
