import ServerError from '@/api/ServerError';
import { useMutation } from '@tanstack/react-query';
import { createCart, updateCart } from './api';

export const useCreateCartMutation = () =>
  useMutation<CartDto, ServerError, CartMutable>(createCart);

export const useUpdateCartMutation = () => {
  return useMutation<CartDto, ServerError, CartMutable>(['cart'], save);
};

function save(cart: CartMutable) {
  const cleanedCart = cleanup(cart);
  return updateCart(cleanedCart);
}

function cleanup(cart: CartMutable): CartMutable {
  const items = cart.items.map((item) => ({
    cartAllocator: item.cartAllocator,
    itemId: item.itemId,
    position: item.position,
    equipmentId: item.equipmentId,
    quantity: item.quantity,
    deliveryPointEntityId: item.deliveryPointEntityId,
  }));

  return {
    items,
  };
}
