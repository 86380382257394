import Checkbox from '../Checkbox';
import { useDataProvider } from './hook';

export default (hooks: any) => {
  hooks.allColumns.push((columns: any) => [
    // Let's make a column for selection
    {
      id: 'selection',
      // The header can use the table's getToggleAllRowsSelectedProps method
      // to render a checkbox.  Pagination is a problem since this will select all
      // rows even though not all rows are on the current page.  The solution should
      // be server side pagination.  For one, the clients should not download all
      // rows in most cases.  The client should only download data for the current page.
      // In that case, getToggleAllRowsSelectedProps works fine.
      Header: ({ getToggleAllRowsSelectedProps, data }: any) => {
        const { onSelectAllRows } = useDataProvider();
        return data.length > 1 ? (
          <div>
            <Checkbox
              {...getToggleAllRowsSelectedProps()}
              onChange={(event) => onSelectAllRows(event.target.checked)}
            />
          </div>
        ) : null;
      },
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      Cell: ({ row }: any) => {
        const { onSelectRow } = useDataProvider();
        return (
          <Checkbox
            size="small"
            {...row.getToggleRowSelectedProps()}
            onChange={(event) => onSelectRow(row, event.target.checked)}
          />
        );
      },
    },
    ...columns,
  ]);
};
