import { Icon } from '@/icon/Icon';
import { Typography } from '@mui/material';
import type { ReactNode } from 'react';
import { Cell, TableCellProps } from 'react-table';
import { Box } from '../Box';
import CountBubble from '../CountBubble';
import StopPropagation from '../StopPropagation';
import { GroupHeaderTableCell } from './StructuredTable/TableCell';

export interface GroupHeaderProps extends TableCellProps {
  cell: Cell;
  actions?: ReactNode;
}

export const GroupHeader = ({
  cell,
  actions,
  ...tableCellProps
}: GroupHeaderProps) => {
  return (
    <GroupHeaderTableCell {...tableCellProps} colSpan={cell.row.cells.length}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center" flex={1}>
          <Typography variant="button">
            {cell.render('Cell') as ReactNode}
          </Typography>
          <CountBubble badgeContent={cell.row.subRows.length} color="primary" />
          <Box flex={1} />
          <StopPropagation>{actions}</StopPropagation>
        </Box>
        <StopPropagation>
          <Box {...cell.row.getToggleRowExpandedProps()} sx={{ ml: 2 }}>
            {cell.row.isExpanded ? (
              <Icon name="expand_less" />
            ) : (
              <Icon name="expand_more" />
            )}
          </Box>
        </StopPropagation>
      </Box>
    </GroupHeaderTableCell>
  );
};
