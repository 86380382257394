import { useMutation } from '@tanstack/react-query';
import { addRegistrationToken, removeRegistrationToken } from './api';
import { NotificationToken } from './api.types';

export const useAddRegistrationToken = () =>
  useMutation({
    mutationFn: (request: NotificationToken) => addRegistrationToken(request),
  });

export const useRemoveRegistrationToken = () =>
  useMutation({
    mutationFn: (request: NotificationToken) =>
      removeRegistrationToken(request),
  });
