import type { Translation } from '@/feature/lang/types';
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Theme,
  Typography,
} from '@mui/material';
import type { ReactNode } from 'react';
import { useContext } from 'react';
import Translate from '../Translate';
import { MenuContext } from './context';

const getColor = (color: 'primary' | 'error' | undefined, theme: Theme) =>
  color === 'error' ? theme.palette.error.main : undefined;

interface MenuItemProps {
  icon?: ReactNode;
  iconAfter?: ReactNode;
  onClick: () => boolean | void;
  disabled?: boolean;
  color?: 'primary' | 'error';
  selected?: boolean;
}

interface WithLabel extends MenuItemProps {
  label: Translation;
  children?: never;
}

interface WithChildren extends MenuItemProps {
  label?: never;
  children: ReactNode;
}

const MenuItem = ({
  children,
  disabled,
  onClick,
  label,
  icon,
  iconAfter,
  color = 'primary',
  selected,
}: WithLabel | WithChildren) => {
  const { onClose } = useContext(MenuContext);

  return (
    <ListItemButton
      disabled={disabled}
      onClick={() => {
        const keepOpen = onClick();
        if (!keepOpen) {
          onClose();
        }
      }}
      sx={(theme) => ({
        color: getColor(color, theme),
        backgroundColor: selected
          ? theme.palette.grey[theme.palette.mode === 'light' ? 400 : 500]
          : 'unset',
      })}
      disableRipple
    >
      {icon && (
        <ListItemIcon
          sx={{
            minWidth: '40px',
            color: (theme) => getColor(color, theme),
          }}
        >
          {icon}
        </ListItemIcon>
      )}
      <ListItemText>
        <Typography variant="body2">
          {label ? <Translate text={label} /> : children}
        </Typography>
      </ListItemText>
      {iconAfter && (
        <ListItemIcon sx={{ minWidth: 'unset' }}>{iconAfter}</ListItemIcon>
      )}
    </ListItemButton>
  );
};

export default MenuItem;
