import { DialogContent as MuiDialogContent } from '@mui/material';
import { styled } from '@mui/material/styles';

const DialogContent = styled(MuiDialogContent, {
  name: 'Dialog',
  slot: 'Content',
})(({ theme }) => ({
  padding: theme.spacing(0, 3),
}));

export default DialogContent;
