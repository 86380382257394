import MuiAppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import { Fragment, Suspense } from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from '@/icon/Icon';
import { Box } from '../Box';
import SamLogo from '../Logo/SamLogo';

export const appBarHeight = 54;

interface AppBarProps {
  toggleDrawer?: () => void;
  isSmallScreen?: boolean;
  actions?: JSX.Element[];
}

const AppBar = ({
  toggleDrawer = () => {},
  isSmallScreen = false,
  actions = [],
}: AppBarProps) => {
  return (
    <MuiAppBar
      color="transparent"
      position="fixed"
      elevation={0}
      sx={[
        (theme) => ({
          zIndex: theme.zIndex.drawer + 1,
          backgroundColor: 'background.paper',
          borderBottom: `1px solid`,
          borderBottomColor: 'divider',
        }),
      ]}
    >
      <Toolbar
        sx={{
          minHeight: `${appBarHeight}px !important`,
          pl: { sm: 3, md: 2 },
          gap: 2,
        }}
      >
        <Left isSmallScreen={isSmallScreen} toggleDrawer={toggleDrawer} />
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            alignItems: 'center',
            flexFlow: 'row-reverse',
            minWidth: 0,
            height: `${appBarHeight}px`,
          }}
        >
          <Suspense fallback={<></>}>
            {actions.map((action, i) => (
              <Fragment key={i}>{action}</Fragment>
            ))}
          </Suspense>
        </Box>
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;

interface AppBarLeftProps {
  isSmallScreen: boolean;
  toggleDrawer: () => void;
}

const Left = ({ isSmallScreen, toggleDrawer }: AppBarLeftProps) => {
  if (isSmallScreen) {
    return (
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={() => toggleDrawer()}
        edge="start"
        size="large"
      >
        <Icon name="menu" />
      </IconButton>
    );
  }

  return (
    <NavLink to="/" style={{ lineHeight: 0 }}>
      <SamLogo />
    </NavLink>
  );
};
