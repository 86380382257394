import { roundedInputSx } from '@/component/Form/RoundedInput';
import { languages } from '@/feature/lang/config';
import useTranslation from '@/feature/lang/hook';
import { MenuItem, Select, Typography } from '@mui/material';

export function LangSelector() {
  const { changeLanguage, language } = useTranslation();

  return (
    <Select
      variant="filled"
      disableUnderline
      value={language ?? 'en'}
      onChange={(e) => {
        changeLanguage(String(e.target.value));
      }}
      renderValue={(value: any) => (
        <Typography variant="body2">
          {languages.find((l) => l.id === value)?.name}
        </Typography>
      )}
      sx={[
        roundedInputSx,
        {
          width: '75px',
          '.MuiInputBase-input:focus': {
            backgroundColor: 'unset',
          },
          '.MuiSelect-select': {
            padding: 0,
            minHeight: 'unset !important',
          },
          '.MuiSelect-icon': {
            right: 12,
          },
        },
      ]}
    >
      {languages.map(({ id, displayName }) => (
        <MenuItem value={id} key={id}>
          <Typography variant="body2">{displayName}</Typography>
        </MenuItem>
      ))}
    </Select>
  );
}
