import { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { NotFound } from '../app/routing/NotFound';
import {
  cartPath,
  ordersPath,
  partsPath,
  recommendationsPath,
  shoppinglistsPath,
} from '../pathDefinitions';
import CartRoutes from './cart/routes';
import OrderRoutes from './orders/routes';
import PartRoutes from './part/routes';
import RecommendationRoutes from './recommendation/routes';
import ShoppingListRoutes from './shoppinglists/routes';

export default () => (
  <Suspense fallback={<></>}>
    <Routes>
      <Route path="/" element={<Navigate to={partsPath} replace />} />
      <Route path={`${partsPath}/*`} element={<PartRoutes />} />
      <Route
        path={`${recommendationsPath}/*`}
        element={<RecommendationRoutes />}
      />
      <Route path={`${shoppinglistsPath}/*`} element={<ShoppingListRoutes />} />
      <Route path={`${ordersPath}/*`} element={<OrderRoutes />} />
      <Route path={`${cartPath}/*`} element={<CartRoutes />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </Suspense>
);
