import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@/component/Accordion';
import { Box } from '@/component/Box';
import ButtonLoading from '@/component/ButtonLoading';
import { Input } from '@/component/Form';
import Translate from '@/component/Translate';
import { useRejectAgreementMutation } from '@/feature/agreement/mutations';
import { useTranslation } from '@/feature/lang';
import { styled } from '@mui/material/styles';
import { useState } from 'react';

const AccordionContainer = styled('div')(() => ({
  maxWidth: '500px',
  '& .MuiAccordion-root': {
    marginBottom: '-2px',
  },
  '& .MuiAccordionSummary-root': {
    height: '40px',
    minHeight: '40px',
  },
}));

const InputWithButtonHeight = styled(Input)(() => ({
  '& .MuiInputBase-input': {
    height: '22px',
  },
}));

interface RejectAgreementProps {
  onRejected: () => void;
}

export const RejectAgreement = ({ onRejected }: RejectAgreementProps) => {
  const { texts, translate } = useTranslation();
  const [rejectText, setRejectText] = useState('');
  const rejectWord = translate(texts.agreement.action.reject)?.toUpperCase();
  const { mutateAsync: rejectAgreement, isLoading } =
    useRejectAgreementMutation();

  const handleReject = () => rejectAgreement().then(onRejected);

  return (
    <AccordionContainer>
      <Accordion>
        <AccordionSummary
          header={texts.agreement.action.reject}
        ></AccordionSummary>
        <AccordionDetails>
          <Translate
            text={texts.agreement.action.rejectInstruction}
            values={{ rejectWord }}
          />
          <Box display="flex" gap={2} alignItems={'baseline'}>
            <InputWithButtonHeight
              value={rejectText}
              onChange={(e) => setRejectText(e.target.value.toUpperCase())}
              placeholder={texts.common.typeHere}
            />
            <ButtonLoading
              color={'error'}
              variant={'outlined'}
              disabled={rejectText !== rejectWord}
              onClick={handleReject}
              loading={isLoading}
            >
              <Translate text={texts.agreement.action.reject} />
            </ButtonLoading>
          </Box>
        </AccordionDetails>
      </Accordion>
    </AccordionContainer>
  );
};
