type DecorateArgs<S, T extends { [key: string]: any }, K = keyof T> = {
  withData: T[];
  locator(entry: S, extraDataEntry: T): boolean;
  from: K;
  to: string;
};

function dataBuilder<S, R extends S>(startData: S[] | undefined = []) {
  let data = startData;

  return {
    decorate<T extends { [key: string]: any }>({
      withData,
      locator,
      from,
      to,
    }: DecorateArgs<S, T>) {
      data = data.map((entry) => {
        const match = withData.find((decorateEntry) =>
          locator(entry, decorateEntry)
        );

        return {
          [to]: match?.[from],
          ...entry,
        };
      });

      return this;
    },
    transform(transformFn: (entry: S) => R) {
      data = data.map(transformFn);

      return this;
    },
    build() {
      return data as R[];
    },
  };
}

export default dataBuilder;
