import './stylesheet.css';

// @ts-ignore
(() => {
  if (document?.fonts) {
    setTimeout(function () {
      document.fonts.load('400 30px SandvikSansHeadline');
      document.fonts.load('400 16px SandvikSansText');
      document.fonts.load('700 16px SandvikSansText');
    }, 0);
  }
})();
