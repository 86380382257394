import SvgIcon from '@/component/Icon';
import IconType from '@/component/Icon/IconType';
import { NavItem } from '@/component/Navigation/types';
import { Icon } from '@/icon/Icon';
import { adminNavItems } from './admin/adminNavItems';
import { ecomNavItems } from './ecom/ecomNavItems';
import { fleetNavItems } from './fleet/fleetNavItems';
import { adminPath, ecomPath, fleetPath } from './pathDefinitions';

export const mainNavItems: NavItem[] = [
  {
    label: ({ app }) => app.navigation.fleet.label,
    to: fleetPath,
    subItems: fleetNavItems,
    retainQuery: true,
    icon: <SvgIcon type={IconType.FleetOutlined} />,
  },
  {
    label: ({ app }) => app.navigation.shop.label,
    to: ecomPath,
    subItems: ecomNavItems,
    icon: <Icon name="shopping_cart" />,
    access: (permissions) => permissions.some((p) => p.startsWith('ecom')),
  },
  {
    label: ({ app }) => app.navigation.admin.label,
    to: adminPath,
    subItems: adminNavItems,
    icon: <Icon name="settings" />,
  },
];
