import { noop } from '@/util/fn';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import type { ReactNode } from 'react';
import { createContext } from 'react';
import { useHasPermission } from '../permissions/PermissionsProvider';
import { useCurrentUser } from '../user/queries';
import type { FeatureName } from './index';

const restrictedFeatures: FeatureName[] = [];
const restrictedFeaturesWhitelist = [
  'thomas.x.bengtsson_c@sandvik.com',
  'adam.hogstedt_c@sandvik.com',
  'veikko.rihu_c@sandvik.com',
];

export const featuresAtom = atomWithStorage<FeatureConfig>('features', {});

const inactive = {
  includes: () => false,
  on: noop(),
  off: noop(),
  reset: noop(),
  list: [],
  activeCount: 0,
};

export const FeatureContext = createContext<Features>(inactive);

interface ProviderProps {
  features: readonly FeatureName[];
  children: ReactNode;
}

const Provider = ({ features, children }: ProviderProps) => {
  const [config, setConfig] = useAtom(featuresAtom);
  const hasAccess = useHasPermission('feattgl:r');
  const user = useCurrentUser();
  const filteredFeatures = features.filter((feature) => {
    if (restrictedFeatures.includes(feature) && user.username) {
      return restrictedFeaturesWhitelist.includes(user.username);
    }
    return true;
  });

  if (!hasAccess) {
    return (
      <FeatureContext.Provider value={inactive}>
        {children}
      </FeatureContext.Provider>
    );
  }

  const activeCount = Object.values(config).filter((c) => c.active).length;

  function includes(...names: FeatureName[]) {
    return names.some((name) => config[name]?.active);
  }

  function on(name: FeatureName) {
    setConfig({ ...config, [name]: { active: true } });
  }

  function off(name: FeatureName) {
    setConfig({ ...config, [name]: { active: false } });
  }

  function reset() {
    setConfig(getInitialState(features));
  }

  return (
    <FeatureContext.Provider
      value={{ includes, on, off, reset, list: filteredFeatures, activeCount }}
    >
      {children}
    </FeatureContext.Provider>
  );
};

export default Provider;

function getInitialState(features: readonly FeatureName[]): FeatureConfig {
  return features.reduce<FeatureConfig>((config, feature) => {
    config[feature] = {
      active: false,
    };
    return config;
  }, {} as FeatureConfig);
}
