import { useContext } from 'react';
import { FeatureName } from '.';
import { FeatureContext } from './Provider';

const useFeatures = () => {
  return useContext(FeatureContext);
};

export default useFeatures;

export const useIsFeatureActive = (name: FeatureName) => {
  const features = useFeatures();
  return features.includes(name);
};
