import { NavItem } from '@/component/Navigation/types';
import { Icon } from '@/icon/Icon';
import {
  activitiesFullPath,
  alarmsFullPath,
  basicsFullPath,
  equipmentDashboardFullPath,
  equipmentDataFullPath,
  equipmentPartsFullPath,
  insightsFullPath,
  noticeBoardFullPath,
} from '../pathDefinitions';

export const fleetNavItems: NavItem[] = [
  {
    to: equipmentDashboardFullPath,
    icon: <Icon name="dashboard" size="small" />,
    label: ({ fleet }) => fleet.dashboard.header,
    featureActive: (features) => features.includes('fleet.dashboard'),
  },
  {
    to: equipmentDataFullPath,
    icon: <Icon name="equipment_data" size="small" />,
    label: ({ fleet }) => fleet.data.header,
    access: (permissions) => permissions.includes('eqmnt.trends:r'),
  },
  {
    to: alarmsFullPath,
    icon: <Icon name="alarms" size="small" />,
    label: ({ fleet }) => fleet.alarms.header,
  },
  {
    to: insightsFullPath,
    icon: <Icon name="insights" size="small" />,
    label: ({ fleet }) => fleet.insights.header,
    access: (permissions) => permissions.includes('insights:r'),
  },
  {
    to: noticeBoardFullPath,
    icon: <Icon name="note" size="small" />,
    label: ({ fleet }) => fleet.noticeBoard.header,
  },
  {
    to: activitiesFullPath,
    icon: <Icon name="activities" size="small" />,
    label: ({ fleet }) => fleet.activity.list.header,
    access: (permissions) => permissions.some((p) => p.startsWith('activity')),
  },
  {
    to: basicsFullPath,
    icon: <Icon name="equipment_basics" size="small" />,
    label: ({ fleet }) => fleet.basics.header,
  },
  {
    to: equipmentPartsFullPath,
    icon: <Icon name="parts" size="small" />,
    label: ({ fleet }) => fleet.partsCatalog.header,
    access: (permissions) => permissions.some((p) => p.startsWith('ecom')),
  },
];
