import { useMediaQuery, useTheme } from '@mui/material';
import type { ReactElement, ReactNode } from 'react';
import Popper from '../../Popper';
import FullScreenModal from './FullScreenModal';
import OpenFilterButton from './OpenFilterButton';

export interface FilterModalProps {
  badgeContent: ReactNode;
  Content: (props: PopperContentProps) => ReactElement;
}

const FilterModal = ({ badgeContent, Content }: FilterModalProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'), {
    noSsr: true,
  });

  if (fullScreen) {
    return <FullScreenModal badgeContent={badgeContent} Content={Content} />;
  }

  return (
    <Popper
      badgeContent={badgeContent}
      Trigger={OpenFilterButton}
      Content={Content}
      closeOnClickAway
      strategy="absolute"
    />
  );
};

export default FilterModal;
