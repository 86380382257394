import { styled } from '@mui/material';
import MuiIconButton, {
  IconButtonProps as MuiIconButtonProps,
} from '@mui/material/IconButton';
import { forwardRef } from 'react';

const StyledIconButton = styled(MuiIconButton, {
  name: 'IconButton',
  shouldForwardProp: (propName) => propName !== 'variant',
})<IconButtonProps>(({ theme, variant }) => ({
  padding: theme.spacing(1),
  ...(variant === 'outlined' && {
    padding: subPixels(theme.spacing(1), 1),
    border: '1px solid',
    borderColor: theme.palette.action.selected,
  }),
}));

function subPixels(str: string, val: number) {
  const num = Number.parseInt(str.replace('px', ''));
  return `${num - val}px`;
}

interface IconButtonProps extends MuiIconButtonProps {
  variant?: 'icon' | 'outlined';
}

const IconButton = forwardRef<any, IconButtonProps>(
  ({ variant = 'icon', classes, ...rest }, ref) => {
    return <StyledIconButton {...rest} variant={variant} ref={ref} />;
  }
);

export default IconButton;
