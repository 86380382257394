import { usePushLogoutAction } from '../pushNotifications/usePushLogoutAction';
import useAuth from './useAuth';

const useLogout = () => {
  const { logout: keycloakLogout } = useAuth();
  const pushLogoutAction = usePushLogoutAction();

  return () => {
    pushLogoutAction().then(keycloakLogout);
  };
};

export default useLogout;
