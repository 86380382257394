export const languages = [
  {
    id: 'en',
    name: 'EN',
    displayName: 'English',
    texts: () => import('../../lang/en.json'),
    dateLocale: () => import('date-fns/locale/en-US'),
  },
  {
    id: 'sv',
    name: 'SV',
    displayName: 'Svenska',
    texts: () => import('../../lang/sv.json'),
    dateLocale: () => import('date-fns/locale/sv'),
  },
  {
    id: 'de',
    name: 'DE',
    displayName: 'Deutsch',
    texts: () => import('../../lang/de.json'),
    dateLocale: () => import('date-fns/locale/de'),
  },
  {
    id: 'es-CL',
    name: 'ES',
    displayName: 'Español',
    texts: () => import('../../lang/es-CL.json'),
    dateLocale: () => import('date-fns/locale/es'),
  },
  {
    id: 'fi',
    name: 'FI',
    displayName: 'Suomeksi',
    texts: () => import('../../lang/fi.json'),
    dateLocale: () => import('date-fns/locale/fi'),
  },
  {
    id: 'fr',
    name: 'FR',
    displayName: 'Français',
    texts: () => import('../../lang/fr.json'),
    dateLocale: () => import('date-fns/locale/fr'),
  },
  {
    id: 'pt-BR',
    name: 'PT',
    displayName: 'Português',
    texts: () => import('../../lang/pt-BR.json'),
    dateLocale: () => import('date-fns/locale/pt'),
  },
  {
    id: 'ru',
    name: 'RU',
    displayName: 'Русский',
    texts: () => import('../../lang/ru.json'),
    dateLocale: () => import('date-fns/locale/ru'),
  },
  {
    id: 'zh-CN',
    name: 'ZH',
    displayName: '简体中文',
    texts: () => import('../../lang/zh-CN.json'),
    dateLocale: () => import('date-fns/locale/zh-CN'),
  },
  {
    id: 'ko-KR',
    name: 'KR',
    displayName: '한국어',
    texts: () => import('../../lang/ko-KR.json'),
    dateLocale: () => import('date-fns/locale/ko'),
  },
];
