import { min } from 'date-fns';
import type { SampleTransformer, TransformSampleData } from './index';
import { getPreviousSample, getSamplesForSignal, getSignals } from './utils';

function isSampleWithinThreshold(
  sample: TransformSampleData,
  date: Date,
  thresholdMs: number
) {
  return date.getTime() - sample.timeStampAsDate.getTime() < thresholdMs;
}

function sampleExists(samples: TransformSampleData[], time: Date) {
  return samples.some(
    (sample) => sample.timeStampAsDate.getTime() === time.getTime()
  );
}

/**
 * Add samples for edges (from/to) of date range.
 */
export const addEdgeSamples: SampleTransformer = (samples, settings) => {
  const {
    dateRange,
    noDataThresholdSeconds,
    extrapolation = {},
  } = settings.options;
  if (!dateRange) {
    return samples;
  }
  const thresholdMs = noDataThresholdSeconds * 1000;
  const { from } = dateRange;
  const to = min([dateRange.to, new Date()]);

  return getSignals(samples)
    .filter((signalName) => Object.hasOwn(extrapolation, signalName))
    .reduce(
      (result, signalName) => {
        const signalSamples = getSamplesForSignal(signalName, samples);
        const previous = getPreviousSample(from, signalSamples);
        const last = getPreviousSample(to, signalSamples);

        const addFromSample =
          ['start', 'both'].includes(extrapolation[signalName]) &&
          !sampleExists(signalSamples, from) &&
          previous;
        const addToSample =
          ['end', 'both'].includes(extrapolation[signalName]) &&
          !sampleExists(signalSamples, to) &&
          last &&
          isSampleWithinThreshold(last, to, thresholdMs);

        if (addFromSample) {
          result.push({
            ...previous,
            timeStamp: from.toISOString(),
            timeStampAsDate: from,
          });
        }
        if (addToSample) {
          result.push({
            ...last,
            timeStamp: to.toISOString(),
            timeStampAsDate: to,
          });
        }

        return result;
      },
      [...samples]
    );
};
