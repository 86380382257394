export const baseUrl = '/api/v1';

export const defaultOptions = {
  responseHandler: (options: OptionsStrict) => (response: Response) =>
    response
      .json()
      .then((json) => {
        const data = options.selector(json, options);
        const copy = Array.isArray(data) ? [...data] : { ...data };
        copy.__original__ = json;
        return copy;
      })
      .catch((error) => {
        console.log('Failed to parse response to JSON / select value', error);
      }),
  selector: (json: any, options: Options) => json?.data ?? options.defaultValue,
  defaultValue: undefined,
  baseUrl,
};
