import { isNonEmptyArray } from '@/util/array';
import { ITelemetryItem } from '@microsoft/applicationinsights-core-js';

const blacklist = [
  'ResizeObserver loop limit exceeded',
  'ResizeObserver loop completed with undelivered notifications.',
  // Related to using Google Translate in browser, see https://github.com/facebook/react/issues/11538#issuecomment-417504600.
  "Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
];
export const suppressTelemetry = (
  envelope: ITelemetryItem,
  blacklistedExceptions: string[] = blacklist
) => {
  const exceptions = envelope.baseData?.exceptions;
  return (
    isNonEmptyArray(exceptions) &&
    exceptions.some((ex: Error) => blacklistedExceptions.includes(ex.message))
  );
};
