import { Icon } from '@/icon/Icon';
import { Badge, IconButton } from '@mui/material';

function OpenFilterButton({
  badgeContent,
  onClick,
  disabled,
}: PopperTriggerProps) {
  return (
    <IconButton
      onClick={onClick}
      disabled={disabled}
      size="large"
      sx={{ p: 1 }}
    >
      <Badge badgeContent={badgeContent} color="primary">
        <Icon name="filter" size="small" />
      </Badge>
    </IconButton>
  );
}

export default OpenFilterButton;
