import type { Translation } from '@/feature/lang/types';
import { excludeProps } from '@/theme/util';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import type { ReactNode } from 'react';
import { Box } from '../Box';
import Translate from '../Translate';
import ParameterContext from './context';

interface ParameterListStyleProps {
  keyColumnWidth?: string;
}

interface ParameterTableProps {
  dense: boolean;
  fullWidth: boolean;
  keyColumnWidth?: string;
}

const ParameterTable = styled(Table, {
  name: 'ParameterList',
  slot: 'ParameterTable',
  shouldForwardProp: (prop) =>
    excludeProps(prop, ['dense', 'fullWidth', 'keyColumnWidth']),
})<ParameterTableProps>(({ dense, fullWidth, keyColumnWidth }) => ({
  '& td': {
    height: '3rem',
    ...(dense && {
      height: '1.5rem',
      border: 0,
    }),
  },
  ...(typeof keyColumnWidth === 'string' && {
    '& td:first-of-type': {
      width: keyColumnWidth,
    },
  }),
  ...(!fullWidth && { width: 'auto' }),
}));

export interface ParameterListProps extends ParameterListStyleProps {
  title?: Translation;
  isLoading?: boolean;
  dense?: boolean;
  fullWidth?: boolean;
  emptyValue?: string;
  children: ReactNode;
}

const ParameterList = ({
  title,
  children,
  keyColumnWidth,
  isLoading = false,
  dense = false,
  fullWidth = false,
  emptyValue = '',
}: ParameterListProps) => {
  return (
    <ParameterContext.Provider value={{ emptyValue, isLoading }}>
      {title && (
        <Box mb={2}>
          <Typography variant="subtitle2" gutterBottom>
            <Translate text={title} />
          </Typography>
        </Box>
      )}
      <ParameterTable
        dense={dense}
        fullWidth={fullWidth}
        keyColumnWidth={keyColumnWidth}
      >
        <TableBody>{children}</TableBody>
      </ParameterTable>
    </ParameterContext.Provider>
  );
};

export default ParameterList;
