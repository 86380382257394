import { styled } from '@mui/material/styles';
import type { ReactNode } from 'react';

const NoLineHeight = styled('span', { name: 'StopPropagationNoLineHeight' })({
  lineHeight: 0,
});
interface StopPropagationProps {
  children: ReactNode;
}

const StopPropagation = ({ children }: StopPropagationProps) => {
  return (
    <NoLineHeight
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {children}
    </NoLineHeight>
  );
};

export default StopPropagation;
