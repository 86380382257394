import {
  DialogActions as MuiDialogActions,
  DialogActionsProps as MuiDialogActionsProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import type { ReactNode } from 'react';

const Left = styled('div', { name: 'Dialog', slot: 'ActionsLeft' })(
  ({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(2),
  })
);
const Right = styled('div', { name: 'Dialog', slot: 'ActionsRight' })(
  ({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(2),
  })
);

interface DialogActionsProps extends MuiDialogActionsProps {
  left?: ReactNode;
  right?: ReactNode;
}

const DialogActions = ({ left, right }: DialogActionsProps) => (
  <MuiDialogActions
    sx={{
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      py: 2,
      px: 3,
    }}
  >
    <Left>{left}</Left>
    <Right>{right}</Right>
  </MuiDialogActions>
);

export default DialogActions;
