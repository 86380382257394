import { createTheme } from '@mui/material/styles';
import { Shadows } from '@mui/material/styles/shadows';

export const applyDefaultShadows = (shadows: string[]) => {
  const themeShadows = [...createTheme().shadows];

  themeShadows[4] = shadows[0];
  themeShadows[8] = shadows[1];
  themeShadows[24] = shadows[2];

  return themeShadows as Shadows;
};
