import {
  Button,
  ButtonProps,
  CircularProgress as MuiCircularProgress,
  Fade,
  styled,
} from '@mui/material';
import type { ElementType, ReactNode } from 'react';

const loadingSize = '1.5em';

const CircularProgress = styled(MuiCircularProgress, {
  name: 'ButtonLoading',
  slot: 'CircularProgress',
})({
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: `calc(-${loadingSize} / 2)`,
  marginLeft: `calc(-${loadingSize} / 2)`,
});

export interface ButtonLoadingProps extends ButtonProps {
  loading: boolean;
  children?: ReactNode;
  component?: ElementType<any>;
}

// TODO Move to `components/Button`
const ButtonLoading = ({
  loading,
  children,
  ...buttonProps
}: ButtonLoadingProps) => (
  <Button {...buttonProps} disabled={loading || buttonProps.disabled}>
    {children}
    <Fade in={loading}>
      <CircularProgress size={loadingSize} />
    </Fade>
  </Button>
);

export default ButtonLoading;
