import { includeProps } from '@/theme/util';
import { styled } from '@mui/material/styles';
import type { ReactNode } from 'react';

interface GridProps {
  children: ReactNode;
  className?: string;
  columns?: string | number;
  rows?: string | number;
  gap?: number;
  columnGap?: number;
  rowGap?: number;
  height?: string;
  minRowHeight?: string;
  width?: string;
  flow?: string;
  justifyContent?: string;
  alignContent?: string;
  disableGrid?: boolean;
  inline?: boolean;
  alignItems?: 'start' | 'end' | 'center' | 'stretch';
  justifyItems?: 'start' | 'end' | 'center' | 'stretch';
}

const frGetter = (value?: string | number) =>
  typeof value === 'number' ? `repeat(${value}, 1fr)` : value;

const Grid = styled('div', {
  name: 'Grid',
  shouldForwardProp: (propName) =>
    includeProps(propName, ['className', 'children']),
})<GridProps>(
  ({
    theme,
    disableGrid,
    inline,
    height = 'auto',
    width = 'auto',
    flow = 'row',
    minRowHeight,
    rows,
    columns = 12,
    gap = 2,
    columnGap = 0,
    rowGap = 0,
    justifyContent,
    alignContent,
    justifyItems,
    alignItems,
  }) => ({
    display: disableGrid ? 'block' : inline ? 'inline-grid' : 'grid',
    height,
    width,
    gridAutoFlow: flow,
    gridAutoRows: minRowHeight && `minmax(${minRowHeight}, auto)`,
    gridTemplateRows: frGetter(rows),
    gridTemplateColumns: frGetter(columns),
    alignContent,
    justifyContent,
    alignItems,
    justifyItems,
    ...(gap && !columnGap && !rowGap ? { gridGap: theme.spacing(gap) } : {}),
    ...(columnGap ? { gridColumnGap: theme.spacing(columnGap) } : {}),
    ...(rowGap ? { gridRowGap: theme.spacing(rowGap) } : {}),
    '& > *': {
      marginBottom: disableGrid ? theme.spacing(gap ?? 0) : 0,
    },
  })
);

export default Grid;
