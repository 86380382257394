import type { AllTexts, Translation } from '@/feature/lang/types';
import usePrevious from '@/util/usePrevious';
import { isEqual, omit } from 'lodash-es';
import type { ReactNode } from 'react';
import { useEffect, useMemo, useState } from 'react';

import {
  Column,
  HeaderProps,
  Renderer,
  Row,
  TableState,
  useColumnOrder,
  useExpanded,
  useFilters,
  useGlobalFilter,
  useGroupBy,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import CheckboxColumn from './CheckboxColumn';
import Context from './context';
import { GroupHeaderProps } from './DataGridGroupHeader';
import useDataProviderState from './dataProviderState';
import { DefaultColumnFilter } from './gridFilters';
import selection from './rowSelection';
import { getSortBy } from './sort';
import { useDecorateColumns } from './useDecorateColumns';

export type ExtendedColumn = {
  initialSort?: Sorting;
  header?: (texts: AllTexts) => Translation;
  ColumnName?: Renderer<any>;
  columnName?: (texts: AllTexts) => Translation;
  group?: number;
  GroupHeader?: (props: GroupHeaderProps) => ReactNode;
  Footer?: string | Renderer<HeaderProps<any>>;
  padding?: 'none' | 'medium';
} & Column;

type InitialFilter = {
  id: string;
  value: any;
}[];

export interface FilterOptions {
  initial?: InitialFilter;
}

interface GroupingOptions {
  initial?: string[];
}

export interface DataProviderProps {
  children?: ReactNode;
  columnDefinitions: ExtendedColumn[];
  initialData?: any[];
  highlightRowId?: (item: any) => boolean;
  rowClickable?: (row: Row<any>) => boolean;
  onRowClick?: (row: Row<any>) => void;
  // Selected rows is controlled outside data grid
  selectedRows?: RowSelection;
  onRowSelect?: (selectedRows: RowSelection) => void;
  filter?: boolean | FilterOptions;
  grouping?: boolean | GroupingOptions;
  pagination?: boolean;
  selectable?: boolean;
  globalFilter?: (
    rows: Row<any>[],
    columnIds: string[],
    globalFilterValue: string
  ) => Row<any>[];
  sorting?: boolean;
  getRowId?: (row: any) => string;
  status?: Status;
  initialState?: TableState;
  id?: string;
}

type DataProviderStateWrapperProps = Omit<DataProviderProps, 'initialState'>;

const DataProviderStateWrapper = (props: DataProviderStateWrapperProps) => {
  const { getInitialState } = useDataProviderState(props.id);

  return <DataProvider {...props} initialState={getInitialState()} />;
};

const DataProvider = ({
  children,
  columnDefinitions,
  initialData,
  highlightRowId,
  selectedRows,
  onRowSelect,
  filter,
  rowClickable,
  onRowClick,
  grouping,
  pagination,
  selectable,
  globalFilter,
  sorting,
  getRowId,
  status = 'success',
  initialState = {} as TableState,
  id,
}: DataProviderProps) => {
  const { saveState } = useDataProviderState(id);
  const [data, setData] = useState<any[]>();
  const [columnDefs, setColumnDefs] = useState(columnDefinitions);
  const memoData = useMemo(() => data || [], [data]);
  const decorateColumns = useDecorateColumns();
  const columns = useMemo(
    () => decorateColumns(columnDefs),
    [columnDefs, decorateColumns]
  );
  const useControlledRowSelection = Boolean(selectedRows);

  useEffect(() => {
    setData(initialData);
  }, [initialData, setData]);

  if (useControlledRowSelection && !onRowSelect) {
    console.warn(
      'DataGrid is in controlled mode for row selection (as selectedRows is specified), but no callback on onRowSelect is specified.'
    );
  }
  if (rowClickable && !onRowClick) {
    console.warn(
      'DataProvider has rowClickable specified but no onRowClick is set.'
    );
  }

  const rowSelection = selection.toInternal(selectedRows);

  const baseProps: any = {
    columns,
    data: memoData,
  };

  const useControlledState = (state: any) =>
    useMemo(
      () => ({ ...state, selectedRowIds: rowSelection }),
      // eslint complains about rowSelection, but we need it in the dep array
      // eslint-disable-next-line
      [state, rowSelection]
    );

  if (useControlledRowSelection) {
    baseProps.useControlledState = useControlledState;
  }

  const hooks: any[] = [useColumnOrder];
  const defaultColumnFilter = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  if (filter) {
    baseProps.defaultColumn = defaultColumnFilter;
    hooks.push(useFilters);
    if (typeof filter !== 'boolean' && filter.initial) {
      initialState.filters = filter.initial;
    }
  }

  if (grouping) {
    hooks.push(useGroupBy);
    if (typeof grouping !== 'boolean' && grouping.initial) {
      initialState.groupBy = grouping.initial;
    }
  }

  if (globalFilter) {
    baseProps.globalFilter = globalFilter;
    hooks.push(useGlobalFilter);
  }

  if (sorting) {
    // must be placed before pagination
    hooks.push(useSortBy);

    const sortBy = getSortBy(columns);
    if (sortBy && !initialState.sortBy) {
      initialState.sortBy = sortBy;
    }
  }

  initialState.pageSize = 10;

  if (grouping) {
    hooks.push(useExpanded);
  }

  if (pagination) {
    hooks.push(usePagination);
  }

  if (selectable) {
    hooks.push(useRowSelect, CheckboxColumn);
  }

  if (getRowId) {
    baseProps.getRowId = getRowId;
  }

  const tableProps = useTable(
    {
      ...baseProps,
      initialState,
    },
    ...hooks
  );

  const {
    setGroupBy,
    toggleAllRowsExpanded,
    toggleRowSelected,
    toggleAllRowsSelected,
    state,
    rows,
    setColumnOrder,
    toggleSortBy,
    gotoPage,
  } = tableProps;

  const selectedRowsInState = useMemo(
    () => selection.toExternal(state.selectedRowIds),
    [state.selectedRowIds]
  );

  useEffect(() => {
    if (grouping) {
      toggleAllRowsExpanded(true);
    }
  }, [grouping, toggleAllRowsExpanded, data]);

  // pageIndex is preserved between renders via saveState(...) below, to solve problems with pageIndex
  // being resetted when initialData changes. We do however want to reset pageIndex if the user changes
  // filters or sortBy. This is accomplished by calling gotoPage(0) for these cases.
  const filters = state.filters;
  const sortBy = state.sortBy;
  const partialState = useMemo(() => ({ filters, sortBy }), [filters, sortBy]);
  const previousPartialState = usePrevious(partialState);
  useEffect(() => {
    if (gotoPage && !isEqual(previousPartialState, partialState)) {
      gotoPage(0);
    }
  }, [partialState, previousPartialState, gotoPage]);

  useEffect(() => {
    saveState(omit(state, 'expanded') as TableState);
  }, [state, saveState]);

  /**
   * Row: Selection
   */
  const handleSelectRow = (row: Row, selected: boolean) => {
    if (useControlledRowSelection) {
      const newRowSelection = selection.selectRow(row, selected, rowSelection);
      onRowSelect?.(newRowSelection);
    } else {
      toggleRowSelected(row.id, selected);
    }
  };

  const handleSelectAllRows = (selected: boolean) => {
    if (useControlledRowSelection) {
      const newRowSelection = selection.selectAllRows(rows, selected);
      onRowSelect?.(newRowSelection);
    } else {
      toggleAllRowsSelected(selected);
    }
  };

  const groupBy = (columnId: string) => {
    setGroupBy([columnId]);
    if (columnId && toggleSortBy) {
      toggleSortBy(columnId, false);
    }
  };

  return (
    <Context.Provider
      value={{
        data: data || [],
        initialData,
        clearSelectedRows: () => handleSelectAllRows(false),
        footer: columns.some((c) => c.Footer),
        grouping: Boolean(grouping),
        groupBy,
        highlightRowId,
        rowClickable,
        onRowClick,
        onSelectRow: handleSelectRow,
        onSelectAllRows: handleSelectAllRows,
        selectedRows: selectedRowsInState || [],
        setColumnOrder,
        setColumns: setColumnDefs,
        setData,
        tableProps,
        status,
        id,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default DataProviderStateWrapper;
