import { createContext } from 'react';

const noop = () => {};

const CartContext = createContext<CartContextProps>({
  addToCart: noop,
  changeQuantity: noop,
  changeDeliveryPoint: noop,
  changeEquipment: noop,
  removeFromCart: noop,
  isReady: false,
  hasWritePermit: false,
  selectedItems: [],
  setSelectedItems: noop,
  setSelectedDeliveryPoint: noop,
  selectedDeliveryPointItems: [],
  setDefaultDeliveryPoint: noop,
});

export default CartContext;
