import keycloak from '../feature/auth/keycloak';
import { getBuild, version } from '../feature/build';
import { validateData } from './schema';
import ServerError from './ServerError';
import { mergeOptions } from './util';

export { getPaginated } from './actions/pagination';

export function get<T>(path: string, options?: Options): Promise<T> {
  const mergedOptions = mergeOptions(
    { requestInit: { method: 'get' } },
    options
  );
  return client<T>(path, mergedOptions);
}

export function put<T>(
  path: string,
  body?: any,
  options?: Options
): Promise<T> {
  const mergedOptions = mergeOptions(
    {
      requestInit: {
        method: 'put',
        body: JSON.stringify(body),
      },
    },
    options
  );
  return client<T>(path, mergedOptions);
}

export function post<T>(
  path: string,
  body: any,
  options?: Options
): Promise<T> {
  const mergedOptions = mergeOptions(
    {
      requestInit: {
        method: 'post',
        body: JSON.stringify(body),
      },
    },
    options
  );
  return client<T>(path, mergedOptions);
}

export function postFile<T>(
  path: string,
  file: File,
  options?: Options
): Promise<T> {
  const contentType = file.type;
  const mergedOptions = mergeOptions(
    {
      requestInit: {
        method: 'post',
        body: file,
        headers: { 'Content-Type': contentType },
      },
    },
    options
  );
  return client<T>(path, mergedOptions);
}

export function remove<T>(
  path: string,
  body?: any,
  options?: Options
): Promise<T> {
  const mergedOptions = mergeOptions(
    { requestInit: { method: 'delete', body: JSON.stringify(body) } },
    { responseHandler: () => () => undefined },
    options
  );
  return client<T>(path, mergedOptions);
}

function client<T>(path: string, options: OptionsStrict): Promise<T> {
  const controller = new AbortController();
  const requestInit = getRequestInit(options, controller);
  const request = new Request(`${options.baseUrl}/${path}`, requestInit);

  const promise = fetch(request)
    .then(verifyAuthenticated)
    .then((response) => verifyResponse(response, request))
    .then(options.responseHandler(options))
    .then(validateData(path, options));

  // @ts-ignore
  promise.cancel = () => {
    controller.abort();
  };

  return promise;
}

function verifyAuthenticated(response: Response) {
  if (response.status === 401) {
    keycloak.logout();
  }
  return response;
}

function verifyResponse(response: Response, request: Request) {
  if (!response.ok) {
    return Promise.reject(new ServerError(response, request));
  }
  return response;
}

function getRequestInit(options: OptionsStrict, controller: AbortController) {
  const headers = getHeaders(options.requestInit?.headers);
  const { signal } = controller;
  return {
    ...options.requestInit,
    headers,
    signal,
  };
}

function getLang() {
  if (sessionStorage.getItem('settings.showTextKeys') === 'true') {
    return 'en-x-code';
  }
  if (typeof localStorage.getItem('settings.language') === 'string') {
    // @ts-ignore
    return JSON.parse(localStorage.getItem('settings.language'));
  }
  return 'en';
}

export function getHeaders(headers?: HeadersInit) {
  const build = getBuild();

  return new Headers({
    Accept: 'application/json',
    'Accept-Language': getLang(),
    Authorization: `Bearer ${keycloak.token}`,
    'Content-Type': 'application/json',
    'sandvik-user-agent': `SamWeb/${version}-${build.number}`,
    ...headers,
  });
}
