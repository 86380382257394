import { createContext, ReactNode, useContext } from 'react';
import { useGlobalPermits } from './useGlobalPermits';

interface PermissionsContextProps {
  permits: Permit[];
}

const PermissionsContext = createContext<PermissionsContextProps>({
  permits: [],
});

interface PermissionsProviderProps {
  children: ReactNode;
}

export const PermissionsProvider = ({ children }: PermissionsProviderProps) => {
  const { permits } = useGlobalPermits();

  return (
    <PermissionsContext.Provider value={{ permits }}>
      {children}
    </PermissionsContext.Provider>
  );
};

export const usePermissions = () => {
  const { permits } = useContext(PermissionsContext);

  return permits;
};

export const useHasPermission = (permit: Permit | PermitQuery) => {
  const permits = usePermissions();

  if (typeof permit === 'string') {
    return permits.includes(permit);
  }

  return permit(permits);
};
