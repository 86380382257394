import { toArray } from '@/util/array';
import useQuery from '@/util/useQuery';
import type { ReactNode } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { SelectionContext } from './SelectionContext';

interface MemorySelectionProviderProps {
  onSelect?: (ids: string[]) => void;
  onClearSelection?: () => void;
  children: ReactNode;
}

export const MemorySelectionProvider = ({
  onSelect,
  onClearSelection,
  children,
}: MemorySelectionProviderProps) => {
  const { query } = useQuery();
  const initialSelected = useMemo(
    () => (query.selected ? query.selected.split(',') : []),
    [query.selected]
  );
  const [selected, setSelected] = useState<string[]>(initialSelected);

  const select = useCallback(
    (id: string | string[]) => {
      const ids = toArray(id);
      setSelected(ids);
      onSelect?.(ids);
    },
    [onSelect]
  );

  const toggleSelected = useCallback(
    (id: string) => {
      const ids = selected.includes(id)
        ? selected.filter((i) => i !== id)
        : [...selected, id];
      setSelected(ids);
    },
    [selected]
  );

  const clearSelection = useCallback(() => {
    setSelected([]);
    onClearSelection?.();
  }, [onClearSelection]);

  return (
    <SelectionContext.Provider
      value={{
        select,
        toggleSelected,
        clearSelection,
        selected,
      }}
      children={children}
    />
  );
};
