import { get } from '../apiClient';
import { toQueryParams } from '../util';

export const isHasNext = <T>(
  response: any
): response is PaginatedHasNextResponse<T> => {
  return Object.hasOwn(response, 'hasNext');
};

export const isTotalPages = <T>(
  response: any
): response is PaginatedTotalPagesResponse<T> => {
  return Object.hasOwn(response, 'totalPages');
};

interface PaginationOptions<T> {
  params?: any;
  options?: any;
  pageProp?: string;
  accumulatedResult: T[];
}

interface HasNextOptions<T> extends PaginationOptions<T> {
  page: number;
}

interface TotalPagesOptions<T> extends PaginationOptions<T> {
  totalPages: number;
}

async function getHasNextPage<T>(
  path: string,
  {
    params,
    options,
    pageProp = 'page',
    page = 0,
    accumulatedResult,
  }: HasNextOptions<T>
): Promise<T[]> {
  const p = {
    ...params,
    [pageProp]: page,
  };
  const response = await get<PaginatedHasNextResponse<T[]>>(
    `${path}${toQueryParams(p)}`,
    {
      ...options,
      selector: (json) => json,
    }
  );
  const { data, hasNext } = response;
  const result = [...accumulatedResult, ...data];

  return hasNext
    ? getHasNextPage(path, {
        params,
        options,
        pageProp,
        page: ++page,
        accumulatedResult: result,
      })
    : result;
}

async function getTotalPagesPage<T>(
  path: string,
  {
    params,
    options,
    pageProp = 'page_number',
    totalPages,
    accumulatedResult,
  }: TotalPagesOptions<T>
): Promise<T[]> {
  function getByPage<T>(page: number) {
    const p = {
      ...params,
      [pageProp]: page,
    };
    return get<T[]>(`${path}${toQueryParams(p)}`, options);
  }

  let page = 1;
  const requests = [];
  while (page < totalPages) {
    requests.push(getByPage<T>(page++));
  }

  const result = [...accumulatedResult];
  const otherPageResult = await Promise.all(requests);
  otherPageResult.forEach((r) => result.push(...r));

  return result;
}

export async function getPaginated<T>(
  path: string,
  params?: any,
  options?: Options,
  pageProp?: string
): Promise<T[]> {
  const data = await get<T[] & { __original__: PaginatedEntityResponse<T[]> }>(
    `${path}${toQueryParams(params)}`,
    options
  );
  const response = data.__original__;

  if (isHasNext(response) && response.hasNext) {
    return getHasNextPage(path, {
      params,
      options,
      pageProp,
      page: 1,
      accumulatedResult: data,
    });
  } else if (isTotalPages(response) && response.totalPages > 1) {
    return getTotalPagesPage(path, {
      params,
      options,
      pageProp,
      totalPages: response.totalPages,
      accumulatedResult: data,
    });
  }

  return data;
}
