import { equals } from '@/util/string';
import { useTranslation } from '../lang';
import rules from './passwordRules';

const usePasswordValidation = (user?: UserDto) => {
  const { translate, texts } = useTranslation();
  const validationText = texts.admin.user.model.password.validation;

  return {
    required: translate(validationText.minLength, {
      minLength: rules.minLength,
    }),
    minLength: {
      value: rules.minLength,
      message:
        translate(validationText.minLength, {
          minLength: rules.minLength,
        }) ?? 'Min length requirement of password not met',
    },
    validate: (value: any) => {
      switch (true) {
        case rules.notUsername && equals(value, user?.username):
          return translate(validationText.notUsername);
        case rules.notEmail && equals(value, user?.email):
          return translate(validationText.notEmail);
      }
    },
  };
};

export default usePasswordValidation;
