import { useRemoveRegistrationToken } from './mutations';
import { usePushToken } from './usePushToken';

export const usePushLogoutAction = () => {
  const { mutateAsync: removeToken } = useRemoveRegistrationToken();
  const [token, setToken] = usePushToken();

  return () => {
    if (token) {
      return removeToken(token)
        .then(() => setToken(undefined))
        .catch((error: any) =>
          console.warn('Push notification, removal of token failed:', error)
        );
    } else {
      return Promise.resolve();
    }
  };
};
