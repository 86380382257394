export const fleetPath = 'fleet';
export const equipmentDashboardPath = 'dashboard';
export const equipmentDashboardFullPath = `/${fleetPath}/${equipmentDashboardPath}`;
export const equipmentDataPath = 'data';
export const equipmentDataFullPath = `/${fleetPath}/${equipmentDataPath}`;
export const alarmsPath = 'alarms';
export const alarmsFullPath = `/${fleetPath}/${alarmsPath}`;
export const insightsPath = 'insights';
export const insightsFullPath = `/${fleetPath}/${insightsPath}`;
export const noticeBoardPath = 'notices';
export const noticeBoardFullPath = `/${fleetPath}/${noticeBoardPath}`;
export const activitiesPath = 'activities';
export const activitiesFullPath = `/${fleetPath}/${activitiesPath}`;
export const basicsPath = 'basics';
export const basicsFullPath = `/${fleetPath}/${basicsPath}`;
export const equipmentPartsPath = 'parts';
export const equipmentPartsFullPath = `/${fleetPath}/${equipmentPartsPath}`;

export const ecomPath = 'ecom';
export const partsPath = 'parts';
export const partsFullPath = `/${ecomPath}/${partsPath}`;
export const recommendationsPath = 'recommendation';
export const recommendationsFullPath = `/${ecomPath}/${recommendationsPath}`;
export const shoppinglistsPath = 'shoppinglist';
export const shoppinglistsFullPath = `/${ecomPath}/${shoppinglistsPath}`;
export const ordersPath = 'order';
export const ordersFullPath = `/${ecomPath}/${ordersPath}`;
export const cartPath = 'cart';
export const cartFullPath = `/${ecomPath}/${cartPath}`;

export const adminPath = 'admin';
export const companiesPath = 'companies';
export const companiesFullPath = `/${adminPath}/${companiesPath}`;
export const sitesPath = 'sites';
export const sitesFullPath = `/${adminPath}/${sitesPath}`;
export const equipmentPath = 'equipment';
export const equipmentFullPath = `/${adminPath}/${equipmentPath}`;
export const usersPath = 'users';
export const usersFullPath = `/${adminPath}/${usersPath}`;
export const samAccountPath = 'samaccount';
export const samAccountFullPath = `/${adminPath}/${samAccountPath}`;
