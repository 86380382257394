import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { NotFound } from '../../app/routing/NotFound';
import { equipmentPath, sitesFullPath } from '../../pathDefinitions';
import { equipmentIdParam } from '../equipment/routes';

const ListSitesRoute = lazy(() => import('./list/ListSitesRoute'));
const SiteDetailsRoute = lazy(() => import('./details/SiteDetailsRoute'));
const SiteEquipmentDetails = lazy(
  () => import('./details/SiteEquipmentDetails')
);

export const siteEquipmentRoute = (siteId: string, equipmentId: string) =>
  `${sitesFullPath}/${siteId}/${equipmentPath}/${equipmentId}`;

export const siteIdParam = 'siteId';

export default () => (
  <Routes>
    <Route path="/" element={<ListSitesRoute />} />
    <Route path={`:${siteIdParam}`} element={<SiteDetailsRoute />} />
    <Route
      path={`:${siteIdParam}/${equipmentPath}/:${equipmentIdParam}`}
      element={<SiteEquipmentDetails />}
    />
    <Route path="*" element={<NotFound />} />
  </Routes>
);
