import { eachSecondOfInterval } from '@/util/date';
import Duration from '@/util/duration';
import type { Interval } from 'date-fns';
import type { SampleTransformer } from './index';
import {
  getPreviousSample,
  getSampleInterval,
  getSamplesForSignal,
  getSignals,
} from './utils';

function getTimeSlots(interval: Interval, bucketWidth: string) {
  const duration = Duration.from(bucketWidth);
  if (duration.years > 0 || duration.months > 0) {
    throw new Error(
      'Unsupported duration. Bucket width includes years / months.'
    );
  }
  const seconds =
    duration.days * 86400 +
    duration.hours * 3600 +
    duration.minutes * 60 +
    duration.seconds;

  return eachSecondOfInterval(interval, { step: seconds });
}

/**
 * Add samples with null values at time slots when there are no samples available.
 *
 * This applies when the previous sample is outside the threshold.
 */
export const addNoDataSamples: SampleTransformer = (samples, settings) => {
  const { options, query } = settings;
  const interval = getSampleInterval(samples);
  const thresholdMs = options.noDataThresholdSeconds * 1000;

  if (
    query.bucket_width === undefined ||
    interval.start === undefined ||
    interval.end === undefined
  ) {
    return samples;
  }

  const { equipmentId = 'UNKNOWN' } = samples.at(0) ?? {};

  // @ts-ignore
  const timeSlots = getTimeSlots(interval, query.bucket_width);

  return getSignals(samples).reduce(
    (result, signalName) => {
      const signalSamples = getSamplesForSignal(signalName, samples);
      const timeStampsWithData = signalSamples.map((sample) =>
        sample.timeStampAsDate.getTime()
      );

      timeSlots
        .filter((timeSlot) => !timeStampsWithData.includes(timeSlot.getTime()))
        .filter((timeSlot) => {
          const current = timeSlot.getTime();
          const previous =
            getPreviousSample(
              timeSlot,
              signalSamples
            )?.timeStampAsDate.getTime() ?? 0;
          return current - previous >= thresholdMs;
        })
        .forEach((timeSlot) => {
          result.push({
            signalName,
            timeStamp: new Date(timeSlot).toISOString(),
            timeStampAsDate: new Date(timeSlot),
            value: null,
            equipmentId,
          });
        });

      return result;
    },
    [...samples]
  );
};
