import { useContext } from 'react';
import AuthContext from './AuthContext';

const useAuth = () => {
  const authContext = useContext(AuthContext);

  return {
    login: authContext.login,
    logout: authContext.logout,
    isAuthenticated: authContext.isAuthenticated,
    isInitialized: authContext.isInitialized,
  };
};

export default useAuth;
