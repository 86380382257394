import { styled } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import type { ReactNode } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import type { Mode } from 'react-hook-form/dist/types/form';
import Input from './Input';
import type { InputProps, ValueOrArray } from './types';

const FormWithSx = styled('form')({});

function Form({
  children,
  defaultValues = {},
  shouldUnregister = true,
  onSubmit,
  className,
  mode,
  items,
  sx,
}: WithConfig | WithChildren) {
  const methods = useForm({ defaultValues, shouldUnregister, mode });

  return (
    <FormProvider {...methods}>
      <FormWithSx
        onSubmit={methods.handleSubmit(onSubmit)}
        className={className}
        sx={sx}
      >
        {children ?? (
          <>
            <Section items={items} />
            <input type="submit" />
          </>
        )}
      </FormWithSx>
    </FormProvider>
  );
}

export default Form;

function Section({ items }: any) {
  return items.map((i: InputProps, index: number) =>
    Array.isArray(i) ? (
      <section key={index}>
        <Section items={i} />
      </section>
    ) : (
      <Input key={i.name || index} {...i} />
    )
  );
}

interface FormProps {
  defaultValues?: any;
  shouldUnregister?: boolean;
  onSubmit: (data: any) => any;
  className?: string;
  mode?: Mode;
  sx?: SxProps;
}

interface WithChildren extends FormProps {
  children: ReactNode;
  items?: never;
}

interface WithConfig extends FormProps {
  items: ValueOrArray<InputProps>[];
  children?: never;
}
