import { FormControl, FormControlLabel, FormHelperText } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import Checkbox from '../Checkbox';
import Label from './Label';
import type { CheckboxProps } from './types';
import { useValidation } from './useValidation';

const FormCheckbox = ({
  label,
  registerControl,
  validation: validationBase,
  ...rest
}: CheckboxProps) => {
  const validation = useValidation(validationBase, { label });

  const props = {
    label: label && (
      <Label label={label} required={Boolean(validation?.required)} />
    ),
    ...rest,
  };

  return registerControl ? (
    <RegisteredControl {...props} validation={validation} />
  ) : (
    <BasicCheckbox {...props} />
  );
};

const BasicCheckbox = ({ label, ControlProps, ...props }: any) => {
  return (
    <FormControl {...ControlProps}>
      <FormControlLabel
        label={label}
        control={<Checkbox {...props} disableRipple />}
      />
    </FormControl>
  );
};

const RegisteredControl = ({
  validation,
  name,
  label,
  ControlProps,
  ...props
}: any) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const error = name && errors[name];
  const helperText = error && (error.message || 'validation error');

  return (
    <FormControl error={error} {...ControlProps}>
      <FormControlLabel
        label={label}
        control={
          <Controller
            name={name}
            control={control}
            rules={validation}
            defaultValue={false}
            render={({ field: { value, onChange } }) => (
              <Checkbox
                {...props}
                checked={!!value}
                onChange={(e) => onChange(e.target.checked)}
                disableRipple
              />
            )}
          />
        }
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default FormCheckbox;
