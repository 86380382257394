import { styled } from '@mui/material/styles';
import { keyframes } from '@mui/system';

const Container = styled('div')({
  position: 'absolute',
  top: 'calc(50% - 2rem)',
  left: 'calc(50% - 2rem)',
});

const bounce = keyframes`
    from {
      scale: 0;
    }
    to {
      scale: 1;
    }
`;

const Circle = styled('div')(({ theme }) => ({
  position: 'fixed',
  height: 64,
  width: 64,
  borderRadius: '100%',
  backgroundColor: theme.palette.secondary.main,
  opacity: 0.6,
  zIndex: theme.zIndex.modal,
  scale: 0,
  animation: `${bounce} 1050ms ease-in-out infinite alternate`,
}));

const Progress = () => (
  <Container>
    <Circle style={{ animationDelay: '1050ms' }} />
    <Circle style={{ animationDelay: '2100ms' }} />
  </Container>
);

export default Progress;
