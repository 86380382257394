import { notEmpty, uniqueValues } from '@/util/array';
import type {
  DataStreamRequest,
  SampleSubscription,
  Subscription,
} from './types';
import { isSampleSubscription } from './types';

function createSampleSubscription(
  subscriptions: Subscription[]
): SampleSubscription | undefined {
  const signalNames = uniqueValues(
    subscriptions
      .filter(isSampleSubscription)
      .map((subscription) => subscription.signalNames)
      .flat()
  );

  if (signalNames.length === 0) {
    return undefined;
  }

  return {
    type: 'sample',
    signalNames,
    includeLatest: true,
  };
}

function getEquipmentIds(subscriptions: Subscription[]): string[] {
  return uniqueValues(
    subscriptions
      .filter(isSampleSubscription)
      .map((subscription) => subscription.equipmentIds)
      .flat()
      .filter(notEmpty)
  );
}

export function createRequest(
  subscriptions: Subscription[]
): DataStreamRequest {
  const equipmentIds = getEquipmentIds(subscriptions);
  const sampleSubscription = createSampleSubscription(subscriptions);

  const requestSubs: Subscription[] = [];
  if (sampleSubscription !== undefined) {
    requestSubs.push(sampleSubscription);
  }

  return { equipmentIds, subscriptions: requestSubs };
}
