import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useOnNavigateEffect = (onNavigate: () => void) => {
  const { key: currentLocationKey } = useLocation();
  const [locationKey, setLocationKey] = useState(currentLocationKey);
  useEffect(() => {
    if (currentLocationKey !== locationKey) {
      setLocationKey(currentLocationKey);
      onNavigate();
    }
  }, [locationKey, currentLocationKey, onNavigate]);
};
