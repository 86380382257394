import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
  Paper,
} from '@mui/material';
import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';
import VirtualizedAutocomplete from './VirtualizedAutocomplete';

interface AutocompleteProps<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
> extends MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  disableUniqueLabel?: boolean;
  disableVirtualization?: boolean;
}

function Autocomplete<T>(
  {
    disableUniqueLabel = false,
    disableVirtualization = false,
    ...props
  }: AutocompleteProps<T>,
  ref: ForwardedRef<any>
): JSX.Element {
  const getOriginalOptionLabel = (option: T) =>
    props.getOptionLabel
      ? props.getOptionLabel(option)
      : typeof option === 'string'
      ? (option as string)
      : '';

  const getOptionLabel = (option: T) => {
    const label: string = getOriginalOptionLabel(option);
    if (disableUniqueLabel) {
      return label;
    }

    const duplicates: T[] = props.options.filter(
      (o) => getOriginalOptionLabel(o) === label
    );
    const index = duplicates.findIndex((o) => o === option) + 1;

    return duplicates.length === 1 || index < 1 ? label : `${label} (${index})`;
  };

  const autocompleteProps: AutocompleteProps<T> = {
    ...props,
    getOptionLabel,
    PaperComponent: (props) => <Paper {...props} sx={{ boxShadow: 8 }} />,
  };

  // Virtualize autocomplete fields with grouping is not supported. Will likely
  // be possible, as outlined in docs, after upgrading MUI:
  // https://mui.com/material-ui/react-autocomplete/#virtualization
  if (!disableVirtualization && props.groupBy === undefined) {
    return <VirtualizedAutocomplete {...autocompleteProps} ref={ref} />;
  }

  return <MuiAutocomplete {...autocompleteProps} ref={ref} />;
}

export default forwardRef(Autocomplete);
