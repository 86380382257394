import useEquipmentPropTexts from '@/feature/equipment/useEquipmentPropTexts';
import type { AllTexts, Translation } from '@/feature/lang/types';
import { formatDate, formatDateAndTime } from '@/util/date';
import { BadgePropsColorOverrides } from '@mui/material/Badge/Badge';
import { styled } from '@mui/material/styles';
import type { OverridableStringUnion } from '@mui/types';
import { ReactElement } from 'react';
import { Column } from 'react-table';
import CountBubble from '../CountBubble';
import { Value } from '../Value';
import { ExtendedColumn } from './DataProvider';
import { SelectColumnFilter } from './gridFilters';

export interface BasicProps {
  translator?: (texts: AllTexts) => Translation;
}

export const BasicColumn = ({
  translator,
  ...rest
}: BasicProps & ExtendedColumn) => {
  return {
    header: translator,
    ...rest,
  };
};

export const NameColumn = (props: BasicProps & ExtendedColumn) =>
  BasicColumn({
    ...props,
    Cell: ({ value }: any) =>
      value?.firstName || value?.lastName ? (
        <div>{`${value?.firstName} ${value?.lastName}`}</div>
      ) : (
        <div>-</div>
      ),
  });

type DateFormat = 'date' | 'datetime';

interface DateColumnProps extends BasicProps {
  format?: DateFormat;
}

export const DateColumn = ({
  format = 'date',
  ...props
}: DateColumnProps & ExtendedColumn) =>
  BasicColumn({
    ...props,
    Cell: ({ value }: any) => {
      const formatFn = format === 'date' ? formatDate : formatDateAndTime;
      const dateValue = value ? formatFn(value) : '-';

      return <div style={{ minWidth: '90px' }}>{dateValue}</div>;
    },
  });

interface BadgeColumnProps extends BasicProps {
  color?: OverridableStringUnion<
    | 'primary'
    | 'secondary'
    | 'default'
    | 'error'
    | 'info'
    | 'success'
    | 'warning',
    BadgePropsColorOverrides
  >;
}

export const BadgeColumn = (props: BadgeColumnProps & ExtendedColumn) =>
  BasicColumn({
    ...props,
    Cell: ({ value }: any) => (
      <CountBubble badgeContent={value} color={props.color} margin="none" />
    ),
  });

interface IconColumnProps extends BasicProps {
  Icon: ReactElement;
}
export const IconColumn = ({
  Icon,
  ...props
}: IconColumnProps & ExtendedColumn) =>
  BasicColumn({
    ...props,
    sortType: 'basic',
    Cell: ({ value }: any) => (value ? Icon : null),
  });

const Truncate = styled('div')({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const TextColumn = (props: ExtendedColumn) => ({
  ...props,
  Cell: ({ value }: any) => <Truncate title={value}>{value}</Truncate>,
});

export const EquipmentCategoryColumn = (props: ExtendedColumn) =>
  BasicColumn({
    ...props,
    Cell: ({ value }: any) => {
      const { getCategoryName } = useEquipmentPropTexts();
      const categoryName = getCategoryName(value);
      return categoryName ? <>{categoryName}</> : null;
    },
    translator: ({ fleet }) => fleet.model.category,
    Filter: (filterProps: any) => {
      const { getCategoryName } = useEquipmentPropTexts();
      return (
        <SelectColumnFilter {...filterProps} translateValue={getCategoryName} />
      );
    },
  });

export const EquipmentConnectivityColumn = (props: Column) =>
  BasicColumn({
    ...props,
    Cell: ({ value }: any) => {
      const { getConnectivityName } = useEquipmentPropTexts();
      const connectivityName = getConnectivityName(value);
      return connectivityName ? <>{connectivityName}</> : null;
    },
    translator: ({ fleet }) => fleet.model.connectivity,
    Filter: (filterProps: any) => {
      const { getConnectivityName } = useEquipmentPropTexts();
      return (
        <SelectColumnFilter
          {...filterProps}
          translateValue={getConnectivityName}
        />
      );
    },
  });

export const CreatedByColumn = BasicColumn({
  id: 'createdBy',
  accessor: (row: any) =>
    row.creator
      ? `${row.creator.firstName} ${row.creator.lastName}`
      : row.createdBy,
  translator: (texts) => texts.common.model.createdBy,
});

export const NumberColumn = (props: ExtendedColumn) => ({
  ...props,
  Cell: ({ value }: any) => <Value value={value} />,
});
