import { Skeleton as BaseSkeleton } from '../Skeleton';

interface SkeletonProps {
  rows?: number;
  pagination?: boolean;
  header?: boolean;
}
const Skeleton = ({
  rows = 5,
  pagination = true,
  header = true,
}: SkeletonProps) => {
  return (
    <div>
      {header && <BaseSkeleton height={70} width="100%" />}
      {[...Array(rows)].map((_, i) => (
        <BaseSkeleton height={42} width="100%" key={i} />
      ))}
      {pagination && (
        <BaseSkeleton height={42} width={300} style={{ float: 'right' }} />
      )}
    </div>
  );
};

export default Skeleton;
